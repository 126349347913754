const getRGB = (c: string | number) => {
  const rgb = typeof c === 'string' ? parseInt(c, 16) : c;
  return rgb / 255 <= 0.03928 ? rgb / 255 / 12.92 : Math.pow((rgb / 255 + 0.055) / 1.055, 2.4);
};

const getLuminance = (hexColor: string) => {
  return (
    0.2126 * getRGB(hexColor.substr(1, 2)) +
    0.7152 * getRGB(hexColor.substr(3, 2)) +
    0.0722 * getRGB(hexColor.substr(-2))
  );
};

const getContrast = (f: string, b: string) => {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
};

const getDynamicTextColor = (bgColor = '') => {
  if (bgColor.length === 0) return '';
  const whiteContrast = getContrast(bgColor, '#ffffff');
  const blackContrast = getContrast(bgColor, '#000000');
  return whiteContrast > blackContrast ? '#ffffff' : '#000000';
};

const bgColorClasses = [
  'bg-indigo-600',
  'bg-teal-600',
  'bg-orange-600',
  'bg-cyan-600',
  'bg-green-600',
  'bg-red-600',
  'bg-purple-600',
  'bg-blue-600',
  'bg-indigo-700',
  'bg-teal-700',
  'bg-orange-700',
  'bg-cyan-700',
  'bg-green-700',
  'bg-purple-700',
  'bg-indigo-800',
  'bg-teal-800',
  'bg-orange-800',
  'bg-cyan-800',
  'bg-green-800',
  'bg-red-800',
  'bg-purple-800',
  'bg-blue-800',
  'bg-indigo-900',
  'bg-teal-900',
  'bg-orange-900',
  'bg-cyan-900',
  'bg-green-900',
  'bg-purple-900',
  'bg-blue-900'
];
const colors = {
  inherit: 'inherit',
  current: 'currentcolor',
  transparent: 'transparent',
  black: '#111418',
  white: '#ffffff',
  gray: {
    50: '#F6F7F9',
    100: '#E8EAEE',
    200: '#DCE0E5',
    300: '#D3D8DE',
    400: '#C5CBD3',
    500: '#5f6b7c',
    600: '#383E47',
    700: '#2F343C',
    800: '#252A31',
    900: '#1C2127'
  },
  green: {
    50: '#BBE5CC',
    100: '#72CA9B',
    200: '#54B781',
    300: '#32A467',
    400: '#2A945C',
    500: '#238551',
    600: '#1F7949',
    700: '#1C6E42',
    800: '#19643C',
    900: '#165A36'
  },
  red: {
    50: '#FBB2B4',
    100: '#FA999C',
    200: '#F18285',
    300: '#E76A6E',
    400: '#DA565A',
    500: '#CD4246',
    600: '#BC383C',
    700: '#AC2F33',
    800: '#9D2C30',
    900: '#8E292C'
  },
  blue: {
    50: '#C9dCFF',
    100: '#8ABBFF',
    200: '#6CA5f8',
    300: '#4C90F0',
    400: '#3D81E1',
    500: '#2D72D2',
    600: '#2767C1',
    700: '#215DB0',
    800: '#1D53A0',
    900: '#184A90'
  },
  orange: {
    50: '#FFD8AF',
    100: '#FBB360',
    200: '#F4A74E',
    300: '#EC9A3C',
    400: '#DA882B',
    500: '#C87619',
    600: '#AD6614',
    700: '#935610',
    800: '#854D0F',
    900: '#77450D'
  },
  yellow: {
    50: '#fefce8',
    100: '#fef9c3',
    200: '#faf1af',
    300: '#f7e99b',
    400: '#f4e187',
    500: '#f1d873',
    600: '#efcf5e',
    700: '#edc648',
    800: '#ebbd30',
    900: '#eab308'
  },
  teal: {
    50: '#f0fdfa',
    100: '#ccfbf1',
    200: '#99f6e4',
    300: '#5eead4',
    400: '#2dd4bf',
    500: '#14b8a6',
    600: '#0d9488',
    700: '#0f766e',
    800: '#115e59',
    900: '#134e4a'
  },
  cyan: {
    50: '#ecfeff',
    100: '#cffafe',
    200: '#a5f3fc',
    300: '#67e8f9',
    400: '#22d3ee',
    500: '#06b6d4',
    600: '#0891b2',
    700: '#0e7490',
    800: '#155e75',
    900: '#164e63'
  },
  indigo: {
    50: '#eef2ff',
    100: '#e0e7ff',
    200: '#c7d2fe',
    300: '#a5b4fc',
    400: '#818cf8',
    500: '#6366f1',
    600: '#4f46e5',
    700: '#4338ca',
    800: '#3730a3',
    900: '#312e81'
  },
  purple: {
    50: '#faf5ff',
    100: '#f3e8ff',
    200: '#e9d5ff',
    300: '#d8b4fe',
    400: '#c084fc',
    500: '#a855f7',
    600: '#9333ea',
    700: '#7e22ce',
    800: '#6b21a8',
    900: '#581c87'
  },
  pink: {
    50: '#fdf2f8',
    100: '#fce7f3',
    200: '#fbcfe8',
    300: '#f9a8d4',
    400: '#f472b6',
    500: '#ec4899',
    600: '#db2777',
    700: '#be185d',
    800: '#9d174d',
    900: '#831843'
  }
};

const base16CodeTheme = {
  scheme: 'govly',
  base00: colors['gray'][900],
  base01: colors['gray'][800],
  base02: colors['gray'][700],
  base03: colors['gray'][600],
  base04: colors['gray'][500],
  base05: colors['gray'][400],
  base06: colors['yellow'][50],
  base07: colors['yellow'][100],
  base08: colors['red'][500],
  base09: colors['orange'][500],
  base0A: colors['yellow'][500],
  base0B: colors['green'][500],
  base0C: colors['cyan'][500],
  base0D: colors['blue'][500],
  base0E: colors['purple'][500],
  base0F: colors['pink'][500]
};

const graphColors: string[] = [];
type DarkerColor = 600 | 700 | 800 | 900;
for (let i: DarkerColor = 600; i < 1000; i += 100) {
  (['blue', 'yellow', 'pink', 'cyan', 'orange', 'red', 'teal', 'purple'] as (keyof typeof colors)[]).forEach(color => {
    graphColors.push(colors[color][i as DarkerColor]);
  });
}

export { colors, base16CodeTheme, bgColorClasses, graphColors, getDynamicTextColor };
