import { TypedUseMutation } from '@reduxjs/toolkit/query/react';

import { useAppLayoutContext } from '@/app/organisms/AppLayout/AppLayoutContext';
import { SearchQueryMeta } from '@/types/__generated__/GovlyApi';

export const useSearchPagination = ({
  mutation,
  cacheKey,
  itemId = null
}: {
  // This was too much of a pain to type because of passing different mutations in
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutation: TypedUseMutation<{ query: any; results: any[]; meta: SearchQueryMeta }, any, any>;
  cacheKey: string;
  itemId?: string | null;
}) => {
  const { appBodyRef } = useAppLayoutContext();

  const [search, meta] = mutation({
    fixedCacheKey: cacheKey,
    selectFromResult: ({ isLoading, isSuccess, data: { query = {}, results = [], meta } = {} }) => {
      const { query: q, ...filters } = query;
      const index = results.findIndex(({ id }) => itemId === id);
      const nextId = index > -1 ? results[index + 1]?.id : null;
      const prevId = index > -1 ? results[index - 1]?.id : null;

      return {
        query: q,
        filters,
        isLoading,
        isSuccess,
        nextId,
        prevId,
        firstId: results[0]?.id,
        lastId: results[results.length - 1]?.id,
        ...meta
      };
    }
  });

  const { totalRelation, query, filters } = meta;
  let totalRelationPrefix = '';

  if (totalRelation && totalRelation.includes('gt')) {
    totalRelationPrefix = 'More Than ';
  }

  const onPageChange = async (newPage: number) => {
    const result = await search({ query, ...filters, page: newPage });

    const scrollContainer = appBodyRef.current;
    const yAnchor = (meta.currentPage ?? 0) < newPage ? 'top' : 'bottom';
    scrollContainer?.scrollTo({ [yAnchor]: 0, behavior: 'smooth' });

    return result;
  };

  return {
    totalRelationPrefix,
    onPageChange,
    ...meta
  };
};
