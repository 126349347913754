import React, { useState } from 'react';
import { Tag, Icon, CompoundTag } from '@blueprintjs/core';
import { truncate } from 'lodash-es';

import { OppSourceTimelineEvent } from '../OppHistoryTimeline/OppHistoryTimelineUtils';
import { OppSourceDrawer } from '../OppSourceDrawer/OppSourceDrawer';
import { OpenDrawer } from '../OppDetailsSources/OppDetailsSources';

import { Card, CardSection } from '@/app/atoms/Card/Card';
import { openDrawerOrNewTab } from '@/app/lib/navigation';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { NewTabTooltip } from '@/app/molecules/NewTabTooltip/NewTabTooltip';
import { OppSourceWithSource } from '@/types/__generated__/GovlyApi';
import { cn } from '@/app/lib/cn';
import { getLatestSequencedNoticeTypes } from '@/app/organisms/OppAttachmentsTable/OppAttachmentsUtils';

export type OppHistoryCardProps = {
  oppSource: OppSourceWithSource & OppSourceTimelineEvent;
};

export const OppHistoryCard = ({ oppSource }: OppHistoryCardProps) => {
  const [openDrawer, setOpenDrawer] = useState<OpenDrawer>(undefined);
  const sequencedNoticeTypes = getLatestSequencedNoticeTypes(oppSource.sequencedNoticeTypes);

  return (
    <div className="my-12" data-testid="opp-history-card">
      <div
        className={cn(
          'absolute -left-3 lg:-left-4 rounded-full p-2 w-6 h-6 lg:w-8 lg:h-8 flex items-center justify-center',
          oppSource.iconBgClass
        )}
      >
        <Icon icon={oppSource.icon} className={oppSource.iconTextClass} />
      </div>
      <div className="pl-0 lg:pl-8 space-y-8">
        <p className="text-gray-500 pl-8 lg:pl-0 pt-[3px] lg:pt-[6px]">
          {oppSource.noticeType} was updated on {oppSource.formattedPostedAt}
        </p>
        <Card compact>
          <CardSection className={cn('flex flex-col gap-y-2 bt-0')}>
            <LinkTag
              tag="a"
              data-test="opp-source-link"
              onClick={event => {
                openDrawerOrNewTab(event, `/opportunities/${oppSource.oppId}/sources/${oppSource.id}`, () =>
                  setOpenDrawer({
                    id: oppSource.oppId ?? '',
                    sourceId: oppSource.id ?? '',
                    subject: oppSource.title ?? ''
                  })
                );
              }}
              className="font-medium no-underline"
            >
              <NewTabTooltip title="View Opp Source">
                <h3 className="inline-block">{truncate(oppSource.title, { length: 200 })}</h3>
              </NewTabTooltip>
            </LinkTag>
          </CardSection>
          <CardSection className="flex flex-col gap-y-2">
            <div className="flex gap-x-2 flex-wrap">
              {sequencedNoticeTypes.map(noticeType => (
                <Tag key={noticeType} intent="success" minimal>
                  {noticeType}
                </Tag>
              ))}
            </div>

            <p className="mb-0">{oppSource.summary || '(No summary available.)'}</p>

            {oppSource.newAttachmentsCount + oppSource.updatedAttachmentsCount > 0 && (
              <div className="flex flex-row items-center gap-2">
                {oppSource.newAttachmentsCount > 0 && (
                  <CompoundTag minimal aria-label="new-attachments" leftContent="New Attachments">
                    {oppSource.newAttachmentsCount}
                  </CompoundTag>
                )}
                {oppSource.updatedAttachmentsCount > 0 && (
                  <CompoundTag minimal aria-label="updated-attachments" leftContent="Updated Attachments">
                    {oppSource.updatedAttachmentsCount}
                  </CompoundTag>
                )}
              </div>
            )}
          </CardSection>
        </Card>
      </div>

      {!!openDrawer && <OppSourceDrawer {...openDrawer} onClose={() => setOpenDrawer(undefined)} />}
    </div>
  );
};
