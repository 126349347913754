import React from 'react';
import { match } from 'ts-pattern';

import type { WorkspaceColumn, WorkspaceColumnValue } from '@/types/__generated__/GovlyApi';
import { useAuthorized } from '@/app/hooks/useAuthorize';
import { WorkspaceColumnCellText } from '@/app/organisms/WorkspaceColumnCell/WorkspaceColumnCellText';
import { WorkspaceColumnCellSelect } from '@/app/organisms/WorkspaceColumnCell/WorkspaceColumnCellSelect';
import { WorkspaceColumnCellBlockProps } from '@/app/organisms/WorkspaceColumnCell/WorkspaceColumnCellBlockProps';

type Cell = {
  workspaceId: string;
  column: WorkspaceColumn;
  value?: WorkspaceColumnValue;
} & Pick<WorkspaceColumnCellBlockProps, 'shouldShowBlankPlaceholder'>;

export type WorkspaceColumnCellProps = Cell;

export const WorkspaceColumnCell = ({
  workspaceId,
  column,
  value,
  shouldShowBlankPlaceholder = false
}: WorkspaceColumnCellProps) => {
  const canEdit = useAuthorized({ role: 'admin' });
  const common: Pick<WorkspaceColumnCellBlockProps, 'workspaceId' | 'canEdit' | 'shouldShowBlankPlaceholder'> = {
    workspaceId,
    canEdit,
    shouldShowBlankPlaceholder
  };

  const blockType = column?.contentBlock?.blockType;

  return match(blockType)
    .with('text_block', () => <WorkspaceColumnCellText value={value} workspaceColumn={column} {...common} />)
    .with('select_block', () => <WorkspaceColumnCellSelect value={value} workspaceColumn={column} {...common} />)
    .otherwise(() => null);
};
