import React, { useMemo, useRef } from 'react';
import { Tooltip, Button } from '@blueprintjs/core';

import { AssistantForm } from '../Assistant/AssistantForm';

import { makeWelcomeMessageFactory } from './OppWorkspaceAssistantWelcomeMessage';

import {
  useCreateWorkspaceAssistantThreadMutation,
  useGetWorkspaceAssistantThreadQuery
} from '@/api/workspaceAssistantThreadsApi';
import { cn } from '@/app/lib/cn';
import { useGetCurrentUserQuery } from '@/api/currentUserApi';
import { useDeviceWidth } from '@/app/hooks/useDeviceWidth';
import { Card, CARD_STICKY_HEADER_CLASS } from '@/app/atoms/Card/Card';
import { useNewMessageScrollToBottom } from '@/app/organisms/Assistant/useNewMessageScrollToBottom';
import { OppWorkspaceAssistantThreadHistory } from '@/app/organisms/OppWorkspaceAssistant/OppWorkspaceAssistantThreadHistory';
import { AssistantProcessingTag } from '@/app/organisms/Assistant/AssistantProcessingTag';
import { AssistantProvider, useAssistantStoreRef } from '@/app/organisms/Assistant/AssistantProvider';
import { useHandleNewThreadRequest } from '@/app/organisms/Assistant/useHandleNewThreadRequest';
import { toggleIsThreadHistoryOpen } from '@/app/organisms/Assistant/useAssistantStore';
import { Loading } from '@/app/atoms/Loading/Loading';

const AssistantMessageList = React.lazy(() =>
  import('@/app/organisms/Assistant/AssistantMessageList').then(m => ({ default: m.AssistantMessageList }))
);

export type OppWorkspaceAssistantProps = {
  workspaceIdProp: string;
};

const DEFAULT_PROMPTS = [
  'When is the RFQ for this opportunity due?',
  'Can you summarize all attached documents in this workspace?',
  'Can you explain what you can help with?'
];

export const OppWorkspaceAssistant = ({ workspaceIdProp }: OppWorkspaceAssistantProps) => {
  const { data: user } = useGetCurrentUserQuery();

  const welcomeMessageFactory = useMemo(() => makeWelcomeMessageFactory(user), [user]);

  return (
    <AssistantProvider
      type="workspace"
      workspaceId={workspaceIdProp}
      useGetThreadQuery={useGetWorkspaceAssistantThreadQuery}
      getThreadParams={({ threadId }) => [{ id: threadId || '', workspaceId: workspaceIdProp }, { skip: !threadId }]}
      useCreateThreadMutation={useCreateWorkspaceAssistantThreadMutation}
      createThreadParams={{ workspaceId: workspaceIdProp }}
      placeholder="Example: Please summarize the opportunity in this workspace."
      defaultPrompts={DEFAULT_PROMPTS}
      welcomeMessageFactory={welcomeMessageFactory}
    >
      <div className="relative">
        <div className="w-full flex flex-row">
          <OppWorkspaceAssistantThreadHistory />

          <div className="flex flex-col flex-1">
            <MessagesCard />
            <AssistantForm className="mt-4 px-4 lg:px-0" />
          </div>
        </div>
      </div>
    </AssistantProvider>
  );
};

const MessagesCard = () => {
  const { isDesktop } = useDeviceWidth();

  const scrollRef = useRef<HTMLDivElement>(null);
  useNewMessageScrollToBottom({ ref: scrollRef });

  return (
    <Card
      className={cn('relative w-full h-[400px] lg:h-[600px] overflow-y-auto', CARD_STICKY_HEADER_CLASS)}
      ref={scrollRef}
      title={
        <div className="flex gap-1 -mt-px w-full">
          <Tooltip content="Show Thread History" placement="right">
            <ThreadHistoryButton />
          </Tooltip>

          {isDesktop ? <NewThreadButton /> : null}

          <AssistantProcessingTag />
        </div>
      }
      titleRenderer={TitleRenderer}
      rightElement={
        isDesktop ? undefined : (
          <Tooltip content="New Thread" placement="right">
            <NewThreadButton />
          </Tooltip>
        )
      }
    >
      <React.Suspense fallback={<Loading />}>
        <AssistantMessageList />
      </React.Suspense>
    </Card>
  );
};

const TitleRenderer = ({ children }: React.PropsWithChildren) => {
  return <>{children}</>;
};

const ThreadHistoryButton = () => {
  const store = useAssistantStoreRef();
  return (
    <Button icon="history" onClick={() => toggleIsThreadHistoryOpen(store)} minimal aria-label="Show Thread History" />
  );
};

const NewThreadButton = () => {
  const { handleNewThreadRequest, isLoading: createThreadIsLoading } = useHandleNewThreadRequest();

  return (
    <Button
      icon="annotation"
      text="New Thread"
      onClick={handleNewThreadRequest}
      minimal
      loading={createThreadIsLoading}
    />
  );
};
