import { match, P } from 'ts-pattern';
import { partition } from 'lodash-es';

import { formErrorToast, successToast } from '@/app/lib/toaster';
import { useEventTracking } from '@/app/hooks/useEventTracking';
import { WorkspaceBase } from '@/types/__generated__/GovlyApi';
import {
  CreateWorkspace,
  LimitedFollow,
  UpdateWorkspace,
  useCreateWorkspaceMutation,
  useUpdateWorkspaceMutation
} from '@/api/workspacesApi';
import { useCurrentUserAttribute } from '@/api/currentUserApi';

export const useSubmitWorkspaceForm = ({
  shouldTrack = true,
  onSuccess,
  workableType,
  follows,
  trackingData
}: {
  follows: LimitedFollow[];
  trackingData?: Record<string, unknown>;
} & Partial<Pick<WorkspaceBase, 'workableType'>> & {
    shouldTrack?: boolean;
    onSuccess?: (workspace: WorkspaceBase) => void;
  }) => {
  const { trackEvent } = useEventTracking();
  const [updateWorkspace, { isLoading: isUpdating }] = useUpdateWorkspaceMutation();
  const [createWorkspace, { isLoading: isCreating }] = useCreateWorkspaceMutation();
  const currentOrgId = useCurrentUserAttribute('organizationId');

  const onSubmit = async (payload: CreateWorkspace['params'] | UpdateWorkspace['params']) => {
    try {
      const data = await match(payload)
        .with({ id: P.nonNullable }, vals => updateWorkspace(vals).unwrap())
        .otherwise(vals => createWorkspace(vals).unwrap());

      const { followsAttributes, ...remainingData } = payload;

      if (shouldTrack) {
        const [teamFollows, partnerFollows] = partition(
          follows.filter(({ state }) => state === 'following'),
          ({ organizationUser }) => organizationUser?.organizationId === currentOrgId
        );

        trackEvent({
          object: `${workableType?.toLowerCase() || 'opp'}_workspace` as Lowercase<string>,
          action: 'updated',
          properties: {
            workspaceId: 'id' in remainingData ? remainingData.id : undefined,
            ...remainingData,
            ...data,
            ...trackingData,
            follows: followsAttributes?.map(f => f.email),
            teamFollows: teamFollows?.map(f => f.organizationUser?.email).filter(Boolean),
            partnerFollows: partnerFollows?.map(f => f.organizationUser?.email).filter(Boolean)
          }
        });
      }

      if (onSuccess) {
        onSuccess(data);
      } else {
        successToast('Saved Changes');
      }
    } catch (e) {
      formErrorToast(400);
    }
  };

  return { onSubmit, isSubmitting: isUpdating || isCreating };
};
