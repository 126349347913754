import React, { useRef } from 'react';
import { Button, Icon, Menu, MenuItem, MenuItemProps } from '@blueprintjs/core';
import { ItemListRendererProps, ItemRenderer, Select } from '@blueprintjs/select';
import { ArrayHelpers } from 'formik';
import { useVirtualizer } from '@tanstack/react-virtual';
import { sortBy } from 'lodash-es';

import { type Recipient, useRecipientSelect } from '@/app/organisms/RecipientSelect/utils';
import { useGetPartnershipsQuery } from '@/api/partnershipsApi';
import { Partnership } from '@/types/__generated__/GovlyApi';
import { useGetDistributorProfilesQuery } from '@/api/distributorProfilesApi';

type Props = {
  helpers: ArrayHelpers<Recipient[]>;
};

const VIRTUALIZATION_THRESHOLD = 200;

export const RecipientSelectPartnerSelect = ({ helpers }: Props) => {
  const { data, isSuccess, isLoading } = useGetPartnershipsQuery();
  const { distributorOrgIds } = useGetDistributorProfilesQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      distributorOrgIds: new Set(result.data?.map(x => x.organizationId))
    })
  });
  const partners = ((data ?? []) as Partnership[]).filter(partner => !distributorOrgIds.has(partner.partnerId));

  const { values } = useRecipientSelect();
  const partnerIds = new Set(
    values.recipients.filter(recipient => recipient.type === 'partner').map(recipient => recipient.organizationId)
  );
  const remainingPartners = sortBy(
    partners.filter(partner => !partnerIds.has(partner.partnerId)),
    'partnerName'
  );

  if (isSuccess && partners.length === 0) {
    return null;
  }

  return (
    <Select
      popoverProps={{ usePortal: false }}
      items={remainingPartners}
      itemRenderer={itemRenderer}
      resetOnSelect
      onItemSelect={item => {
        helpers.push({ type: 'partner', organizationId: item.partnerId, name: item.partnerName, recipients: [] });
      }}
      itemPredicate={(query, item) => Boolean(item.partnerName?.toLowerCase().includes(query.toLowerCase()))}
      {...(remainingPartners.length > VIRTUALIZATION_THRESHOLD ? { itemListRenderer: virtualItemListRenderer } : {})}
    >
      <Button
        loading={isLoading}
        disabled={remainingPartners.length === 0}
        small
        minimal
        icon={<Icon icon="person" className="text-green-500" />}
        text="Partner"
        rightIcon="chevron-down"
      />
    </Select>
  );
};

const itemRenderer: ItemRenderer<Partnership> = (item, { handleClick }) => {
  return <Item key={item.partnerId} item={item} onClick={handleClick} />;
};

const Item = ({ item, ...props }: Partial<MenuItemProps> & { item: Partnership }) => {
  const { values } = useRecipientSelect();

  const selected = values.recipients.some(
    recipient => recipient.type === 'partner' && recipient.organizationId === item.partnerId
  );
  return <MenuItem key={item.partnerId} text={item.partnerName} onClick={props.onClick} selected={selected} />;
};

const ROW_HEIGHT = 30;

const virtualItemListRenderer = ({ items, ...props }: ItemListRendererProps<Partnership>) => {
  return <VirtualList items={items} {...props} />;
};

const VirtualList = ({ items, ...props }: ItemListRendererProps<Partnership>) => {
  const parentRef = useRef<HTMLUListElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => ROW_HEIGHT
  });

  return (
    <Menu ulRef={parentRef} className="min-h-[40px] overflow-y-auto w-[360px] p-0">
      <div className="relative" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
        {props.filteredItems.length === 0 && <MenuItem disabled={true} text="No results." />}
        {rowVirtualizer.getVirtualItems().map(virtualRow => {
          const item = props.filteredItems[virtualRow.index];
          if (!item) return null;
          return (
            <div
              key={virtualRow.index}
              className="absolute top-0 left-0 w-full"
              style={{
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`
              }}
            >
              {props.renderItem(item, virtualRow.index)}
            </div>
          );
        })}
      </div>
    </Menu>
  );
};
