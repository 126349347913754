import React from 'react';

import { CardHeading } from '@/app/atoms/Typography/Typography';
import { cn } from '@/app/lib/cn';
import { GovlyTableRowCountTag } from '@/app/molecules/GovlyTable/GovlyTableRowCountTag';

export const GovlyTableTitle = ({
  className,
  children,
  title,
  ...props
}: { title: string | React.ReactNode } & Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>) => {
  return (
    <span className={cn('flex gap-2 items-center', className)} {...props}>
      {typeof title === 'string' ? <CardHeading>{title}</CardHeading> : title}

      <GovlyTableRowCountTag />

      {children}
    </span>
  );
};
