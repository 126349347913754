import React, { useMemo, useRef, useState } from 'react';
import { FormGroup, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { match, P } from 'ts-pattern';

import { useUpdateWorkspaceColumnValueMutation } from '@/api/workspaceColumnsApi';
import { ContentBlockSelectTag } from '@/app/atoms/ContentBlockSelectTag/ContentBlockSelectTag';
import { WorkspaceColumnCellBlockProps } from '@/app/organisms/WorkspaceColumnCell/WorkspaceColumnCellBlockProps';
import { WorkspaceColumnCellSaveStatus } from '@/app/organisms/WorkspaceColumnCell/WorkspaceColumnCellSaveStatus';

export const WorkspaceColumnCellSelect = ({
  value: valueProp,
  workspaceId,
  workspaceColumn,
  canEdit,
  shouldShowBlankPlaceholder
}: WorkspaceColumnCellBlockProps) => {
  const [value, setValue] = useState(valueProp);
  const [updateValue, updateMeta] = useUpdateWorkspaceColumnValueMutation();
  const [inputValue, setInputValue] = useState(
    typeof value?.contentBlock.value === 'string' ? value?.contentBlock.value : ''
  );
  const initialValueRef = useRef(inputValue);
  const [shouldShowStatus, setShouldShowStatus] = useState(false);

  const save = async (newValue: string) => {
    setInputValue(newValue);
    if (newValue !== initialValueRef.current) {
      setShouldShowStatus(true);

      updateValue({
        id: value?.id,
        blockType: 'select_block',
        value: newValue,
        workspaceId,
        workspaceColumnId: workspaceColumn.id
      }).then(res => {
        setValue(res.data as typeof value);
        initialValueRef.current = inputValue;
        setTimeout(() => setShouldShowStatus(false), 2000);
      });
    }
  };
  const { options, valueMap } = useMemo(() => {
    const options = getOptions(workspaceColumn.contentBlock.options);
    const valueMap = Object.fromEntries(options.map(o => [o.label, o]));
    return { options, valueMap };
  }, [workspaceColumn]);

  const activeItem = valueMap[inputValue] as (typeof options)[number] | undefined;

  if (canEdit) {
    return (
      <FormGroup className="relative mb-0 w-min">
        <Select
          className="w-min"
          items={options}
          onItemSelect={e => save(e.label)}
          activeItem={activeItem}
          itemRenderer={renderItem}
          filterable={false}
        >
          <ContentBlockSelectTag interactive role="button" option={activeItem ?? { label: 'Select', color: '' }} />
        </Select>

        {shouldShowStatus && <WorkspaceColumnCellSaveStatus updateMeta={updateMeta} />}
      </FormGroup>
    );
  }

  return match({ activeItem, shouldShowBlankPlaceholder })
    .with({ activeItem: P.nonNullable }, ({ activeItem }) => <ContentBlockSelectTag option={activeItem} />)
    .with({ shouldShowBlankPlaceholder: true }, () => <span>(blank)</span>)
    .otherwise(() => null);
};

const renderItem: ItemRenderer<{ label: string; color: string }> = (item, { modifiers, handleClick, index }) => {
  return (
    <MenuItem
      key={index}
      active={modifiers.active}
      onClick={e => handleClick(e)}
      text={<ContentBlockSelectTag option={item} />}
    />
  );
};

function getOptions(options?: unknown): { label: string; color: string }[] {
  if (Array.isArray(options) && options.every(o => o && typeof o === 'object' && 'label' in o && 'color' in o)) {
    return options as { label: string; color: string }[];
  }
  return [];
}
