import { useMemo } from 'react';

import { useAuthenticatedNavContext } from './useAuthenticatedNavContext';
import { type DrawerNavItem, type NavItem, AuthenticatedNavItemsUtils as Utils } from './AuthenticatedNavItemsUtils';
import { OppHistoryTagContent } from './OppHistoryTagContent';

import { WorklistNavItem } from '@/app/organisms/AuthenticatedNav/WorklistNavItem';
import { WorklistNotificationsTabTitle } from '@/app/organisms/AuthenticatedNav/WorklistNotificationsTabTitle';
import { OppWorkspacesTagContent } from '@/app/organisms/AuthenticatedNav/OppWorkspacesTagContent';
import { OppAttachmentsTagContent } from '@/app/organisms/AuthenticatedNav/OppAttachmentsTagContent';
import { OppProvidersTagContent } from '@/app/organisms/AuthenticatedNav/OppProvidersTagContent';
import { useFeatureFlag } from '@/app/hooks/useFeatureFlag';

// TODO see if it's possible to just extend and use the routes array in routes.tsx to source this information.
export const useAuthenticatedNavItems = () => {
  const context = useAuthenticatedNavContext();
  const { currentUser, hasSouptoolsCatalog, pathname } = context;
  const pathnameKey = pathname.replace(/^\//, '');

  const setCurrent = useMemo(() => Utils.makeSetCurrent(pathnameKey), [pathnameKey]);
  const hasQuoteTemplating = useFeatureFlag('quote-templating');

  const searchableProducts = useMemo<NavItem[]>(
    () =>
      [
        {
          to: 'opportunities',
          label: 'Opportunities',
          icon: 'label',
          maxTabs: 4,
          children: [
            {
              to: 'opportunities',
              label: 'Results',
              icon: 'list-detail-view',
              children: [
                { to: 'opportunities/:id', label: '' },
                { to: 'opportunities/:id/sources/:id', label: '' },
                { to: 'opportunities/:id/workspaces', label: '' },
                { to: 'opportunities/:id/workspaces/:id', label: '' },
                { to: 'opportunities/new', label: '' }
              ]
            },
            { to: 'opportunities/analytics', label: 'Analytics', icon: 'timeline-line-chart' },
            { to: 'opportunities/saved_searches', label: 'Saved Searches', icon: 'bookmark' },
            { to: 'opportunities/insights', label: 'Usage Reports', icon: 'timeline-bar-chart' },
            {
              to: 'opportunities/searchable_feeds/fed',
              label: 'Feed Management',
              icon: 'align-justify',
              enabled: currentUser.subscriptionSeatPaid,
              children: [
                { to: 'opportunities/searchable_feeds/fed', label: 'Federal' },
                { to: 'opportunities/searchable_feeds/sled', label: 'SLED' },
                {
                  to: 'opportunities/searchable_feeds/managed',
                  label: 'Managed',
                  children: [{ to: 'opportunities/searchable_feeds/managed/:id', label: '' }]
                }
              ]
            },
            {
              to: 'opportunities/debug',
              label: 'Debug',
              icon: 'lifesaver',
              enabled: currentUser.sysAdmin,
              intent: 'warning'
            }
          ]
        },
        {
          to: 'awards',
          label: 'Awards',
          icon: 'pie-chart',
          children: [
            {
              to: 'awards/results',
              label: 'Results',
              icon: 'list-detail-view',
              children: [{ to: 'awards/:id', label: '' }],
              equivalentPaths: ['awards']
            },
            { to: 'awards/analytics', label: 'Analytics', icon: 'timeline-line-chart' },
            { to: 'awards/saved_searches', label: 'Saved Searches', icon: 'bookmark' }
          ]
        },
        {
          label: 'Budgets',
          to: 'budgets/agencies',
          icon: 'briefcase',
          children: [
            {
              label: 'Results',
              to: 'budgets/agencies',
              icon: 'list-detail-view',
              children: [
                {
                  to: 'budgets/agencies/:id',
                  label: ''
                }
              ]
            },
            {
              label: 'Budget Analysis',
              to: 'budgets/analysis',
              icon: 'generate',
              intent: 'warning',
              enabled: currentUser.sysAdmin
            }
          ]
        },
        {
          to: 'contacts',
          label: 'Contacts',
          icon: 'user',
          children: [
            {
              to: 'contacts',
              label: 'Results',
              icon: 'list-detail-view',
              children: [{ to: 'contacts/:id', label: '' }]
            },
            { to: 'contacts/saved_searches', label: 'Saved Searches', icon: 'bookmark' }
          ]
        }
      ] satisfies NavItem[],
    [currentUser.sysAdmin, currentUser.subscriptionSeatPaid]
  );

  const otherProducts = useMemo<NavItem[]>(
    () =>
      [
        {
          to: 'worklist/workspaces',
          label: 'Worklist',
          icon: 'manually-entered-data',
          Component: WorklistNavItem,
          children: [
            { to: 'worklist/workspaces', label: 'Following', icon: 'thumbs-up' },
            { to: 'worklist/calendar', label: 'Calendar', icon: 'calendar' },
            {
              to: 'worklist/notifications',
              label: 'Notifications',
              icon: 'notifications',
              TabTitle: WorklistNotificationsTabTitle
            }
          ]
        },
        {
          to: 'souptools/quotes',
          label: 'Souptools',
          enabled:
            currentUser.subscriptionHasSouptools &&
            currentUser.subscriptionSeatPaid &&
            !currentUser.compliancePreventUploads,
          icon: 'th',
          children: [
            {
              to: 'souptools/quotes',
              label: 'Quotes',
              icon: 'th',
              children: [{ to: 'souptools/quotes/:id', label: '' }]
            },
            {
              to: 'souptools/trs',
              label: 'TRs',
              icon: 'application',
              children: [{ to: 'souptools/trs/:id', label: '' }]
            },
            { to: 'souptools/tr_templates', label: 'TR Templates', icon: 'applications' },
            {
              to: 'souptools/catalog',
              label: 'Catalog',
              icon: 'book',
              enabled: hasSouptoolsCatalog
            }
          ]
        },
        {
          to: 'partnerships',
          label: 'Partnerships',
          icon: 'people',
          children: [
            {
              to: 'partnerships',
              label: 'My Partners',
              icon: 'people',
              children: [
                { label: 'Settings', to: `partnerships/:id` },
                { label: 'Shared Feeds', to: `partnerships/:id/shared_feeds` },
                { label: 'Contacts', to: `partnerships/:id/contacts` }
              ]
            },
            {
              to: 'partnerships/partner_portals',
              label: 'Partner Portals',
              icon: 'comment',
              enabled: currentUser.subscriptionSeatPaid
            },
            {
              to: 'partnerships/invites/sent',
              label: 'Invitations',
              icon: 'envelope',
              children: [{ to: 'partnerships/invites', label: '' }],
              enabled: currentUser.subscriptionSeatPaid
            },
            {
              to: 'vendors',
              label: 'Search for Partners',
              icon: 'search'
            }
          ]
        }
      ] satisfies NavItem[],
    [
      currentUser.subscriptionHasSouptools,
      currentUser.subscriptionSeatPaid,
      currentUser.compliancePreventUploads,
      hasSouptoolsCatalog
    ]
  );

  const systemNodes = useMemo<NavItem[]>(
    () =>
      [
        {
          to: '?modal=foia-request',
          label: 'FOIA Request',
          icon: 'annotation',
          enabled: currentUser.role === 'admin' && currentUser.subscriptionSeatPaid
        },
        {
          to: 'settings/users',
          label: 'Settings',
          icon: 'cog',
          children: [
            {
              to: 'settings/users',
              label: 'Users',
              icon: 'user',
              children: [
                { to: 'users/new', label: '' },
                { to: 'users/:id', label: '' },
                { to: 'users/:id/edit', label: '' }
              ]
            },
            {
              to: 'settings/organization_settings',
              label: 'Organization',
              icon: 'office'
            },
            {
              to: 'settings/quote_templates',
              label: 'Quote Templates',
              icon: 'clipboard',
              enabled: hasQuoteTemplating
            },
            {
              to: 'settings/vendor_profile',
              label: 'Vendor Profile',
              icon: 'mugshot'
            },
            {
              to: 'settings/labels',
              label: 'Labels',
              icon: 'tag'
            },
            {
              to: 'settings/contract_management',
              label: 'Contract Management',
              icon: 'manual',
              enabled: currentUser.subscriptionSeatPaid,
              children: [{ to: 'settings/contract_management/:slug', label: '' }]
            },
            {
              to: 'settings/contract_automation',
              label: 'Contract Automation',
              icon: 'application',
              enabled: currentUser.subscriptionSeatPaid
            },
            {
              to: 'settings/api_settings',
              label: 'API Settings',
              icon: 'database',
              children: [{ to: 'webhooks/:id', label: '' }]
            },
            {
              to: 'settings/organization_sys_admin_settings',
              label: 'Internal',
              enabled: currentUser.sysAdmin,
              icon: 'wrench',
              intent: 'warning'
            }
          ]
        }
      ] satisfies NavItem[],
    [currentUser.sysAdmin, currentUser.subscriptionSeatPaid, currentUser.role, hasQuoteTemplating]
  );

  const profileNodes = useMemo<NavItem[]>(
    () =>
      [
        { to: `users/${currentUser.id}`, label: `Profile`, icon: 'user' },
        { to: 'sign_out', label: `Sign Out`, absolute: true, icon: 'log-out' }
      ] satisfies NavItem[],
    [currentUser.id]
  );

  const sysAdminNodes = useMemo<NavItem[]>(
    () =>
      currentUser.sysAdmin
        ? ([
            {
              label: 'Sys Admin',
              to: 'sys_admin/organizations/new',
              icon: 'wrench',
              intent: 'warning',
              children: [
                {
                  label: 'Create Organization',
                  to: 'sys_admin/organizations/new'
                },
                {
                  label: 'SLED Dashboard',
                  to: 'sys_admin/sled_dashboard'
                },
                {
                  label: 'System Admins',
                  to: 'sys_admin/system_admins'
                },
                {
                  label: 'Settings',
                  to: 'sys_admin/settings'
                },
                {
                  label: 'Error Handling',
                  to: 'sys_admin/error_handling'
                },
                {
                  label: 'Feed Profiles',
                  to: 'sys_admin/feed_profiles'
                },
                {
                  label: 'Provider Feeds',
                  to: 'sys_admin/provider_feeds'
                },
                {
                  label: 'Vendors',
                  to: 'sys_admin/vendors',
                  children: [{ to: 'sys_admin/vendors/:id', label: '' }]
                },
                {
                  label: 'Profile Claims',
                  to: 'sys_admin/profile_claims'
                },
                {
                  label: 'Partnership Requests',
                  to: 'sys_admin/partnership_requests'
                },
                {
                  label: 'Reports',
                  to: 'sys_admin/reports',
                  icon: 'open-application',
                  absolute: true,
                  external: true
                },
                {
                  label: 'Sidekiq',
                  to: 'sys_admin/sidekiq',
                  icon: 'open-application',
                  absolute: true,
                  external: true
                }
              ]
            }
          ] satisfies NavItem[])
        : [],
    [currentUser.sysAdmin]
  );

  const subNavs = useMemo<NavItem[]>(
    () =>
      [
        {
          label: 'Opp',
          to: 'opportunities/:id',
          children: [
            {
              label: 'Overview',
              to: 'opportunities/:id',
              icon: 'label'
            },

            {
              label: 'Attachments',
              to: 'opportunities/:id/attachments',
              icon: 'paperclip',
              TagContent: OppAttachmentsTagContent,
              getTagProps: _ => ({ className: '[&:has(>span:empty)]:hidden' })
            },
            {
              label: 'History',
              to: 'opportunities/:id/history',
              icon: 'history',
              TagContent: OppHistoryTagContent,
              getTagProps: _ => ({ className: '[&:has(>span:empty)]:hidden' })
            },
            {
              label: 'Providers',
              to: 'opportunities/:id/providers',
              icon: 'office',
              TagContent: OppProvidersTagContent,
              getTagProps: _ => ({ className: '[&:has(>span:empty)]:hidden' })
            },
            {
              label: 'Workspaces',
              to: 'opportunities/:id/workspaces',
              icon: 'manually-entered-data',
              TagContent: OppWorkspacesTagContent,
              getTagProps: _ => ({ className: '[&:has(>span:empty)]:hidden' })
            }
          ]
        },
        {
          label: 'Workspace',
          to: 'opportunities/:oppId/workspaces/:workspaceId',
          children: [
            {
              label: 'Overview',
              to: 'opportunities/:oppId/workspaces/:workspaceId',
              icon: 'manual'
            },
            {
              label: 'Opportunity',
              to: 'opportunities/:oppId/workspaces/:workspaceId/opportunity',
              icon: 'label'
            },
            {
              label: 'Assistant',
              to: 'opportunities/:oppId/workspaces/:workspaceId/assistant',
              icon: 'generate'
            }
          ]
        },
        {
          label: 'Award',
          to: 'awards/:id',
          children: [
            {
              label: 'Overview',
              to: 'awards/:id',
              icon: 'manual'
            }
          ]
        }
      ] satisfies NavItem[],
    []
  );

  const drawerNavs = useMemo(
    () =>
      [
        {
          type: 'drawer',
          drawerType: 'opportunities',
          children: [
            { type: 'tab', tab: 'Overview', icon: 'manual' },
            {
              type: 'tab',
              tab: 'Attachments',
              icon: 'paperclip',
              TagContent: OppAttachmentsTagContent,
              getTagProps: _ => ({ className: '[&:has(>span:empty)]:hidden' })
            },
            {
              type: 'tab',
              tab: 'History',
              icon: 'history',
              TagContent: OppHistoryTagContent,
              getTagProps: _ => ({ className: '[&:has(>span:empty)]:hidden' })
            },
            {
              type: 'tab',
              tab: 'Providers',
              icon: 'office',
              TagContent: OppProvidersTagContent,
              getTagProps: _ => ({ className: '[&:has(>span:empty)]:hidden' })
            },
            {
              type: 'tab',
              tab: 'Workspaces',
              icon: 'manually-entered-data',
              TagContent: OppWorkspacesTagContent,
              getTagProps: _ => ({ className: '[&:has(>span:empty)]:hidden' })
            }
          ]
        },
        {
          type: 'drawer',
          drawerType: 'workspaces',
          children: [
            { type: 'tab', tab: 'Overview', icon: 'manual' },
            { type: 'tab', tab: 'Opportunity', icon: 'label' },
            {
              type: 'tab',
              tab: 'Assistant',
              icon: 'generate'
            }
          ]
        },
        {
          type: 'drawer',
          drawerType: 'awards',
          children: [{ type: 'tab', tab: 'Overview', icon: 'manual' }]
        }
      ] satisfies DrawerNavItem[],
    []
  );

  const sections = { searchableProducts, otherProducts, systemNodes, profileNodes, sysAdminNodes, subNavs };

  return {
    ...(Object.fromEntries(
      Object.entries(sections).map(([k, v]) => [k, setCurrent(Utils.getEnabledNodes(v))])
    ) as typeof sections),
    drawerNavs
  };
};
