import { useCallback } from 'react';
import { MenuItemProps } from '@blueprintjs/core';

import { useSelectNavItem } from './useSelectNavItem';
import { NavItem } from './AuthenticatedNavItemsUtils';

export function useGetNavMenuItemProps() {
  const selectItem = useSelectNavItem();

  return useCallback(
    (node: NavItem): MenuItemProps => {
      return {
        ...(node.absolute
          ? { href: `/${node.to}`.replace(/^\/+/, '/'), target: node.external ? '_blank' : undefined }
          : { onClick: () => selectItem(node) }),
        text: node.label,
        icon: node.icon,
        active: node.current,
        intent: node.intent
      };
    },
    [selectItem]
  );
}
