import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Tag, Tooltip } from '@blueprintjs/core';
import { Option } from 'space-monad';

import { WorkspaceIndex } from '@/types/__generated__/GovlyApi';
import { useGetOppQuery } from '@/api/oppsApi';
import { useGetCurrentUserQuery } from '@/api/currentUserApi';
import { possessive } from '@/app/lib/strings';
import { OppDetailsCreateWorkspace } from '@/app/organisms/OppDetailsCreateWorkspace/OppDetailsCreateWorkspace';
import { GovlyTable } from '@/app/molecules/GovlyTable/GovlyTable';
import { GovlyTableToolbar } from '@/app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTableTitle } from '@/app/molecules/GovlyTable/GovlyTableTitle';
import { OppWorkspaceWorkflowStageTag } from '@/app/organisms/OppWorkspaceWorkflowStage/OppWorkspaceWorkflowStage';
import { showWorkspace } from '@/app/lib/authorize';
import { OppWorkspaceHeader } from '@/app/organisms/OppWorkspaceHeader/OppWorkspaceHeader';
import { LabelList } from '@/app/organisms/LabelList/LabelList';
import { AvatarList } from '@/app/molecules/AvatarList/AvatarList';

const columnHelper = createColumnHelper<WorkspaceIndex>();

export const OppWorkspacesTable = ({ id: oppId, ...rest }: React.HTMLProps<HTMLDivElement> & { id: string }) => {
  const { data: { workspaces = [] } = {}, isLoading } = useGetOppQuery({ id: oppId });
  const { data: currentUser, isLoading: currentUserLoading } = useGetCurrentUserQuery();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        size: 200,
        cell: e => {
          const workspace = e.row.original;
          const {
            id: workspaceId,
            name,
            organizationDefault,
            createdByOrganization,
            privateAccess,
            follows = [],
            createdById
          } = workspace;
          const canView = showWorkspace({
            privateAccess,
            follows,
            currentOrgUserRole: currentUser?.role,
            createdById,
            currentOrgUserId: currentUser?.id
          });
          return (
            <OppWorkspaceHeader
              id={workspaceId}
              oppId={oppId}
              name={name}
              organizationDefault={organizationDefault}
              showLink={canView}
              createdByOrganization={createdByOrganization}
            />
          );
        }
      }),
      columnHelper.accessor('workflowStage', {
        header: 'Stage',
        enableSorting: false,
        size: 240,
        cell: e => <OppWorkspaceWorkflowStageTag workspaceId={e.row.original.id} />
      }),
      columnHelper.accessor(e => e.organizationLabels?.map(({ name }) => name).join(' '), {
        header: 'Labels',
        id: 'labels',
        filterFn: 'fuzzyText',
        enableSorting: false,
        size: 200,
        cell: e => {
          const { organizationLabels } = e.row.original;
          return <LabelList labels={organizationLabels || []} />;
        }
      }),
      columnHelper.accessor(
        row => {
          const { follows = [], createdByOrganization } = row;

          const participatingOrganizations = [
            ...new Set(follows.map(({ organizationUser: { organizationName } = {} }) => organizationName))
          ].sort(n => (n === createdByOrganization ? -1 : 1));

          return participatingOrganizations.map(organizationName => organizationName).join(' ');
        },
        {
          id: 'organizations',
          header: 'Organizations',
          cell: e => {
            const { follows = [], createdByOrganization } = e.row.original;

            const orgs = [
              ...new Set(follows.map(({ organizationUser: { organizationName } = {} }) => organizationName))
            ].sort(n => (n === createdByOrganization ? -1 : 1));

            return (
              <div className="flex flex-wrap gap-1">
                {orgs.map(organizationName => {
                  if (orgs.length > 1 && organizationName === createdByOrganization) {
                    return (
                      <Tag key={organizationName} minimal intent="warning">
                        {`Created by ${organizationName}`}
                      </Tag>
                    );
                  }

                  return (
                    <Tag key={organizationName} minimal>
                      {organizationName}
                    </Tag>
                  );
                })}
              </div>
            );
          }
        }
      ),
      columnHelper.display({
        id: 'comments',
        header: 'Comments',
        size: 100,
        cell: e => {
          const workspace = e.row.original;
          const { commentsCount } = workspace;

          return (
            !!commentsCount && (
              <div className="flex items-center justify-center">
                <Tag minimal icon="chat" intent="primary" aria-label={`${commentsCount} comments`}>
                  {commentsCount}
                </Tag>
              </div>
            )
          );
        }
      }),
      columnHelper.display({
        id: 'access',
        header: 'Access',
        size: 75,
        cell: e => {
          const { privateAccess } = e.row.original;

          return (
            privateAccess && (
              <div className="flex items-center justify-center">
                <Tooltip content="Only invited team members may join">
                  <Tag minimal intent="danger" icon="lock" aria-label="Private access" />
                </Tooltip>
              </div>
            )
          );
        }
      }),
      columnHelper.display({
        id: 'followers',
        header: 'Followers',
        cell: e => {
          const { follows = [] } = e.row.original;

          return follows.length ? (
            <AvatarList
              limit={3}
              avatarData={follows.map(
                ({ organizationUser: { name, initials, organizationName, avatar, avatarColor } = {}, active }) => ({
                  initials,
                  name,
                  organizationName,
                  avatar,
                  avatarColor,
                  notificationProps: {
                    intent: active ? 'success' : 'danger',
                    icon: active ? 'thumbs-up' : 'thumbs-down',
                    size: 'xl',
                    position: 'bottom'
                  }
                })
              )}
            />
          ) : null;
        }
      })
    ],
    [currentUser?.id, currentUser?.role, oppId]
  );

  const follows = Option(currentUser)
    .map(({ id, organization }) => [
      {
        organizationUserId: id,
        state: 'following' as const,
        notifications: 'user_setting' as const,
        organizationUser: Option(organization)
          .map(({ id }) => ({ organizationId: id }))
          .get()
      }
    ])
    .getOrElse([]);

  return (
    <div {...rest}>
      <GovlyTable
        id="organization_insights_activity_table"
        columns={columns}
        data={workspaces}
        isFixedLayout
        title={<GovlyTableTitle title="Workspaces" />}
        rightElement={
          <GovlyTableToolbar>
            <OppDetailsCreateWorkspace
              name={`${possessive(currentUser?.name)} Workspace`}
              oppId={oppId}
              follows={follows}
              tooltipProps={{ content: 'Create a new Workspace' }}
            />
          </GovlyTableToolbar>
        }
        isLoading={isLoading || currentUserLoading}
        emptyStateProps={{ icon: 'folder-open', title: 'No workspaces' }}
        initialState={{ pagination: { pageSize: 10 } }}
      />
    </div>
  );
};
