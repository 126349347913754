import { WorklistViewItem } from './types';

import { WorklistView } from '@/app/organisms/WorklistWorkspaces/useWorklistStore';

export const WORKLIST_REWARD_ID = 'worklist-reward-anchor';

export const DEFAULT_MY_WORKLIST_VIEW: WorklistView = {
  __typename: 'WorklistView',
  // Negative ints so they can be saved in the db
  id: '-1',
  name: 'My Worklist',
  tableState: { filters: { followers: [process.govlyEnv.currentUser.id] } },
  active: true,
  organizationId: process.govlyEnv.currentOrganization.id,
  personal: true
};

export const DEFAULT_TEAM_WORKLIST_VIEW: WorklistView = {
  __typename: 'WorklistView',
  // Negative ints so they can be saved in the db
  id: '-2',
  name: 'Team Worklist',
  tableState: { filters: { followers: ['all'] } },
  active: true,
  organizationId: process.govlyEnv.currentOrganization.id,
  personal: false
};

export const WORKLIST_VIEW_CREATE_NEW_ITEM: WorklistViewItem = {
  label: 'Create new',
  value: 'CREATE_NEW',
  icon: 'plus',
  group: 'view'
};
