import React from 'react';

import { ApplicationError, ApplicationErrorProps } from '@/app/atoms/ErrorFallback/ApplicationError';

export const NotFound = (props: ApplicationErrorProps) => (
  <ApplicationError
    {...props}
    title="Page not found."
    errorCode="404 ERROR"
    description="Sorry, we couldn’t find the page you’re looking for."
  />
);
