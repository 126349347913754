import { useEffect } from 'react';

import cable from '@/api/actionCable/consumer';
import { LlmAssistantMessageBreakout } from '@/types/__generated__/GovlyApi';
import { AssistantStoreRef, getThreadActions } from '@/app/organisms/Assistant/useAssistantStore';

// TODO model message types with discriminated union `type` field
type ThreadChannelMessage = {
  files_processing?: boolean;
  messages_committed?: LlmAssistantMessageBreakout[];
  message_in_progress?: string;
  message_delta?: {
    id: string;
    delta: {
      content: {
        index: number;
        text: {
          value: string;
          annotations?: never[];
        };
      }[];
    };
  };
  message_complete?: string;
  execute_tool_call?: {
    call: string;
    arguments: unknown;
  };
  thread_run_started?: string;
  thread_run_completed?: string;
};

const useAssistantThreadChannel = (store: AssistantStoreRef) => {
  const threadId = store.use.threadId();

  useEffect(() => {
    if (!threadId) return;

    const { makeEmptyMessage, updateMessage, updateMessageDelta, removeMessageByExternalId } = getThreadActions(
      threadId,
      store
    );

    const deltaBuffer: Map<string, string[]> = new Map();
    const deltaCallback = setInterval(() => {
      for (const messageId of deltaBuffer.keys()) {
        const delta = deltaBuffer.get(messageId)?.join('');
        deltaBuffer.delete(messageId);

        if (delta?.length) updateMessageDelta(messageId, delta);
      }
    }, 120);

    // TEMP until we figure out msw websockets with action cable
    if (process.env.NODE_ENV === 'test') return;

    const channel = cable.subscriptions.create(
      { channel: 'Llm::Assistant::ThreadChannel', id: threadId },
      {
        received(data: ThreadChannelMessage) {
          if (data.thread_run_started) {
            // no message yet, but we know the thread has started
            const message = makeEmptyMessage('thread_run_started');
            updateMessage('thread_run_started', { ...message, role: 'assistant' });
          } else if (data.message_in_progress) {
            // remove the spinner not attached to a message
            removeMessageByExternalId('thread_run_started');
            // insert a blank placeholder
            const message = makeEmptyMessage(data.message_in_progress);
            updateMessage(data.message_in_progress, { ...message, role: 'assistant' });
          } else if (data.message_delta) {
            const messageId = data.message_delta.id;
            const delta = data.message_delta.delta.content.map(c => c.text.value).join('');

            if (!deltaBuffer.has(messageId)) {
              deltaBuffer.set(messageId, [delta]);
            } else {
              deltaBuffer.get(messageId)?.push(delta);
            }
          } else if (data.message_complete) {
            // no-op, we'll clear is partial on committed
          } else if (data.messages_committed) {
            // messages are complete at this point, upsert the full database version
            data.messages_committed.forEach(message => {
              if (message.externalId) {
                updateMessage(message.externalId, { ...message, isPartial: false });
              } else {
                // this should never be null
                console.warn('upstream sent a message without an external id?', message);
              }
            });
          } else if (data.execute_tool_call) {
            // no-op
            // in the future, maybe this shows the tool use in the thread
          } else if (data.thread_run_completed) {
            // no-op
            // the message may still be printing out, but the thread run is done
          } else if (data.files_processing != null) {
            store.setState({ filesProcessing: data.files_processing });
          } else {
            // should be unreachable
            console.warn('strange channel object, ignoring:', data);
          }
        }
      }
    );

    return () => {
      channel.unsubscribe();
      clearInterval(deltaCallback);
    };
  }, [store, threadId]);
};

export { useAssistantThreadChannel };
