import React from 'react';
import { Text } from '@blueprintjs/core';

import { QuoteShow as Quote } from '@/types/__generated__/GovlyApi';
import { useOppWorkspaceQuoteListItem } from '@/app/organisms/OppWorkspaceQuoteForm/useOppWorkspaceQuoteListItem';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { OppWorkspaceQuoteMenu } from '@/app/organisms/OppWorkspaceQuoteForm/OppWorkspaceQuoteMenu';

type OppWorkspaceQuoteArtifactItemProps = {
  quote: Quote;
};

export function OppWorkspaceQuoteArtifactItem({ quote }: OppWorkspaceQuoteArtifactItemProps) {
  const { subtext, title, QuoteIcon, viewQuote, disableLineItems } = useOppWorkspaceQuoteListItem(quote);

  return (
    <div className="flex justify-between gap-x-2 items-center w-full hover:bg-gray-100 rounded px-1 -mx-1">
      <div className="flex-shrink-0">
        <QuoteIcon size={24} />
      </div>

      <div className="flex-1 truncate flex flex-col items-start">
        <LinkTag tag="button" className="font-bold max-w-full truncate" onClick={viewQuote} disabled={disableLineItems}>
          {title}
        </LinkTag>
        <Text className="text-xs max-w-full truncate">{subtext}</Text>
      </div>

      <div className="flex flex-shrink-0">
        <OppWorkspaceQuoteMenu quote={quote} buttonProps={{ small: true }} />
      </div>
    </div>
  );
}
