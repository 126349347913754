import React, { useMemo } from 'react';
import { Tag } from '@blueprintjs/core';
import { createColumnHelper } from '@tanstack/react-table';

import { useGetCurrentUserQuery } from '@/api/currentUserApi';
import { OppDetailsCreateWorkspace } from '@/app/organisms/OppDetailsCreateWorkspace/OppDetailsCreateWorkspace';
import { GovlyTable } from '@/app/molecules/GovlyTable/GovlyTable';
import { GovlyTableTitle } from '@/app/molecules/GovlyTable/GovlyTableTitle';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import {
  useOppDetailsProviderContactsTableData,
  ProviderContactRow,
  OppDetailsProps
} from '@/app/pages/OppDetailsProvidersPage/useOppDetailsProvidersPage';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { AvatarWithName } from '@/app/molecules/AvatarWithName/AvatarWithName';

const columnHelper = createColumnHelper<ProviderContactRow>();

export const OppDetailsProviderContactsTable = ({ id }: OppDetailsProps) => {
  const { data: currentUser, isLoading: currentUserLoading } = useGetCurrentUserQuery();
  const { providerContacts, isLoading } = useOppDetailsProviderContactsTableData({ id });

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        sortingFn: 'basic',
        size: 240,
        cell: e => (
          <AvatarWithName
            initials={e.row.original.initials}
            imgSrc={e.row.original.avatar?.thumbUrl}
            color={e.row.original.avatarColor}
            id={e.row.original.id}
            name={e.row.original.name || 'Missing'}
          />
        )
      }),
      columnHelper.accessor('email', { header: 'Email', size: 300, sortingFn: 'basic' }),
      columnHelper.accessor('organizationName', {
        header: 'Organization',
        size: 200,
        sortingFn: 'basic',
        cell: e => (
          <LinkTag to={`/partnerships/${e.row.original.peerId}`} className="font-medium">
            {e.row.original.organizationName}
          </LinkTag>
        )
      }),
      columnHelper.accessor('organizationTeams', {
        header: 'Team',
        size: 200,
        sortingFn: 'basic',
        cell: e => (
          <div className="flex gap-x-2">
            {e.row.original.organizationTeams?.map(team => (
              <Tag minimal key={team.id}>
                {team.name}
              </Tag>
            ))}
          </div>
        )
      }),
      columnHelper.display({
        id: 'create-workspace',
        size: 200,
        cell: e => (
          <div className="flex justify-end">
            <OppDetailsCreateWorkspace
              name={`${currentUser?.organization?.name} <> ${e.row.original.name}`}
              oppId={id}
              follows={[
                {
                  organizationUserId: e.row.original.id,
                  state: 'following',
                  notifications: 'user_setting',
                  organizationUser: { organizationId: e.row.original.organizationId }
                },
                ...(currentUser
                  ? [
                      {
                        organizationUserId: currentUser.id,
                        state: 'following' as const,
                        notifications: 'user_setting' as const,
                        organizationUser: currentUser.organization
                          ? { organizationId: currentUser.organization?.id }
                          : undefined
                      }
                    ]
                  : [])
              ]}
              tooltipProps={{ content: `Create a Workspace with ${e.row.original.name}` }}
              buttonProps={{ text: 'Create Workspace' }}
            />
          </div>
        )
      })
    ],
    [currentUser, id]
  );

  return (
    <GovlyTable
      id="provider_contacts_table"
      columns={columns}
      data={providerContacts}
      title={<GovlyTableTitle title={<CardHeadingSmall>Provider Contacts</CardHeadingSmall>} />}
      isFixedLayout
      subtitle="Provider contacts are available for collaboration in Workspaces"
      isLoading={isLoading || currentUserLoading}
      enableFilters={providerContacts.length > 3}
      emptyStateProps={{
        icon: 'office',
        title: 'No provider contacts',
        description: 'Provider contacts are available for collaboration in Workspaces'
      }}
      initialState={{
        sorting: [{ id: 'name', desc: false }],
        pagination: { pageSize: 5 }
      }}
    />
  );
};
