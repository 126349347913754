import * as yup from 'yup';
import { useFormikContext } from 'formik';
import { IconName } from '@blueprintjs/core';
import { match, P } from 'ts-pattern';
import { sortBy } from 'lodash-es';

import { LimitedFollow } from '@/api/workspacesApi';
import { OrganizationUserFollowerSelectInput } from '@/types/__generated__/GovlyApi';

export type SimpleRecipient = {
  name?: string;
  company?: string;
  new?: boolean;
  icon?: IconName;
  email: string;
  initials?: string;
  avatarUrl?: string;
};

type CommonPartnerRecipient = {
  organizationId: string;
  name: string;
  recipients: Extract<Recipient, { type: 'organizationUser' }>[];
};

export type Recipient =
  | ({ type: 'organizationUser'; notifyOnSave: boolean } & SimpleRecipient & LimitedFollow)
  | ({ type: 'partner' } & CommonPartnerRecipient)
  | ({ type: 'distributor' } & CommonPartnerRecipient)
  | { type: 'guest'; recipients: SimpleRecipient[] };

export type OrgUserRecipient = Extract<Recipient, { type: 'organizationUser' }>;
export type GuestRecipient = Extract<Recipient, { type: 'guest' }>;
export type PartnerRecipient = Extract<Recipient, { type: 'partner' }>;
export type DistributorRecipient = Extract<Recipient, { type: 'distributor' }>;

const simpleRecipientSchema = yup.object({
  name: yup.string().optional(),
  email: yup.string().email('Invalid email address').required('Email is required'),
  company: yup.string().optional(),
  new: yup.boolean().optional()
});

export const recipientsSchema = yup
  .array()
  .of(
    yup.object({
      type: yup.string().oneOf(['organizationUser', 'partner', 'distributor', 'guest']).required(),
      name: yup.string().when('type', { is: 'organizationUser', then: schema => schema.optional() }),
      email: yup.string().when('type', {
        is: 'organizationUser',
        then: schema => schema.email('Invalid email address').required('Email is required')
      }),
      company: yup.string().optional(),
      // Fields for partner/distributor types
      organizationId: yup.string().when('type', {
        is: ['partner', 'distributor'],
        then: schema => schema.required('Organization ID is required')
      }),
      // Nested recipients for partner/distributor/guest types
      recipients: yup
        .array()
        .of(simpleRecipientSchema)
        .when('type', { is: 'organizationUser', then: schema => schema.optional() })
    })
  )
  .required('At least one recipient is required');

export const useRecipientSelect = () => {
  const formik = useFormikContext<{ recipients: Recipient[] }>();

  return formik;
};

export const orgUserToOrgUserRecipient = (orgUser: OrganizationUserFollowerSelectInput): OrgUserRecipient => {
  return {
    type: 'organizationUser',
    name: orgUser.name,
    email: orgUser.email,
    organizationUser: { organizationId: orgUser.organizationId, email: orgUser.email },
    state: 'following',
    notifications: 'user_setting',
    organizationUserId: orgUser.id,
    notifyOnSave: true,
    initials: orgUser.initials,
    avatarUrl: orgUser.avatar?.thumbUrl
  };
};

export const followToOrgUserRecipient = (follow: LimitedFollow): OrgUserRecipient => {
  const { state, notifications, organizationUser, organizationUserId } = follow;
  return {
    type: 'organizationUser',
    email: organizationUser?.email ?? '(missing email)',
    name: organizationUser?.name ?? '(missing name)',
    organizationUser,
    organizationUserId,
    state,
    notifications,
    initials: organizationUser?.initials,
    avatarUrl: organizationUser?.avatar?.thumbUrl,
    notifyOnSave: false
  };
};

export const flattenRecipientsToFollows = (recipients: Recipient[]): (LimitedFollow & { notifyOnSave: boolean })[] => {
  return recipients.reduce(
    (acc, el) => {
      return match<Recipient, (LimitedFollow & { notifyOnSave: boolean })[]>(el)
        .with({ type: 'organizationUser' }, recipient => acc.concat(orgUserRecipientToLimitedFollow(recipient)))
        .with({ type: P.union('partner', 'distributor') }, ({ recipients }) =>
          acc.concat(recipients.map(orgUserRecipientToLimitedFollow))
        )
        .otherwise(() => acc);
    },
    [] as (LimitedFollow & { notifyOnSave: boolean })[]
  );
};

type EmailRecipient = { email: string; name?: string; company?: string };
export const flattenRecipientsToEmails = (recipients: Recipient[]): EmailRecipient[] => {
  return recipients.reduce((acc, el) => {
    return match<Recipient, EmailRecipient[]>(el)
      .with({ type: 'organizationUser' }, recipient => acc.concat(toEmailRecipient(recipient)))
      .with({ type: P.union('partner', 'distributor') }, ({ recipients }) =>
        acc.concat(recipients.map(toEmailRecipient))
      )
      .with({ type: 'guest' }, ({ recipients }) => acc.concat(recipients.map(toEmailRecipient)))
      .otherwise(() => acc);
  }, [] as EmailRecipient[]);
};

const toEmailRecipient = (recipient: SimpleRecipient | OrgUserRecipient): EmailRecipient => {
  return { email: recipient.email, name: recipient.name, company: recipient.company };
};

const orgUserRecipientToLimitedFollow = (recipient: OrgUserRecipient): LimitedFollow & { notifyOnSave: boolean } => {
  return {
    ...recipient,
    state: 'following' as const,
    organizationUser: recipient.organizationUser
  };
};

export const sortRecipients = (recipients: Recipient[]): Recipient[] => {
  return sortBy(recipients, [
    recipient => {
      const typePriority: Record<Recipient['type'], number> = {
        organizationUser: 0,
        partner: 1,
        distributor: 2,
        guest: 3
      };
      return typePriority[recipient.type];
    },
    recipient => (recipient.type === 'organizationUser' ? recipient.email : '')
  ]);
};
