import React from 'react';
import { useSearchParams } from 'react-router';

import { SurveyModal } from '@/app/organisms/SurveyModal/SurveyModal';

export const AppModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const modalType = searchParams.get('modal');
  let modal = null;
  switch (modalType) {
    case 'foia-request':
      modal = <SurveyModal type="foia-request" afterClose={() => setSearchParams({})} />;
      break;
    case 'sled-opps':
      modal = <SurveyModal type="sled-opps" afterClose={() => setSearchParams({})} />;
      break;
    default:
      break;
  }
  if (!modal) return null;

  return modal;
};
