import React from 'react';
import { Icon } from '@blueprintjs/core';
import { NonIdealState } from '@blueprintjs/core';
import { match } from 'ts-pattern';

import { cn } from '@/app/lib/cn';
import { LlmAssistantThreadIndex } from '@/types/__generated__/GovlyApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { Card, CARD_STICKY_HEADER_CLASS, CardBody } from '@/app/atoms/Card/Card';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import { useAssistantStoreRef, useCurrentThreadId } from '@/app/organisms/Assistant/AssistantProvider';
import { usePopulateThreadHistory } from '@/app/organisms/Assistant/usePopulateThreadHistory';
import { useDeviceWidth } from '@/app/hooks/useDeviceWidth';
import { selectThreadHistoryItem } from '@/app/organisms/Assistant/useAssistantStore';

export type AssistantThreadHistoryProps = React.PropsWithChildren<{
  isLoading: boolean;
}>;

export const AssistantThreadHistory = ({ isLoading, children }: AssistantThreadHistoryProps) => {
  usePopulateThreadHistory();
  const store = useAssistantStoreRef();
  const isThreadHistoryOpen = store.use.isThreadHistoryOpen();

  if (!isThreadHistoryOpen) return null;

  const childCount = React.Children.count(children);

  return (
    <Card
      className={cn(
        'absolute z-5 lg:relative flex flex-col w-full lg:w-1/3 mr-4 h-[--main-card-full-height] overflow-auto',
        CARD_STICKY_HEADER_CLASS
      )}
      title="Recent Threads"
      titleRenderer={CardHeadingSmall}
      icon={
        <Icon
          icon="chevron-left"
          className="text-gray-500 lg:hidden"
          onClick={() => store.setState({ isThreadHistoryOpen: false })}
        />
      }
    >
      <CardBody className="px-0 flex-1 h-[calc(var(--main-card-full-height)_-_var(--card-heading-small-height)]">
        {match({ isLoading, childCount })
          .with({ isLoading: true }, () => <Loading type="thread" threadCount={12} />)
          .with({ childCount: 0 }, () => <NonIdealState icon="horizontal-bar-chart-desc" title="No threads found" />)
          .otherwise(() => (
            <div className="overflow-y-auto h-full mx-4">{children}</div>
          ))}
      </CardBody>
    </Card>
  );
};

export const AssistantThreadHistoryItem = ({ thread }: { thread: LlmAssistantThreadIndex }) => {
  const activeThreadId = useCurrentThreadId();
  const store = useAssistantStoreRef();

  const { isDesktop } = useDeviceWidth();

  const handleThreadSelect = (id: string) => {
    selectThreadHistoryItem(store, { threadId: id, isDesktop });
  };

  return (
    <div
      key={thread.id}
      className={cn(
        'cursor-pointer transition-colors rounded px-[10px] py-[5px]',
        activeThreadId === thread.id ? 'bg-gray-400' : 'hover:bg-gray-400'
      )}
      onClick={() => handleThreadSelect(thread.id)}
    >
      <div className="flex justify-between items-center p-1">
        <div className="text-sm">
          <h3 className="font-medium">Thread {thread.id.slice(-4)}</h3>
          <p className="text-gray-500 text-xs">
            {thread.lastUserMessage?.content
              ? `${thread.lastUserMessage.content.slice(0, 40)}${thread.lastUserMessage.content.length > 40 ? '...' : ''}`
              : 'Empty thread'}
          </p>
        </div>
      </div>
    </div>
  );
};
