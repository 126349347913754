import React from 'react';
import { NonIdealState } from '@blueprintjs/core';

import { Card, CardBody } from '@/app/atoms/Card/Card';

export const SearchNoResultsMessage = ({ searchType }: { searchType?: string }) => {
  return (
    <Card>
      <CardBody>
        <NonIdealState
          icon="search"
          title="No search results"
          description={
            searchType ? (
              <div>
                {`Your search didn't match any ${searchType}.`}
                <br />
                {`Try adjusting your search filters.`}
              </div>
            ) : (
              <div>{`No results found for your search.`}</div>
            )
          }
        />
      </CardBody>
    </Card>
  );
};
