import React from 'react';
import { Icon, Spinner } from '@blueprintjs/core';
import { match, P } from 'ts-pattern';

import { useOppWorkspaceStoreRef } from '@/app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';
import { PdfIcon } from '@/images/components/PdfIcon';
import { QuoteShow } from '@/types/__generated__/GovlyApi';

export function useOppWorkspaceQuoteListItem(quote: QuoteShow) {
  const store = useOppWorkspaceStoreRef();
  const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  const QuoteIcon = ({ size = 36 }: { size?: number }) =>
    match(quote.analysisStatus)
      .with('completed', () => <PdfIcon size={size} />)
      .with('processing', () => <Spinner size={size} intent="primary" />)
      .otherwise(() => <Icon icon="document" size={size} />);

  const subtext = match(quote)
    // in progress
    .with({ analysisStatus: 'processing' }, () => {
      return 'Analyzing and extracting information from your document…';
    })
    // error cases
    .with({ analysisStatus: P.union('failed', 'unknown') }, () => {
      return 'Error extracting information from your document…';
    })
    // normal case
    .with(
      { analysisStatus: 'completed', llmExtractStatus: 'completed', lineItemsTotal: P.not(P.nullish) },
      ({ lineItemsTotal, lineItems }) => {
        const totalAsCurrency = moneyFormat.format(lineItemsTotal);
        const lineItemCount = lineItems?.length || 0;
        return `${lineItemCount} line items totalling ${totalAsCurrency}`;
      }
    )
    // llm wasn't run, this one is old
    .with(
      { analysisStatus: 'completed', llmExtractStatus: 'pending', lineItemsTotal: P.not(P.nullish) },
      ({ lineItemsTotal, lineItemsRaw }) => {
        const totalAsCurrency = moneyFormat.format(lineItemsTotal);
        const lineItemCount = lineItemsRaw?.length || 0;
        return `${lineItemCount} line items totalling ${totalAsCurrency}`;
      }
    )
    // llm wasn't run, and we didn't get a total from line item raw
    .with({ analysisStatus: 'completed', lineItemsRaw: P.not([]) }, ({ lineItemsRaw }) => {
      const lineItemCount = lineItemsRaw?.length || 0;
      return `${lineItemCount} line items`;
    })
    .otherwise(() => 'No line items found.');

  const hasLineItems = (quote.lineItemsRaw?.length || quote.lineItems?.length) && true;
  const disableLineItems = quote.analysisStatus !== 'completed' || !hasLineItems;

  return {
    title: quote.file?.name ?? '(untitled)',
    subtext,
    disableLineItems,
    QuoteIcon,
    viewQuote: () => store.setState({ quoteToView: quote })
  };
}
