import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import DOMPurify from 'dompurify';
import { AnchorButton, Button, Dialog } from '@blueprintjs/core';
import { Option } from 'space-monad';

import { useShareOppSourceMutation } from '@/api/oppSourcesApi';
import { Card, CardBody } from '@/app/atoms/Card/Card';
import { AttachmentList } from '@/app/organisms/AttachmentList/AttachmentList';
import { ShareViaEmailForm } from '@/app/organisms/ShareViaEmailForm/ShareViaEmailForm';
import { Breadcrumbs } from '@/app/molecules/Breadcrumbs/Breadcrumbs';
import { useGetOppQuery } from '@/api/oppsApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { NotFound } from '@/app/molecules/NotFound/NotFound';
import { DescriptionListField } from '@/app/molecules/DescriptionListField/DescriptionListField';
import { formatTime, DATETIME_24_SHORT } from '@/app/lib/dates';
import { useCurrentUserAttribute } from '@/api/currentUserApi';
import { OppDetailsDescriptionExpander } from '@/app/organisms/OppDetailsDescription/OppDetailsDescription';
import { SysAdminAddAttachmentsForm } from '@/app/organisms/SysAdminAddAttachmentsForm/SysAdminAddAttachmentsForm';

const JsonViewer = React.lazy(() =>
  import('@/app/molecules/JsonViewer/JsonViewer').then(mod => ({ default: mod.JsonViewer }))
);

type OppDetailsSourceProps = {
  oppId: string;
  oppSourceId: string;
};

export const OppDetailsSource = ({ oppId, oppSourceId }: OppDetailsSourceProps) => {
  const navigate = useNavigate();
  const [isSharing, setIsSharing] = useState(false);
  const [shareOppSource, { isLoading: isSubmittingShare }] = useShareOppSourceMutation();
  const disableDownloads = useCurrentUserAttribute('organization.settings.disableOppAttachmentDownloads');

  const [isSysAdminDialogOpen, setIsSysAdminDialogOpen] = useState(false);
  const [isAddingAttachments, setIsAddingAttachments] = useState(false);
  const isSysAdmin = useCurrentUserAttribute('sysAdmin');

  const { data: { oppSources, displayName, naics } = {}, isLoading } = useGetOppQuery({ id: oppId });

  if (isLoading) {
    return <Loading />;
  }

  const source = oppSources?.find(({ id }) => id === oppSourceId);

  if (!source) {
    return <NotFound />;
  }

  const { attachments = [], subject, mail, postedAt, modificationReason } = source;
  const body = mail?.body ?? '';

  const sanitizedBody = DOMPurify.sanitize(body);
  const hasHTML = /\/[a-z]*>/i.test(sanitizedBody);

  const breadcrumbs = [
    { text: 'Opportunities', to: '/opportunities' },
    { text: displayName, tag: 'button', onClick: () => navigate(`/opportunities/${oppId}`) }
  ];

  const data = Option(source.data)
    .map(data =>
      [
        { label: 'Buyer', value: data.buyer?.name ?? data.customer?.name },
        { label: 'Modified At', value: data.modifiedAt ? formatTime(data.modifiedAt, DATETIME_24_SHORT) : undefined },
        { label: 'Solicitation Number', value: data.solicitationNumber },
        { label: 'Set Aside', value: data.setAside },
        { label: 'NAICS', value: naics?.join(', ') },
        {
          label: 'Public Link',
          value: Option(source.externalUrl)
            .map<React.ReactNode>(url => (
              <AnchorButton
                minimal
                intent="primary"
                href={url}
                target="_blank"
                icon="open-application"
                key={url}
                className="-ml-[10px]"
              >
                {url}
              </AnchorButton>
            ))
            .get()
        }
      ].filter(({ value }) => value != null)
    )
    .getOrElse([]);

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Card title={subject}>
        <CardBody>
          <div className="flex justify-between">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              {!!postedAt && (
                <DescriptionListField label="Posted At" value={formatTime(postedAt, DATETIME_24_SHORT)} cols={2} />
              )}
              <DescriptionListField label="Modification Reason" value={modificationReason} cols={2} />
            </dl>
            <div className="flex gap-2 items-center">
              {isSysAdmin ? (
                <>
                  <Button
                    intent="primary"
                    minimal
                    text="Add Attachments"
                    icon="plus"
                    onClick={() => setIsAddingAttachments(true)}
                  />
                  <Button
                    intent="warning"
                    minimal
                    text="View Opp Source JSON"
                    icon="code"
                    onClick={() => setIsSysAdminDialogOpen(true)}
                  />
                </>
              ) : null}
              <Button
                data-test="share-source-button"
                text="Share Source"
                icon="send-message"
                onClick={() => setIsSharing(true)}
              />
            </div>
          </div>
        </CardBody>
        <CardBody>
          {data.map(({ label, value }) => (
            <div key={label} className="grid grid-cols-4">
              <span className="col-span-1 font-medium">{label}</span>
              <span className="col-span-3">{value}</span>
            </div>
          ))}
        </CardBody>
        <CardBody>
          <AttachmentList attachments={attachments} disableDownloads={disableDownloads} />
          {mail ? (
            <div className="prose prose-sm">
              {hasHTML ? (
                <div dangerouslySetInnerHTML={{ __html: sanitizedBody }} />
              ) : (
                <div className="whitespace-pre-line">{sanitizedBody}</div>
              )}
            </div>
          ) : (
            <OppDetailsDescriptionExpander description={source.data?.description || ''} />
          )}
        </CardBody>
      </Card>
      <Dialog isOpen={isSharing} className="min-w-[50%] p-0">
        <ShareViaEmailForm
          title="Share Source"
          onCancel={() => setIsSharing(false)}
          initialValues={{ subject, id: oppSourceId }}
          isSubmitting={isSubmittingShare}
          onSubmit={values => shareOppSource(values).unwrap()}
        />
      </Dialog>

      <React.Suspense fallback={''}>
        {isSysAdmin ? (
          <>
            <Dialog isOpen={isSysAdminDialogOpen} onClose={() => setIsSysAdminDialogOpen(false)}>
              <JsonViewer json={source} />
            </Dialog>
            <Dialog isOpen={isAddingAttachments} onClose={() => setIsAddingAttachments(false)}>
              <SysAdminAddAttachmentsForm oppSourceId={oppSourceId} onClose={() => setIsAddingAttachments(false)} />
            </Dialog>
          </>
        ) : null}
      </React.Suspense>
    </>
  );
};
