import React from 'react';
import { Button, Icon, MenuItem, MenuItemProps } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { ArrayHelpers } from 'formik';
import { sortBy } from 'lodash-es';

import { type Recipient, useRecipientSelect } from '@/app/organisms/RecipientSelect/utils';
import { useGetDistributorProfilesQuery } from '@/api/distributorProfilesApi';
import { DistributorProfile } from '@/types/__generated__/GovlyApi';

type Props = {
  helpers: ArrayHelpers<Recipient[]>;
};

export const RecipientSelectDistributorSelect = ({ helpers }: Props) => {
  const { data: distributorProfiles = [], isSuccess, isLoading } = useGetDistributorProfilesQuery();

  const { values } = useRecipientSelect();
  const distributorIds = new Set(
    values.recipients.filter(recipient => recipient.type === 'distributor').map(recipient => recipient.organizationId)
  );
  const remainingDistributors = sortBy(
    distributorProfiles.filter(distributor => !distributorIds.has(distributor.organizationId)),
    'organizationName'
  );

  if (isSuccess && distributorProfiles.length === 0) {
    return null;
  }

  return (
    <Select
      popoverProps={{ usePortal: false }}
      items={remainingDistributors}
      itemRenderer={itemRenderer}
      resetOnSelect
      filterable={remainingDistributors.length >= 10}
      onItemSelect={item => {
        helpers.push({
          type: 'distributor',
          organizationId: item.organizationId,
          name: item.organizationName,
          recipients: []
        });
      }}
    >
      <Button
        loading={isLoading}
        small
        disabled={remainingDistributors.length === 0}
        minimal
        icon={<Icon icon="office" className="text-blue-500" />}
        text="Distributor"
        rightIcon="chevron-down"
      />
    </Select>
  );
};

const itemRenderer: ItemRenderer<DistributorProfile> = (item, { handleClick }) => {
  return <Item key={item.organizationId} item={item} onClick={handleClick} />;
};

const Item = ({ item, ...props }: Partial<MenuItemProps> & { item: DistributorProfile }) => {
  const { values } = useRecipientSelect();

  const selected = values.recipients.some(
    recipient => recipient.type === 'distributor' && recipient.organizationId === item.organizationId
  );
  return (
    <MenuItem key={item.organizationId} text={item.organizationName} onClick={props.onClick} selected={selected} />
  );
};
