import React from 'react';
import { Tag, TagProps } from '@blueprintjs/core';

import { getDynamicTextColor } from '@/app/lib/colors';

export const ContentBlockSelectTag = ({
  option,
  style,
  ...props
}: TagProps & {
  option: { label: string; color?: string };
}) => {
  return (
    <Tag round style={{ backgroundColor: option.color, color: getDynamicTextColor(option.color), ...style }} {...props}>
      {option.label}
    </Tag>
  );
};
