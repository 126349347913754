import React from 'react';
import { Icon } from '@blueprintjs/core';
import { Tag } from '@blueprintjs/core';
import { useRef } from 'react';

import { useAssistantStoreRef, useCurrentThreadQuery } from '@/app/organisms/Assistant/AssistantProvider';

export const AssistantProcessingTag = () => {
  const filesProcessing = useAssistantStoreRef().use.filesProcessing();

  const { data: thread } = useCurrentThreadQuery();
  const filesProcessingInit = thread?.filesProcessing;

  const wasEverProcessing = useRef(filesProcessingInit);
  const isProcessing = filesProcessingInit || filesProcessing;

  if (isProcessing && !wasEverProcessing.current) wasEverProcessing.current = true;

  if (!wasEverProcessing.current) return null;

  return (
    <Tag
      className="self-center"
      minimal
      {...(isProcessing
        ? {
            intent: 'warning',
            icon: <Icon size={12} icon="unresolve" className="animate-spin" />,
            children: 'Processing files'
          }
        : { intent: 'success', icon: 'tick', children: 'Files processed' })}
    />
  );
};
