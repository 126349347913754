import React from 'react';
import { Icon } from '@blueprintjs/core';
import { ArrayHelpers, useField } from 'formik';

import { OrgUserRecipient, orgUserToOrgUserRecipient } from '@/app/organisms/RecipientSelect/utils';
import { RecipientSelectGroupItem } from '@/app/organisms/RecipientSelect/RecipientSelectGroupItem';
import { useGetOrganizationUsersQuery } from '@/api/organizationUsersApi';
import { OrganizationUserFollowerSelectInput } from '@/types/__generated__/GovlyApi';

type Props = {
  organizationId: string;
  helpers: ArrayHelpers;
  index: number;
};

export const RecipientSelectDistributorItem = ({ organizationId, helpers, index }: Props) => {
  const [nameField] = useField<string>(`recipients.${index}.name`);
  const [field] = useField<OrgUserRecipient[]>(`recipients.${index}.recipients`);

  const contactEmails = new Set(field.value.map(contact => contact.email));
  const orgUsersQuery = useGetOrganizationUsersQuery({
    filter: 'partners',
    organizationId,
    view: 'follower_select_input'
  });
  const contacts = (orgUsersQuery.data ?? []) as OrganizationUserFollowerSelectInput[];
  const remainingContacts = contacts
    .filter(contact => !contactEmails.has(contact.email))
    .map(orgUserToOrgUserRecipient);

  return (
    <RecipientSelectGroupItem
      type="distributor"
      buttonProps={{
        icon: <Icon icon="office" className="text-blue-500" />
      }}
      helpers={helpers}
      index={index}
      recipients={remainingContacts}
      name={`${nameField.value} contacts`}
    />
  );
};
