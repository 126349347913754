import { rootApi } from './rootApi';

import { CustomQuoteShow } from '@/types/__generated__/GovlyApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['CustomQuotes'] });

type GetCustomQuotesForWorkspace = {
  params: { workspaceId: string };
  result: CustomQuoteShow[];
};

type CreateCustomQuote = {
  params: { workspaceId: string; customQuoteTemplateId: string; includedQuoteIds: string[] };
  result: CustomQuoteShow;
};

type DeleteCustomQuote = {
  params: { id: string };
  result: void;
};

export const customQuotesApi = api.injectEndpoints({
  endpoints: build => ({
    getCustomQuotesForWorkspace: build.query<
      GetCustomQuotesForWorkspace['result'],
      GetCustomQuotesForWorkspace['params']
    >({
      query: ({ workspaceId }) => ({ url: '/v2/custom_quotes', params: { workspaceId } }),
      providesTags: ['CustomQuotes']
    }),

    createCustomQuote: build.mutation<CreateCustomQuote['result'], CreateCustomQuote['params']>({
      query: body => ({
        url: `/v2/custom_quotes`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['CustomQuotes']
    }),

    deleteCustomQuote: build.mutation<DeleteCustomQuote['result'], DeleteCustomQuote['params']>({
      query: ({ id }: { id: string }) => ({ url: `/v2/custom_quotes/${id}`, method: 'DELETE' }),
      invalidatesTags: ['CustomQuotes']
    })
  })
});

export const { useCreateCustomQuoteMutation, useDeleteCustomQuoteMutation, useGetCustomQuotesForWorkspaceQuery } =
  customQuotesApi;
