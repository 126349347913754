import React from 'react';
import { EntityTitle } from '@blueprintjs/core';

import { CardListItem, CardListItemProps } from '@/app/molecules/CardList/CardList';
import { useGetOppWorkspaceColumnValuesQuery } from '@/api/oppWorkspacesApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { useGetWorkspaceColumnsQuery } from '@/api/workspaceColumnsApi';
import { WorkspaceColumnCell } from '@/app/organisms/WorkspaceColumnCell/WorkspaceColumnCell';

export type OppWorkspaceColumnValuesCardListItemProps = {
  workspaceId: string;
  cardListItemProps: CardListItemProps;
};

export const OppWorkspaceColumnValuesCardListItem = ({
  workspaceId,
  cardListItemProps
}: OppWorkspaceColumnValuesCardListItemProps) => {
  const { data: columns = [], isLoading: isLoadingColumns } = useGetWorkspaceColumnsQuery();
  const { valuesByColumnId, isLoading: isLoadingColumnValues } = useGetOppWorkspaceColumnValuesQuery(
    {
      id: workspaceId
    },
    {
      selectFromResult: result => ({
        ...result,
        valuesByColumnId: Object.fromEntries(result.data?.map(cv => [cv.workspaceColumnId, cv]) ?? [])
      })
    }
  );

  const isLoading = isLoadingColumns || isLoadingColumnValues;

  return (
    <CardListItem title="Artifacts" {...cardListItemProps}>
      {isLoading ? (
        <Loading type="flex-row" />
      ) : (
        <dl className="flex flex-col gap-y-2 col-span-2">
          {columns?.map(column => (
            <EntityTitle
              key={column.id}
              title={column.contentBlock.name || '(unknown)'}
              subtitle={
                <WorkspaceColumnCell
                  shouldShowBlankPlaceholder
                  column={column}
                  value={valuesByColumnId[column.id]}
                  workspaceId={workspaceId}
                />
              }
            />
          ))}
        </dl>
      )}
    </CardListItem>
  );
};
