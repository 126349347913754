import React from 'react';
import { Button, Divider, Tooltip } from '@blueprintjs/core';

import { useAuthenticatedNavItems } from './useAuthenticatedNavItems';

import { Loading } from '@/app/atoms/Loading/Loading';
import { NavLinkButton } from '@/app/molecules/NavLinkButton/NavLinkButton';
import LogoIcon from '@/images/components/LogoIcon';
import { cn } from '@/app/lib/cn';
import { NavItem } from '@/app/organisms/AuthenticatedNav/AuthenticatedNavItemsUtils';
import { useNavStore, toggleExpanded } from '@/app/organisms/AuthenticatedNav/useNavStore';
import { useAuthenticatedNavContext } from '@/app/organisms/AuthenticatedNav/useAuthenticatedNavContext';

const DevTools = React.lazy(() => import('../../DevTools').then(({ DevTools }) => ({ default: DevTools })));

export const SideNav = () => {
  const data = useAuthenticatedNavItems();
  const { currentUser } = useAuthenticatedNavContext();
  const isExpanded = useNavStore.use.isExpanded();

  return (
    <div className="relative h-full [&:focus-within>button]:opacity-100 [&:hover>button]:opacity-100 transition-all">
      <Button
        className={cn(
          'mt-1 mr-1 absolute top-[3px] transition-all opacity-0',
          isExpanded ? 'left-52 rotate-180' : 'left-full'
        )}
        icon="menu-open"
        minimal
        small
        onClick={toggleExpanded}
        aria-label="show side nav"
      />

      <Shell className="transition-all">
        <Icon />

        <Nav>
          {data.searchableProducts.map((node, index) => (
            <Product key={index} node={node} index={index} />
          ))}

          <Divider className="w-full m-0" />

          {data.otherProducts.map((node, index) => (
            <Product key={index} node={node} index={index} />
          ))}

          <div className="flex-1" />

          <React.Suspense fallback="">
            {(process.govlyEnv.RAILS_ENV === 'development' || currentUser?.sysAdmin) && <DevTools />}
          </React.Suspense>

          {data.sysAdminNodes.map((node, index) => (
            <Product key={index} node={node} index={index} />
          ))}

          {data.systemNodes.map((node, index) => (
            <Product key={index} node={node} index={index} />
          ))}
        </Nav>
      </Shell>
    </div>
  );
};

export const SideNavLoading = () => {
  return (
    <Shell>
      <Icon />
      <Nav className="px-2">
        {Array.from({ length: 5 }).map((_, index) => (
          <Loading key={index} type="button" buttonProps={{ className: 'w-full' }} />
        ))}
        <div className="flex-1" />

        <Loading type="button" buttonProps={{ className: 'w-full' }} />
      </Nav>
    </Shell>
  );
};

const Shell = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const isExpanded = useNavStore.use.isExpanded();
  return (
    <div
      className={cn(
        'group flex flex-col gap-4 py-2 border-r-2 border-r-gray-200 dark:border-r-gray-600 h-full',
        isExpanded ? 'w-60' : 'w-12',
        className
      )}
      {...props}
    />
  );
};

const Icon = () => {
  return (
    <div className="px-3">
      <LogoIcon className="h-6 w-auto rounded" />
    </div>
  );
};

const Nav = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return <nav className={cn('flex flex-col items-start flex-1 gap-2 [&>*]:px-2', className)} {...props} />;
};

const Product = ({ node, index }: { node: NavItem; index: number }) => {
  const isExpanded = useNavStore.use.isExpanded();

  return node.Component ? (
    <node.Component key={index} node={node} index={index} />
  ) : (
    <Tooltip content={node.label} disabled={isExpanded} className="w-full">
      <NavLinkButton
        key={index}
        to={node.to}
        state={node.state}
        aria-current={node.current ? 'page' : undefined}
        buttonProps={{
          icon: node.icon,
          text: isExpanded ? node.label : undefined,
          intent: node.intent
        }}
        forceCurrent={node.current}
      />
    </Tooltip>
  );
};
