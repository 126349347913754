import React from 'react';
import { LoaderFunction, RouteObject, createBrowserRouter } from 'react-router';

import { RouteLayout } from '@/app/organisms/RouteLayout/RouteLayout';
import { BrowserWarning } from '@/app/molecules/BrowserWarning/BrowserWarning';
import { RouteErrorBoundary } from '@/app/organisms/RouteErrorBoundary/RouteErrorBoundary';
import { pageViewLoader } from '@/app/lib/trackEvent';

const routes: RouteObject[] = [
  {
    index: true,
    lazy: () => import('@/app/pages/HomePage/HomePage').then(({ HomePage }) => ({ element: <HomePage /> }))
  },
  { path: 'browser_warning', element: <BrowserWarning /> },
  {
    path: 'sign_in',
    lazy: () =>
      import('@/app/pages/SignInPage/SignInPage').then(({ SignInPage }) => ({
        element: <SignInPage />
      })),
    children: [
      {
        index: true,
        lazy: () =>
          import('@/app/organisms/SignInForm/SignInForm').then(({ SignInForm }) => ({ element: <SignInForm /> }))
      },
      {
        path: 'verify',
        lazy: () =>
          import('@/app/organisms/SignInVerification/SignInVerification').then(({ SignInVerification }) => ({
            element: <SignInVerification />
          }))
      }
    ]
  },
  {
    path: 'register',
    lazy: () =>
      import('@/app/pages/RegisterPage/RegisterPage').then(({ RegisterPage }) => ({
        element: <RegisterPage />
      })),
    children: [
      {
        index: true,
        lazy: () =>
          import('@/app/organisms/RegisterForm/RegisterForm').then(({ RegisterForm }) => ({
            element: <RegisterForm />
          }))
      },
      {
        path: 'verify',
        lazy: () =>
          import('@/app/organisms/RegisterVerification/RegisterVerification').then(({ RegisterVerification }) => ({
            element: <RegisterVerification />
          }))
      }
    ]
  },
  {
    path: 'vendors',
    lazy: () =>
      import('@/app/pages/VendorProfilesSearchPage/VendorProfilesSearchPage').then(({ VendorProfilesSearchPage }) => ({
        element: <VendorProfilesSearchPage />
      }))
  },
  {
    path: 'vendors/:slug',
    lazy: () =>
      import('@/app/pages/VendorProfilesShowPage/VendorProfilesShowPage').then(({ VendorProfilesShowPage }) => ({
        element: <VendorProfilesShowPage />
      })),
    children: [
      {
        index: true,
        lazy: () =>
          import('@/app/organisms/VendorProfileSummary/VendorProfileSummary').then(({ VendorProfileSummary }) => ({
            element: <VendorProfileSummary />
          }))
      },
      {
        path: 'partners',
        lazy: () =>
          import('@/app/organisms/VendorProfilePartners/VendorProfilePartners').then(({ VendorProfilePartners }) => ({
            element: <VendorProfilePartners />
          }))
      },
      {
        path: 'spending',
        lazy: () =>
          import('@/app/organisms/VendorProfileSpending/VendorProfileSpending').then(({ VendorProfileSpending }) => ({
            element: <VendorProfileSpending />
          }))
      },
      {
        path: 'contracts',
        lazy: () =>
          import('@/app/organisms/VendorProfileContracts/VendorProfileContracts').then(
            ({ VendorProfileContracts }) => ({
              element: <VendorProfileContracts />
            })
          )
      }
    ]
  },
  {
    path: '/',
    lazy: () =>
      import('@/app/organisms/AuthenticatedRouteLayout/AuthenticatedRouteLayout').then(
        ({ AuthenticatedRouteLayout }) => ({
          element: <AuthenticatedRouteLayout />
        })
      ),
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        path: 'unsubscribe',
        lazy: () =>
          import('@/app/pages/UnsubscribePage/UnsubscribePage').then(({ UnsubscribePage }) => ({
            element: <UnsubscribePage />
          }))
      },
      {
        path: 'onboarding',
        lazy: () =>
          import('@/app/pages/OnboardingPage/OnboardingPage').then(({ OnboardingPage }) => ({
            element: <OnboardingPage />
          }))
      },
      {
        path: 'opportunities/saved_searches',
        lazy: () =>
          import('@/app/pages/OppSavedSearchesPage/OppSavedSearchesPage').then(({ OppSavedSearchesPage }) => ({
            element: <OppSavedSearchesPage />
          }))
      },
      {
        path: 'contacts/saved_searches',
        lazy: () =>
          import('@/app/pages/ContactSavedSearchesPage/ContactSavedSearchesPage').then(
            ({ ContactSavedSearchesPage }) => ({
              element: <ContactSavedSearchesPage />
            })
          )
      },
      {
        path: 'awards/saved_searches',
        lazy: () =>
          import('@/app/pages/AwardSavedSearchesPage/AwardSavedSearchesPage').then(({ AwardSavedSearchesPage }) => ({
            element: <AwardSavedSearchesPage />
          }))
      },
      {
        path: 'opportunities/:id/sources/:sourceId',
        loader: pageViewLoader('opp_source'),
        lazy: () =>
          import('@/app/pages/OppSourcePage/OppSourcePage').then(({ OppSourcePage }) => ({
            element: <OppSourcePage />
          }))
      },
      {
        path: 'souptools',
        lazy: () =>
          import('@/app/pages/SouptoolsPage/SouptoolsPage').then(({ SouptoolsPage }) => ({
            element: <SouptoolsPage />
          })),
        children: [
          {
            path: 'trs',
            lazy: () =>
              import('@/app/pages/SouptoolsTrsPage/SouptoolsTrsPage').then(({ SouptoolsTrsPage }) => ({
                element: <SouptoolsTrsPage />
              }))
          },
          {
            path: 'quotes',
            lazy: () =>
              import('@/app/pages/SouptoolsQuotesPage/SouptoolsQuotesPage').then(({ SouptoolsQuotesPage }) => ({
                element: <SouptoolsQuotesPage />
              }))
          },
          {
            path: 'tr_templates',
            lazy: () =>
              import('@/app/pages/SouptoolsTrTemplatesPage/SouptoolsTrTemplatesPage').then(
                ({ SouptoolsTrTemplatesPage }) => ({ element: <SouptoolsTrTemplatesPage /> })
              )
          },
          {
            path: 'quotes/:id',
            lazy: () =>
              import('@/app/pages/SouptoolsQuotePage/SouptoolsQuotePage').then(({ SouptoolsQuotePage }) => ({
                element: <SouptoolsQuotePage />
              }))
          },
          {
            path: 'trs/:id',
            lazy: () =>
              import('@/app/pages/SouptoolsTrPage/SouptoolsTrPage').then(({ SouptoolsTrPage }) => ({
                element: <SouptoolsTrPage />
              }))
          },
          {
            path: 'catalog',
            lazy: () =>
              import('@/app/pages/SouptoolsCatalogPage/SouptoolsCatalogPage').then(({ SouptoolsCatalogPage }) => ({
                element: <SouptoolsCatalogPage />
              }))
          }
        ]
      },
      {
        path: 'opportunities/:oppId/workspaces/:workspaceId',
        lazy: () =>
          import('@/app/pages/OppWorkspacePage/OppWorkspacePage').then(({ loader, handle }) => ({
            loader: composeLoaders(pageViewLoader('workspace'), loader),
            handle
          })),
        children: [
          {
            index: true,
            lazy: () =>
              import('@/app/pages/OppWorkspacePage/OppWorkspacePage').then(({ OppWorkspacePage }) => ({
                element: <OppWorkspacePage />
              }))
          },
          {
            path: 'opportunity',
            lazy: () =>
              import('@/app/pages/OppWorkspacePage/OppWorkspacePage').then(({ OppWorkspacePage }) => ({
                element: <OppWorkspacePage tab="Opportunity" />
              }))
          },
          {
            path: 'assistant',
            lazy: () =>
              import('@/app/pages/OppWorkspacePage/OppWorkspacePage').then(({ OppWorkspacePage }) => ({
                element: <OppWorkspacePage tab="Assistant" />
              }))
          }
        ]
      },
      {
        path: 'partnerships/:id',
        lazy: () =>
          import('@/app/pages/PartnershipShowPage/PartnershipShowPage').then(({ PartnershipShowPage }) => ({
            element: <PartnershipShowPage />
          })),
        children: [
          {
            index: true,
            lazy: () =>
              import('@/app/organisms/PartnershipDetailsCard/PartnershipDetailsCard').then(
                ({ PartnershipDetailsCard }) => ({ element: <PartnershipDetailsCard /> })
              )
          },
          {
            path: 'shared_feeds',
            lazy: () =>
              import('@/app/organisms/PartnerFeeds/PartnerFeeds').then(({ PartnerFeeds }) => ({
                element: <PartnerFeeds />
              }))
          },
          {
            path: 'contacts',
            lazy: () =>
              import('@/app/organisms/PartnershipContactsTable/PartnershipContactsTable').then(
                ({ PartnershipContactsTable }) => ({ element: <PartnershipContactsTable /> })
              )
          }
        ]
      },
      {
        path: 'partnerships',
        lazy: () =>
          import('@/app/pages/PartnershipsPage/PartnershipsPage').then(({ PartnershipsPage }) => ({
            element: <PartnershipsPage />
          })),
        children: [
          {
            index: true,
            lazy: () =>
              import('@/app/organisms/PartnershipsTable/PartnershipsTable').then(({ PartnershipsTable }) => ({
                element: <PartnershipsTable />
              }))
          },
          {
            path: 'partner_portals',
            children: [
              {
                index: true,
                lazy: () =>
                  import('@/app/organisms/PartnerPortalLinks/PartnerPortalLinks').then(({ PartnerPortalLinks }) => ({
                    element: <PartnerPortalLinks />
                  }))
              }
            ]
          },
          {
            path: 'invites',
            lazy: () =>
              import('@/app/organisms/PartnershipRequests/PartnershipRequests').then(({ PartnershipRequests }) => ({
                element: <PartnershipRequests />
              })),
            children: [
              {
                index: true,
                lazy: () =>
                  import('@/app/organisms/PartnershipRequestsTable/PartnershipRequestsTable').then(
                    ({ PartnershipRequestsTable }) => ({ element: <PartnershipRequestsTable /> })
                  )
              },
              {
                path: 'sent',
                lazy: () =>
                  import('@/app/organisms/PartnershipRequestsTable/PartnershipRequestsTable').then(
                    ({ PartnershipRequestsTable }) => ({ element: <PartnershipRequestsTable /> })
                  )
              }
            ]
          }
        ]
      },
      {
        path: 'contacts',
        lazy: () =>
          import('@/app/pages/ContactSearchPage/ContactSearchPage').then(({ loader, ContactSearchPage }) => ({
            loader,
            element: <ContactSearchPage />
          }))
      },
      {
        path: 'contacts/:id',
        loader: pageViewLoader('contact'),
        lazy: () =>
          import('@/app/pages/ContactPage/ContactPage').then(({ ContactPage }) => ({
            element: <ContactPage />
          }))
      },
      {
        path: 'settings',
        lazy: () =>
          import('@/app/pages/SettingsPage/SettingsPage').then(({ SettingsPage }) => ({
            element: <SettingsPage />
          })),
        children: [
          {
            path: 'users',
            lazy: () =>
              import('@/app/organisms/UsersTable/UsersTable').then(({ UsersTable }) => ({ element: <UsersTable /> })),
            index: true
          },
          {
            path: 'organization_settings',
            lazy: () =>
              import('@/app/organisms/OrganizationSettings/OrganizationSettings').then(({ OrganizationSettings }) => ({
                element: <OrganizationSettings />
              }))
          },
          {
            path: 'quote_templates',
            lazy: () =>
              import('@/app/organisms/QuoteTemplateSettings/QuoteTemplateSettings').then(
                ({ QuoteTemplateSettings }) => ({
                  element: <QuoteTemplateSettings />
                })
              )
          },
          {
            path: 'vendor_profile',
            lazy: () =>
              import('@/app/organisms/VendorProfileCustomizationForm/VendorProfileCustomizationForm').then(
                ({ VendorProfileCustomizationForm }) => ({ element: <VendorProfileCustomizationForm /> })
              )
          },
          {
            path: 'contract_management',
            lazy: () =>
              import('@/app/organisms/ContractManagement/ContractManagement').then(({ ContractManagement }) => ({
                element: <ContractManagement />
              }))
          },
          {
            path: 'contract_automation',
            lazy: () =>
              import('@/app/pages/ContractAutomationPage/ContractAutomationPage').then(
                ({ ContractAutomationPage }) => ({
                  element: <ContractAutomationPage />
                })
              )
          },
          {
            path: 'labels',
            lazy: () =>
              import('@/app/organisms/LabelSettings/LabelSettings').then(({ LabelSettings }) => ({
                element: <LabelSettings />
              }))
          },
          {
            path: 'api_settings',
            lazy: () =>
              import('@/app/pages/ApiSettingsPage/ApiSettingsPage').then(({ ApiSettingsPage }) => ({
                element: <ApiSettingsPage />
              }))
          },
          {
            path: 'contract_management/:feedProfile',
            lazy: () =>
              import('@/app/organisms/ContractManagement/ContractManagement').then(({ ContractManagement }) => ({
                element: <ContractManagement />
              }))
          },
          {
            path: 'organization_sys_admin_settings',
            lazy: () =>
              import('@/app/organisms/OrganizationSysAdminSettings/OrganizationSysAdminSettings').then(
                ({ OrganizationSysAdminSettings }) => ({ element: <OrganizationSysAdminSettings /> })
              )
          }
        ]
      },
      {
        path: 'opportunities/:id',
        lazy: () =>
          import('@/app/pages/OppDetailsLayoutPage/OppDetailsLayoutPage').then(
            ({ loader, OppDetailsLayoutPage, handle }) => ({
              loader: composeLoaders(pageViewLoader('opp'), loader),
              handle,
              element: <OppDetailsLayoutPage />
            })
          ),
        children: [
          {
            index: true,
            lazy: () =>
              import('@/app/pages/OppDetailsOverviewPage/OppDetailsOverviewPage').then(
                ({ OppDetailsOverviewPage }) => ({
                  element: <OppDetailsOverviewPage />
                })
              )
          },
          {
            path: 'workspaces',
            lazy: () =>
              import('@/app/pages/OppDetailsWorkspacesPage/OppDetailsWorkspacesPage').then(
                ({ OppDetailsWorkspacesPage }) => ({ element: <OppDetailsWorkspacesPage /> })
              )
          },
          {
            path: 'attachments',
            lazy: () =>
              import('@/app/pages/OppDetailsAttachmentsPage/OppDetailsAttachmentsPage').then(
                ({ OppDetailsAttachmentsPage }) => ({ element: <OppDetailsAttachmentsPage /> })
              )
          },
          {
            path: 'history',
            lazy: () =>
              import('@/app/pages/OppDetailsHistoryPage/OppDetailsHistoryPage').then(({ OppDetailsHistoryPage }) => ({
                element: <OppDetailsHistoryPage />
              }))
          },
          {
            path: 'providers',
            lazy: () =>
              import('@/app/pages/OppDetailsProvidersPage/OppDetailsProvidersPage').then(
                ({ OppDetailsProvidersPage }) => ({ element: <OppDetailsProvidersPage /> })
              )
          }
        ]
      },
      {
        path: 'opportunities',
        lazy: () =>
          import('@/app/pages/OppSearchPage/OppSearchPage').then(({ loader, OppSearchPage, handle }) => ({
            loader,
            handle,
            element: <OppSearchPage />
          })),
        children: [
          {
            index: true,
            lazy: () =>
              import('@/app/organisms/OppSearchResultTable/OppSearchResultTable').then(({ OppSearchResultTable }) => ({
                element: <OppSearchResultTable />
              }))
          },
          {
            path: 'analytics',
            lazy: () =>
              import('@/app/pages/OppSearchAnalyticsPage/OppSearchAnalyticsPage').then(
                ({ OppSearchAnalyticsPage }) => ({
                  element: <OppSearchAnalyticsPage />
                })
              )
          },
          {
            path: 'debug',
            lazy: () =>
              import('@/app/organisms/OppSearchDebugger/OppSearchDebugger').then(({ OppSearchDebugger }) => ({
                element: <OppSearchDebugger />
              }))
          }
        ]
      },
      {
        path: 'opportunities/insights',
        lazy: () =>
          import('@/app/pages/OrganizationInsightsPage/OrganizationInsightsPage').then(
            ({ OrganizationInsightsPage }) => ({ element: <OrganizationInsightsPage /> })
          )
      },
      {
        path: 'opportunities/searchable_feeds',
        lazy: () =>
          import('@/app/organisms/SearchableFeeds/SearchableFeeds').then(({ SearchableFeeds }) => ({
            element: <SearchableFeeds />
          })),
        children: [
          {
            index: true,
            lazy: () =>
              import('@/app/organisms/SearchableFeeds/SearchableFeedsFed').then(({ SearchableFeedsFed }) => ({
                element: <SearchableFeedsFed />
              }))
          },
          {
            path: 'fed',
            lazy: () =>
              import('@/app/organisms/SearchableFeeds/SearchableFeedsFed').then(({ SearchableFeedsFed }) => ({
                element: <SearchableFeedsFed />
              }))
          },
          {
            path: 'sled',
            lazy: () =>
              import('@/app/organisms/SearchableFeeds/SearchableFeedsListSLED/SearchableFeedsListSLED').then(
                ({ SearchableFeedsListSLED }) => ({ element: <SearchableFeedsListSLED /> })
              )
          },
          {
            path: 'managed',
            lazy: () =>
              import('@/app/organisms/ManagedFeeds/ManagedFeeds').then(({ ManagedFeeds }) => ({
                element: <ManagedFeeds />
              }))
          },
          {
            path: 'managed/:id',
            lazy: () =>
              import('@/app/organisms/ManagedFeed/ManagedFeed').then(({ ManagedFeed }) => ({
                element: <ManagedFeed />
              }))
          }
        ]
      },
      {
        path: 'awards/:id',
        lazy: () =>
          import('@/app/pages/AwardDetailsLayoutPage/AwardDetailsLayoutPage').then(
            ({ loader, AwardDetailsLayoutPage, handle }) => ({
              loader: composeLoaders(pageViewLoader('award'), loader),
              handle,
              element: <AwardDetailsLayoutPage />
            })
          ),
        children: [
          {
            index: true,
            lazy: () =>
              import('@/app/pages/AwardDetailsOverviewPage/AwardDetailsOverviewPage').then(
                ({ AwardDetailsOverviewPage }) => ({ element: <AwardDetailsOverviewPage /> })
              )
          }
        ]
      },
      {
        path: 'awards',
        lazy: () =>
          import('@/app/pages/AwardSearchPage/AwardSearchPage').then(({ loader, AwardSearchPage, handle }) => ({
            loader,
            handle,
            element: <AwardSearchPage />
          })),
        children: [
          {
            index: true,
            lazy: () =>
              import('@/app/organisms/AwardSearchResults/AwardSearchResults').then(({ AwardSearchResults }) => ({
                element: <AwardSearchResults />
              }))
          },
          {
            path: 'analytics',
            lazy: () =>
              import('@/app/organisms/AwardSearchAnalytics/AwardSearchAnalytics').then(({ AwardSearchAnalytics }) => ({
                element: <AwardSearchAnalytics />
              }))
          },
          {
            path: 'results',
            lazy: () =>
              import('@/app/organisms/AwardSearchResults/AwardSearchResults').then(({ AwardSearchResults }) => ({
                element: <AwardSearchResults />
              }))
          }
        ]
      },
      {
        path: 'budgets',
        children: [
          {
            path: 'analysis',
            lazy: () =>
              import('@/app/pages/BudgetsOverviewPage/BudgetsOverviewPage').then(({ BudgetsOverviewPage }) => ({
                element: <BudgetsOverviewPage />
              }))
          },
          {
            path: 'agencies',
            lazy: () =>
              import('@/app/pages/GovernmentEntitiesPage/GovernmentEntitiesPage').then(
                ({ GovernmentEntitiesPage }) => ({
                  element: <GovernmentEntitiesPage />
                })
              )
          },
          {
            path: 'agencies/:id',
            lazy: () =>
              import('@/app/pages/GovernmentEntityPage/GovernmentEntityPage').then(({ GovernmentEntityPage }) => ({
                element: <GovernmentEntityPage />
              }))
          }
        ]
      },
      {
        path: 'users',
        lazy: () =>
          import('@/app/pages/UserProfilePage/UserProfilePage').then(({ UserProfilePage }) => ({
            element: <UserProfilePage />
          })),
        children: [
          {
            path: 'new',
            lazy: () =>
              import('@/app/organisms/UserProfileSettings/UserProfileSettings').then(({ UserProfileSettings }) => ({
                element: <UserProfileSettings />
              }))
          },
          {
            path: ':id',
            lazy: () =>
              import('@/app/organisms/UserProfile/UserProfile').then(({ UserProfile }) => ({
                element: <UserProfile />
              }))
          },
          {
            path: ':id/edit',
            lazy: () =>
              import('@/app/organisms/UserProfileSettings/UserProfileSettings').then(({ UserProfileSettings }) => ({
                element: <UserProfileSettings />
              }))
          }
        ]
      },
      {
        path: 'webhooks/:id',
        lazy: () =>
          import('@/app/pages/WebhookPage/WebhookPage').then(({ WebhookPage }) => ({
            element: <WebhookPage />
          }))
      },
      {
        path: 'worklist',
        lazy: () =>
          import('@/app/pages/WorklistPage/WorklistPage').then(({ WorklistPage, loader, handle }) => ({
            loader,
            handle,
            element: <WorklistPage />
          })),
        children: [
          {
            path: 'workspaces',
            index: true,
            lazy: () =>
              import('@/app/organisms/WorklistWorkspaces/WorklistWorkspaces').then(({ WorklistWorkspaces }) => ({
                element: <WorklistWorkspaces />
              }))
          },
          {
            path: 'calendar',
            lazy: () =>
              import('@/app/organisms/WorklistCalendar/WorklistCalendar').then(({ WorklistCalendar }) => ({
                element: <WorklistCalendar />
              }))
          },
          {
            path: 'notifications',
            lazy: () =>
              import('@/app/organisms/ActivityNotificationsTable/ActivityNotificationsTable').then(
                ({ ActivityNotificationsTable }) => ({ element: <ActivityNotificationsTable /> })
              )
          }
        ]
      },
      {
        path: 'sys_admin',
        lazy: () =>
          import('@/app/pages/SysAdminPage/SysAdminPage').then(({ SysAdminPage }) => ({
            element: <SysAdminPage />
          })),
        children: [
          { index: true, element: <h1>Govly Sys Admin App</h1> },
          {
            path: 'organizations/new',
            lazy: () =>
              import('@/app/organisms/OrganizationSysAdminForm/OrganizationSysAdminForm').then(
                ({ OrganizationSysAdminForm }) => ({
                  element: <OrganizationSysAdminForm />
                })
              )
          },
          {
            path: 'sled_dashboard',
            lazy: () =>
              import('@/app/organisms/SLEDDashboardTrawlerConfigs/SLEDDashboardTrawlerConfigs').then(
                ({ SLEDDashboardTrawlerConfigs }) => ({ element: <SLEDDashboardTrawlerConfigs /> })
              )
          },
          {
            path: 'settings',
            lazy: () =>
              import('@/app/organisms/SysAdminSettings/SysAdminSettings').then(({ SysAdminSettings }) => ({
                element: <SysAdminSettings />
              }))
          },
          {
            path: 'error_handling',
            lazy: () =>
              import('@/app/pages/SysAdminErrorHandlingPage/SysAdminErrorHandlingPage').then(
                ({ SysAdminErrorHandlingPage }) => ({ element: <SysAdminErrorHandlingPage /> })
              )
          },
          {
            path: 'system_admins',
            lazy: () =>
              import('@/app/organisms/SysAdminUsers/SysAdminUsers').then(({ SysAdminUsers }) => ({
                element: <SysAdminUsers />
              }))
          },
          {
            path: 'vendors',
            lazy: () =>
              import('@/app/organisms/VendorsTable/VendorsTable').then(({ VendorsTable }) => ({
                element: <VendorsTable />
              }))
          },
          {
            path: 'vendors/:id',
            lazy: () =>
              import('@/app/organisms/VendorProfileForm/VendorProfileForm').then(({ VendorProfileForm }) => ({
                element: <VendorProfileForm />
              }))
          },
          {
            path: 'provider_feeds',
            lazy: () =>
              import('@/app/organisms/ProviderFeedsTable/ProviderFeedsTable').then(({ ProviderFeedsTable }) => ({
                element: <ProviderFeedsTable />
              }))
          },
          {
            path: 'profile_claims',
            lazy: () =>
              import('@/app/organisms/ProfileClaimsTable/ProfileClaimsTable').then(({ ProfileClaimsTable }) => ({
                element: <ProfileClaimsTable />
              }))
          },
          {
            path: 'partnership_requests',
            lazy: () =>
              import('@/app/organisms/PartnershipRequestsTable/PartnershipRequestsTable').then(
                ({ PartnershipRequestsTable }) => ({ element: <PartnershipRequestsTable isAdmin /> })
              )
          }
        ]
      }
    ]
  }
];

const composeLoaders = (...loaders: LoaderFunction[]): LoaderFunction => {
  return async (...args) => {
    let result = null;

    for (const loader of loaders) {
      result = await loader(...args);
    }

    return result;
  };
};

const root = [
  {
    path: '/',
    element: <RouteLayout />,
    children: routes,
    errorElement: <RouteErrorBoundary />
  }
];

export const router = createBrowserRouter(root);
