import { useMemo } from 'react';

import { useAuthenticatedNavContext } from './useAuthenticatedNavContext';
import { AuthenticatedNavItemsUtils as Utils } from './AuthenticatedNavItemsUtils';
import { useAuthenticatedNavItems } from './useAuthenticatedNavItems';

export const useTopNavItems = () => {
  const context = useAuthenticatedNavContext();
  const { pathname } = context;
  const pathnameKey = pathname.replace(/^\//, '');
  const pathDoesMatch = Utils.makePathDoesMatch(pathnameKey);
  const isCurrent = useMemo(() => Utils.makeIsCurrent(pathnameKey), [pathnameKey]);

  const data = useAuthenticatedNavItems();

  const parent = useMemo(() => {
    return [
      ...(data.subNavs ?? []),
      ...(data.searchableProducts ?? []),
      ...(data.otherProducts ?? []),
      ...(data.systemNodes ?? []),
      ...(data.sysAdminNodes ?? [])
    ].find(isCurrent);
  }, [data.otherProducts, data.searchableProducts, data.subNavs, data.sysAdminNodes, data.systemNodes, isCurrent]);

  const children = useMemo(() => {
    if (!parent) return [];
    const lookup = Utils.setParentChildrenToDecscendant({}, parent, parent.children);
    const key = Object.keys(lookup).find(pathDoesMatch) ?? pathnameKey;

    return lookup[key] ?? [];
  }, [parent, pathDoesMatch, pathnameKey]);

  return useMemo(() => ({ children, parent, profileNodes: data.profileNodes }), [children, data.profileNodes, parent]);
};
