import { useCallback } from 'react';
import { useSearchParams } from 'react-router';

import type { DrawerNavTab } from './AuthenticatedNavItemsUtils';

import { DRAWER_PARAMS } from '@/app/organisms/AppDrawer/constants';

export function useSelectDrawerNavItem() {
  const [, setSearchParams] = useSearchParams();

  return useCallback(
    (node: DrawerNavTab) => {
      if (!node) return;

      setSearchParams(prev => {
        prev.set(DRAWER_PARAMS.tab, node.tab);
        return prev;
      });
    },
    [setSearchParams]
  );
}
