import React, { useRef, useState } from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';

import { WorkspaceColumnCellBlockProps } from '@/app/organisms/WorkspaceColumnCell/WorkspaceColumnCellBlockProps';
import { useUpdateWorkspaceColumnValueMutation } from '@/api/workspaceColumnsApi';
import { WorkspaceColumnCellSaveStatus } from '@/app/organisms/WorkspaceColumnCell/WorkspaceColumnCellSaveStatus';

export const WorkspaceColumnCellText = ({
  value: valueProp,
  workspaceId,
  workspaceColumn,
  canEdit,
  shouldShowBlankPlaceholder
}: WorkspaceColumnCellBlockProps) => {
  const [value, setValue] = useState(valueProp);
  const [updateValue, updateMeta] = useUpdateWorkspaceColumnValueMutation();
  const [inputValue, setInputValue] = useState(
    typeof value?.contentBlock.value === 'string' ? value?.contentBlock.value : ''
  );
  const initialValueRef = useRef(inputValue);
  const isDirty = inputValue !== initialValueRef.current;
  const [shouldShowStatus, setShouldShowStatus] = useState(false);
  const workspaceColumnId = workspaceColumn.id;

  const save = async () => {
    if (isDirty) {
      setShouldShowStatus(true);

      updateValue({
        id: value?.id,
        blockType: 'text_block',
        value: inputValue,
        workspaceId,
        workspaceColumnId
      }).then(res => {
        setValue(res.data as typeof value);
        initialValueRef.current = inputValue;
        setTimeout(() => setShouldShowStatus(false), 2000);
      });
    }
  };

  if (canEdit) {
    return (
      <FormGroup className="relative mb-0">
        <InputGroup
          type="text"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          placeholder={`Enter ${workspaceColumn.contentBlock.name}`}
          onBlur={save}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.currentTarget.blur();
            }
          }}
        />
        {shouldShowStatus && <WorkspaceColumnCellSaveStatus updateMeta={updateMeta} />}
      </FormGroup>
    );
  }
  return <span>{inputValue || (shouldShowBlankPlaceholder ? '(blank)' : '')}</span>;
};
