import React, { useRef } from 'react';

import { OppWorkspaceCommentForm } from './OppWorkspaceCommentForm';

import { Comment } from '@/app/molecules/Comment/Comment';
import { useCurrentUserAttribute } from '@/api/currentUserApi';
import { deleteToast } from '@/app/lib/toaster';
import {
  useUpdateOppWorkspaceCommentMutation,
  useDeleteOppWorkspaceCommentMutation
} from '@/api/oppWorkspaceCommentsApi';
import { CommentIndex } from '@/types/__generated__/GovlyApi';
import { ErrorBoundary } from '@/app/atoms/ErrorBoundary/ErrorBoundary';
import { CardError } from '@/app/atoms/ErrorFallback/CardError';
import { AttachmentEntityTitle } from '@/app/organisms/OppWorkspaceAttachmentsCard/AttachmentEntityTitle';
import { AttachmentEntityTitleUtils } from '@/app/organisms/OppWorkspaceAttachmentsCard/utils';
import { useGetCommentQuery } from '@/api/commentsApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { useIntersectionObserver } from '@/app/hooks/useIntersectionObserver';
import { WorkspaceAttachmentToolsButton } from '@/app/organisms/WorkspaceAttachmentToolsButton/WorkspaceAttachmentToolsButton';
import { useGetWorkspaceAttachmentsQuery } from '@/api/workspacesAttachmentsApi';

export const OppWorkspaceComment = ({ comment, oppId }: { comment: CommentIndex; oppId: string }) => {
  const currentUserId = useCurrentUserAttribute('id');
  const ref = useRef<HTMLDivElement>(null);
  const { hasIntersected } = useIntersectionObserver(ref);
  const { data: commentShow, isLoading } = useGetCommentQuery({ id: comment.id }, { skip: !hasIntersected });

  const { attachments } = useGetWorkspaceAttachmentsQuery(
    { id: comment.commentableId },
    {
      selectFromResult: ({ data }) => ({
        attachments: data?.filter(a => a.commentId === comment.id) ?? []
      })
    }
  );
  const [updateOppWorkspaceComment, { isLoading: isUpdating }] = useUpdateOppWorkspaceCommentMutation();
  const [deleteOppWorkspaceComment] = useDeleteOppWorkspaceCommentMutation();

  return (
    <div ref={ref}>
      <ErrorBoundary
        action="OppWorkspaceComment"
        fallback={<CardError title="Opp Workspace Comment" entity="comment" />}
      >
        {isLoading || !commentShow ? (
          <Loading type="condensed-card" />
        ) : (
          <Comment
            key={commentShow.id}
            comment={commentShow}
            isAuthor={commentShow.organizationUserId === currentUserId}
            onRemove={() => {
              deleteOppWorkspaceComment({ id: comment.id });
              deleteToast('Message deleted.');
            }}
            editor={
              <OppWorkspaceCommentForm
                onSubmit={updateOppWorkspaceComment}
                comment={commentShow}
                oppId={oppId}
                workspaceId={comment.commentableId}
                isLoading={isUpdating}
              />
            }
          >
            {attachments.length > 0 ? (
              <div className="flex flex-wrap gap-2">
                {attachments.map(attachment => (
                  <AttachmentEntityTitle
                    key={attachment.id}
                    {...AttachmentEntityTitleUtils.fromWorkspaceAttachment(attachment)}
                    actionsButtonGroup={<WorkspaceAttachmentToolsButton attachment={attachment} />}
                  />
                ))}
              </div>
            ) : null}
          </Comment>
        )}
      </ErrorBoundary>
    </div>
  );
};
