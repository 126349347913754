import React from 'react';

import { Notification, NotificationIndicator } from '@/app/atoms/NotificationIndicator/NotificationIndicator';
import { NavItem } from '@/app/organisms/AuthenticatedNav/AuthenticatedNavItemsUtils';
import { useAuthenticatedNavContext } from '@/app/organisms/AuthenticatedNav/useAuthenticatedNavContext';

export type WorklistNotificationsTabTitleProps = {
  node: NavItem;
  index: number;
};

export const WorklistNotificationsTabTitle = ({ node: node }: WorklistNotificationsTabTitleProps) => {
  const { currentUser } = useAuthenticatedNavContext();
  const notificationIntent = currentUser.hasUnreadNotifications ? 'success' : undefined;

  return notificationIntent ? (
    <Notification>
      <span className="pr-2">{node.label}</span>
      <NotificationIndicator intent={notificationIntent} size="sm" position="top" className="right-[2px]" />
    </Notification>
  ) : (
    node.label
  );
};
