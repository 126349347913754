import React from 'react';
import { match } from 'ts-pattern';
import { Icon, Tooltip } from '@blueprintjs/core';

type SaveStatusProps = {
  updateMeta: { isLoading: boolean; isError: boolean };
};

export const WorkspaceColumnCellSaveStatus = ({ updateMeta }: SaveStatusProps) => {
  return (
    <span className="absolute top-1/2 -translate-y-1/2 left-full translate-x-1 flex">
      <Tooltip
        className="flex"
        content={match(updateMeta)
          .with({ isLoading: true }, () => 'Saving...')
          .with({ isError: true }, () => 'Error saving')
          .otherwise(() => 'Saved')}
      >
        <Icon
          size={12}
          {...match(updateMeta)
            .with({ isLoading: true }, () => ({
              'aria-label': 'Saving',
              icon: 'refresh' as const,
              className: 'animate-spin text-gray-500'
            }))
            .with({ isError: true }, () => ({
              icon: 'warning-sign' as const,
              className: 'text-red-500',
              'aria-label': 'Error saving'
            }))
            .otherwise(() => ({ icon: 'tick' as const, className: 'text-green-500', 'aria-label': 'Saved' }))}
        />
      </Tooltip>
    </span>
  );
};
