import React, { useMemo } from 'react';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import 'highcharts/es-modules/masters/modules/drilldown.src';
import { HighchartsReact } from 'highcharts-react-official';

import { Card, CardBody } from '@/app/atoms/Card/Card';
import { useGetGovernmentEntityQuery } from '@/api/governmentEntitiesApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import { LlmGeneratedIndicator } from '@/app/molecules/LlmGeneratedIndicator/LlmGeneratedIndicator';
import { GovernmentEntityUploadShow } from '@/types/__generated__/GovlyApi';

const extractSeriesData = (categoryRequests: GovernmentEntityUploadShow['categoryRequests']) => {
  const series: Highcharts.SeriesBarOptions[] = [
    {
      type: 'bar',
      id: 'Categories',
      name: 'Budget Requested',
      tooltip: {
        valuePrefix: '$'
      },
      data: categoryRequests
        .map(categoryRequests => ({
          name: categoryRequests.category,
          y: categoryRequests.initiatives.reduce((acc, request) => acc + (request.amount || 0), 0),
          drilldown: categoryRequests.category
        }))
        .filter(({ y }) => y !== 0)
        .sort((a, b) => b.y - a.y)
    }
  ];

  const drilldown: Highcharts.SeriesOptionsType[] = categoryRequests.map(categoryRequests => {
    return {
      type: 'bar',
      id: categoryRequests.category,
      name: 'Budget Requested',
      data: categoryRequests.initiatives
        .map(request => ({ name: request.name, y: request.amount, text: request.summary }))
        .filter(({ y }) => y && y !== 0)
        .sort((a, b) => (b.y || 0) - (a.y || 0))
    };
  });

  return { series, drilldown };
};

export const CategoryRequestsChart = ({ id }: { id: string }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  const options: Highcharts.Options = useMemo(() => {
    if (!governmentEntity?.latestUpload?.categoryRequests) return {};

    const { series, drilldown } = extractSeriesData(governmentEntity.latestUpload.categoryRequests);

    if (!series[0].data?.length) return {};

    return {
      plotOptions: {
        bar: {
          colorByPoint: true
        },
        column: {
          colorByPoint: true
        }
      },
      title: {
        text: undefined
      },
      chart: {
        type: 'bar',
        height: 350,
        zooming: {
          type: 'xy'
        }
      },
      legend: {
        enabled: false
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: undefined
        }
      },
      tooltip: {
        valuePrefix: '$',
        useHTML: true,
        formatter: function (tooltip) {
          const point = this.point as unknown as { text?: string };
          const defaultTooltip = tooltip.defaultFormatter.call(this, tooltip);

          if (!point.text) {
            return defaultTooltip;
          }

          const defaultTooltipArray = Array.isArray(defaultTooltip) ? defaultTooltip : [defaultTooltip];
          return defaultTooltipArray.concat([
            '<br />',
            '<span style="font-size:0.8em">Description:</span>',
            '<br />',
            `<div style="width:300px; white-space:normal; text-wrap:wrap">${point.text}</div>`
          ]);
        }
      },
      series,
      drilldown: {
        series: drilldown,
        breadcrumbs: {
          formatter: function (e) {
            const breadcrumb = e as unknown as Highcharts.BreadcrumbOptions;
            return breadcrumb.level === 0 ? '← Back' : String(breadcrumb.levelOptions.name);
          }
        }
      }
    };
  }, [governmentEntity?.latestUpload?.categoryRequests]);

  if (isLoading) return <Loading type="card" />;
  if (!governmentEntity || !governmentEntity?.latestUpload?.categoryRequests?.length || !Object.keys(options).length)
    return null;

  const fy = governmentEntity.latestUpload.fiscalYear;

  return (
    <Card
      title={
        <span className="space-x-2">
          <LlmGeneratedIndicator icon="generate" /> Projected Requests by Category{fy ? ` (FY${fy})` : ''}
        </span>
      }
      titleRenderer={CardHeadingSmall}
      collapsible={true}
    >
      <CardBody>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </CardBody>
    </Card>
  );
};
