import React from 'react';
import { useSearchParams } from 'react-router';
import { Icon, Tag, Tooltip } from '@blueprintjs/core';

import { OppWorkspaceWorkflowStageTag } from '@/app/organisms/OppWorkspaceWorkflowStage/OppWorkspaceWorkflowStage';
import { OppWorkspaceName } from '@/app/organisms/OppWorkspaceName/OppWorkspaceName';
import { LabelsDescriptionListField } from '@/app/organisms/LabelsDescriptionListField/LabelsDescriptionListField';
import { DescriptionListField } from '@/app/molecules/DescriptionListField/DescriptionListField';
import { formatTime, DATETIME_24_SHORT } from '@/app/lib/dates';
import { DescriptionListLabel } from '@/app/molecules/DescriptionList/DescriptionList';
import { WorkspaceFollowers } from '@/app/organisms/WorkspaceFollowers/WorkspaceFollowers';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { DRAWER_PARAMS } from '@/app/organisms/AppDrawer/constants';
import { CardList, CardListItem, CardListProps } from '@/app/molecules/CardList/CardList';
import { OppWorkspaceAttachmentsCard } from '@/app/organisms/OppWorkspaceAttachmentsCard/OppWorkspaceAttachmentsCard';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import { WorkspaceShow } from '@/types/__generated__/GovlyApi';
import { OppWorkspaceArtifactsCardListItem } from '@/app/organisms/OppWorkspaceArtifactsCardListItem/OppWorkspaceArtifactsCardListItem';
import { OppDetailsAttributesCard } from '@/app/organisms/OppDetailsAttributesCard/OppDetailsAttributesCard';
import { OppWorkspaceColumnValuesCardListItem } from '@/app/organisms/OppWorkspaceColumnValuesCardListItem/OppWorkspaceColumnValuesCardListItem';

export const OppWorkspaceCard = ({
  workspace,
  cardListProps
}: {
  workspace: WorkspaceShow;
  cardListProps?: Partial<CardListProps>;
}) => {
  const [searchParams] = useSearchParams();
  const drawerType = searchParams.get(DRAWER_PARAMS.type);

  const { createdAt, createdByEmail, createdByOrganization, privateAccess, follows, organizationLabels } = workspace;

  const participatingOrganizations = [
    ...new Set(
      follows?.map(({ organizationUser }) => organizationUser?.organizationName).filter(name => name !== undefined)
    )
  ].sort(n => (n === createdByOrganization ? -1 : 1));

  return (
    <CardList {...cardListProps}>
      <CardListItem title="Workspace Details" collapsible defaultIsOpen titleRenderer={CardHeadingSmall}>
        <dl className="grid grid-cols-2 gap-y-4">
          <div className="flex flex-col gap-y-1 col-span-2">
            <DescriptionListLabel className="mb-2">Workspace Name</DescriptionListLabel>
            <div>
              <OppWorkspaceName oppId={workspace.workableId} workspace={workspace} />
            </div>
          </div>
          <div className="col-span-2 flex flex-col gap-y-1">
            <DescriptionListLabel className="mb-2">Participants</DescriptionListLabel>
            <div>
              <WorkspaceFollowers
                formTitle={workspace.organizationDefault ? 'Default Workspace' : workspace.name}
                type="Opp"
                workspace={workspace}
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-1 col-span-2">
            <DescriptionListLabel className="mb-2">Stage</DescriptionListLabel>
            <div>
              <OppWorkspaceWorkflowStageTag workspaceId={workspace.id} />
            </div>
          </div>
          <LabelsDescriptionListField
            taggableId={workspace.id}
            taggableType="Workspace"
            labels={organizationLabels}
            cacheTags={[{ type: 'Opp', id: workspace.workableId }]}
          />
          {drawerType && (
            <DescriptionListField
              label="Actions"
              value={
                <Tooltip content="Click to view workspace in new tab">
                  <LinkTag to={`/opportunities/${workspace.workableId}/workspaces/${workspace.id}`} target="_blank">
                    <Tag interactive minimal>
                      Open in new tab
                    </Tag>
                  </LinkTag>
                </Tooltip>
              }
              cols={2}
            />
          )}
          <DescriptionListField label="Created At" value={formatTime(createdAt, DATETIME_24_SHORT)} cols={2} />
          <DescriptionListField label="Created By" value={createdByEmail} cols={2} />
          <DescriptionListField
            label="Workspace Access"
            value={
              <Tooltip content={privateAccess ? 'Invited team members may join' : 'All team members may join'}>
                <Tag
                  minimal
                  intent={privateAccess ? 'danger' : 'success'}
                  icon={<Icon iconSize={12} icon={privateAccess ? 'lock' : 'unlock'} />}
                >
                  {privateAccess ? 'Private' : 'Open'}
                </Tag>
              </Tooltip>
            }
            cols={2}
          />
          <DescriptionListField label="Created By Organization" value={createdByOrganization} cols={1} />
          <div className="col-span-2 flex flex-col gap-y-1">
            <DescriptionListField
              label="Participating Organizations"
              value={
                <div className="space-x-2">
                  {participatingOrganizations.map(organizationName => {
                    if (participatingOrganizations.length > 1 && organizationName === createdByOrganization) {
                      return (
                        <Tag key={organizationName} minimal intent="warning">
                          {`Created by ${organizationName}`}
                        </Tag>
                      );
                    }

                    return (
                      <Tag key={organizationName} minimal>
                        {organizationName}
                      </Tag>
                    );
                  })}
                </div>
              }
              cols={1}
            />
          </div>
        </dl>
      </CardListItem>

      <OppDetailsAttributesCard id={workspace.workableId} title="Opportunity Details" defaultIsOpen={false} />

      <OppWorkspaceAttachmentsCard
        workspaceId={workspace.id}
        cardListItemProps={{
          titleRenderer: CardHeadingSmall,
          collapsible: true,
          defaultIsOpen: false
        }}
      />

      <OppWorkspaceArtifactsCardListItem
        workspaceId={workspace.id}
        cardListItemProps={{
          title: 'Artifacts',
          titleRenderer: CardHeadingSmall,
          collapsible: true,
          defaultIsOpen: false
        }}
      />

      <OppWorkspaceColumnValuesCardListItem
        workspaceId={workspace.id}
        cardListItemProps={{
          title: 'Custom Columns',
          titleRenderer: CardHeadingSmall,
          collapsible: true,
          defaultIsOpen: false
        }}
      />
    </CardList>
  );
};
