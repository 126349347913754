import { rootApi } from './rootApi';

import { DeactivatedFeedShow } from '@/types/__generated__/GovlyApi';

const api = rootApi.enhanceEndpoints({
  addTagTypes: ['DeactivatedFeed']
});

type GetDeactivatedFeeds = {
  params: void;
  result: DeactivatedFeedShow[];
};

type CreateDeactivatedFeed = {
  params: { feedId: string } | { partnerFeedId: string };
  result: DeactivatedFeedShow;
};

type ToggleDeactivatedFeeds = {
  params: { feedIds: string[]; active: boolean } | { partnerFeedIds: string[]; active: boolean };
  result: void;
};

type DeleteDeactivatedFeed = {
  params: { id: string };
  result: void;
};

export const deactivatedFeedsApi = api.injectEndpoints({
  endpoints: build => ({
    getDeactivatedFeeds: build.query<GetDeactivatedFeeds['result'], GetDeactivatedFeeds['params']>({
      query: () => ({ url: '/v2/deactivated_feeds' }),
      providesTags: ['DeactivatedFeed']
    }),

    createDeactivatedFeed: build.mutation<CreateDeactivatedFeed['result'], CreateDeactivatedFeed['params']>({
      query: body => ({
        url: `/v2/deactivated_feeds`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['DeactivatedFeed']
    }),

    toggleDeactivatedFeeds: build.mutation<ToggleDeactivatedFeeds['result'], ToggleDeactivatedFeeds['params']>({
      query: body => ({
        url: '/v2/deactivated_feeds/toggle',
        method: 'POST',
        body
      }),
      invalidatesTags: ['DeactivatedFeed']
    }),

    deleteDeactivatedFeed: build.mutation<DeleteDeactivatedFeed['result'], DeleteDeactivatedFeed['params']>({
      query: ({ id }) => ({
        url: `/v2/deactivated_feeds/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['DeactivatedFeed']
    })
  })
});

export const {
  useGetDeactivatedFeedsQuery,
  useCreateDeactivatedFeedMutation,
  useToggleDeactivatedFeedsMutation,
  useDeleteDeactivatedFeedMutation
} = deactivatedFeedsApi;
