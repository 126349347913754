import React from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useLocation } from 'react-router';

import { useGetCurrentUserQuery } from '@/api/currentUserApi';
import { useGetCatalogQuery } from '@/api/souptoolsCatalogsApi';
import { OrganizationUserCurrentUser } from '@/types/__generated__/GovlyApi';

export type AuthenticatedNavContext = {
  currentUser: OrganizationUserCurrentUser;
  hasSouptoolsCatalog: boolean;
  pathname: string;
};

export const useAuthenticatedNavContextQuery = (): { isLoading: boolean; data?: AuthenticatedNavContext } => {
  const { pathname } = useLocation();
  const currentUserQuery = useGetCurrentUserQuery();
  const souptoolsCatalogQuery = useGetCatalogQuery(undefined, { skip: !pathname.includes('souptools') });
  const blockingQueries = [currentUserQuery];
  const isLoading = blockingQueries.some(query => query.isLoading);

  return useMemo(() => {
    return {
      isLoading,
      data:
        isLoading || !currentUserQuery.data
          ? undefined
          : {
              currentUser: currentUserQuery.data as OrganizationUserCurrentUser,
              hasSouptoolsCatalog: Boolean(souptoolsCatalogQuery.data),
              pathname
            }
    };
  }, [currentUserQuery.data, isLoading, pathname, souptoolsCatalogQuery.data]);
};

const AuthenticatedNavContext = createContext<AuthenticatedNavContext | undefined>(undefined);

export const useAuthenticatedNavContext = () => {
  const context = useContext(AuthenticatedNavContext);
  if (!context) {
    throw new Error('useAuthenticatedNavContext must be used within an AuthenticatedNavContextProvider');
  }
  return context;
};

export const AuthenticatedNavContextProvider = ({
  authenticatedNavContext,
  children
}: React.PropsWithChildren<{ authenticatedNavContext: AuthenticatedNavContext }>) => {
  return (
    <AuthenticatedNavContext.Provider value={authenticatedNavContext}>{children}</AuthenticatedNavContext.Provider>
  );
};
