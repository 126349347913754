import React, { useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as yup from 'yup';
import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';

import { isEmail } from '@/app/lib/isEmail';
import { useFuzzySearch } from '@/app/hooks/useFuzzySearch';
import { H3 } from '@/app/atoms/Typography/Typography';
import { Card, CardBody, CardFooter } from '@/app/atoms/Card/Card';
import { TextInput } from '@/app/atoms/inputs/TextInput/TextInput';
import { TextAreaInput } from '@/app/atoms/inputs/TextAreaInput/TextAreaInput';
import { formErrorToast, successToast } from '@/app/lib/toaster';
import { useGetOutreachRecipientsQuery } from '@/api/outreachRecipientsApi';
import { ShareViaEmailFormInvites } from '@/app/organisms/ShareViaEmailFormInvites/ShareViaEmailFormInvites';
import { Editor } from '@/app/molecules/Editor/Editor';
import { OutreachRecipientShow } from '@/types/__generated__/GovlyApi';
import { isFetchBaseQueryError } from '@/api/utils';
import { useFeatureFlag } from '@/app/hooks/useFeatureFlag';
import { flattenRecipientsToEmails, Recipient, recipientsSchema } from '@/app/organisms/RecipientSelect/utils';
import { RecipientSelect } from '@/app/organisms/RecipientSelect/RecipientSelect';

type MinimalOutreachRecipient = Pick<OutreachRecipientShow, 'recipientEmail' | 'recipientName' | 'recipientCompany'>;

type LegacyRecipient = { email: string; name?: string; company?: string };

type FormValues = {
  id: string;
  subject: string;
  customMessage?: string;
  recipients: Recipient[];
  legacyRecipients: LegacyRecipient[];
};

type ShareViaEmailFormProps = {
  onCancel: () => void;
  isSubmitting: boolean;
  title: string;
  initialValues: Partial<FormValues> & { id: string };
  onSubmit: (
    values: Omit<FormValues, 'recipients' | 'legacyRecipients'> & { recipients: LegacyRecipient[] }
  ) => Promise<OutreachRecipientShow[] | undefined>;
};

export const ShareViaEmailForm = ({
  onCancel,
  isSubmitting,
  title,
  initialValues,
  onSubmit,
  ...rest
}: ShareViaEmailFormProps) => {
  const newFormEnabled = useFeatureFlag('outreach-form');

  const { data: preferredRecipients = [], isLoading: preferredRecipientsLoading } = useGetOutreachRecipientsQuery({});
  const [unselectedPreferredRecipients, setUnselectedPreferredRecipients] = useState<MinimalOutreachRecipient[]>([]);
  const [preferredRecipientsQuery, setPreferredRecipientsQuery] = useState('');
  const [inviteables, setInviteables] = useState<OutreachRecipientShow[]>([]);

  useEffect(() => {
    setUnselectedPreferredRecipients(preferredRecipients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredRecipientsLoading]);

  const preferredRecipientsList = useFuzzySearch({
    options: { keys: ['recipientEmail', 'recipientName', 'recipientCompany'] },
    data: unselectedPreferredRecipients,
    query: preferredRecipientsQuery
  });

  const form = {
    onSubmit: async ({ recipients: newRecipients, legacyRecipients, ...values }: FormValues) => {
      try {
        const recipients = newFormEnabled ? flattenRecipientsToEmails(newRecipients) : legacyRecipients;

        const data = await onSubmit({ ...values, recipients });
        successToast('Email sent');
        const possibleInviteables = data?.filter(r => r.shouldInvite || r.shouldInviteToPartner) || [];

        if (!possibleInviteables.length) {
          onCancel();
        } else {
          setInviteables(possibleInviteables);
        }
      } catch (e) {
        if (isFetchBaseQueryError(e) && 'number' == typeof e.status) {
          formErrorToast(e.status);
        } else {
          formErrorToast(500);
        }
      }
    },
    initialValues: {
      subject: '',
      customMessage: '',
      recipients: [],
      legacyRecipients: [{ name: '', email: '', company: '' }],
      ...(initialValues || {})
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: yup.object({
      subject: yup.string().required('Subject is required.'),
      legacyRecipients: yup
        .array()
        .of(
          yup.object().shape({
            email: yup
              .string()
              .required('Email is required.')
              .test(
                'is-email',
                ({ value }) => `Invalid email address: ${value}.`,
                value => isEmail(value)
              )
          })
        )
        .min(1),
      ...(newFormEnabled ? { recipients: recipientsSchema } : {})
    })
  };

  if (inviteables.length) return <ShareViaEmailFormInvites inviteables={inviteables} onCancel={onCancel} />;

  return (
    <div data-test="share-via-email-form" {...rest}>
      <Formik {...form}>
        {({ setFieldValue, values }) => (
          <Form>
            <Card title={title} rightElement={<Button minimal icon="cross" onClick={onCancel} />}>
              <CardBody className="space-y-4">
                <H3>1. Who do you want to share this with?</H3>
                {newFormEnabled ? (
                  <RecipientSelect context="share" className="max-h-[320px]" />
                ) : (
                  <FieldArray
                    name="legacyRecipients"
                    render={arrayHelpers => (
                      <>
                        {values.legacyRecipients.map((recipient, index) => (
                          <div className="relative flex flex-col space-y-2 rounded border p-4" key={index}>
                            {values.legacyRecipients.length > 1 && (
                              <Button
                                small
                                minimal
                                intent="danger"
                                icon="cross"
                                className="absolute top-0 right-0 mr-2 mt-2"
                                onClick={() => {
                                  if (recipient.email) {
                                    setUnselectedPreferredRecipients(
                                      unselectedPreferredRecipients.concat({
                                        recipientEmail: recipient.email,
                                        recipientName: recipient.name,
                                        recipientCompany: recipient.company
                                      })
                                    );
                                  }

                                  arrayHelpers.remove(index);
                                }}
                              />
                            )}
                            <TextInput
                              inputProps={{ large: false }}
                              name={`legacyRecipients.${index}.email`}
                              label="Email"
                            />
                            <TextInput
                              inputProps={{ large: false }}
                              name={`legacyRecipients.${index}.name`}
                              label="Name"
                              labelInfo="(optional)"
                            />
                            <TextInput
                              inputProps={{ large: false }}
                              name={`legacyRecipients.${index}.company`}
                              label="Company"
                              labelInfo="(optional)"
                            />
                          </div>
                        ))}
                        <div className="flex gap-x-2">
                          <Button
                            text="Add another person"
                            onClick={() => arrayHelpers.push({ name: '', email: '', company: '' })}
                          />
                          {unselectedPreferredRecipients.length > 0 && (
                            <>
                              <span className="py-2">or</span>
                              <Select
                                items={preferredRecipientsList}
                                disabled={preferredRecipientsLoading}
                                resetOnSelect
                                onItemSelect={recipient => {
                                  const swapIndex = values.legacyRecipients.findIndex(
                                    r => Object.values(r).join('').length === 0
                                  );

                                  const replacement = {
                                    email: recipient.recipientEmail,
                                    name: recipient.recipientName || '',
                                    company: recipient.recipientCompany || ''
                                  };
                                  if (swapIndex !== -1) {
                                    arrayHelpers.replace(swapIndex, replacement);
                                  } else {
                                    arrayHelpers.push(replacement);
                                  }

                                  setPreferredRecipientsQuery('');

                                  setUnselectedPreferredRecipients(
                                    unselectedPreferredRecipients.filter(
                                      r => r.recipientEmail !== recipient.recipientEmail
                                    )
                                  );
                                }}
                                onQueryChange={q => setPreferredRecipientsQuery(q)}
                                itemRenderer={(item, { handleClick, modifiers }) => (
                                  <MenuItem
                                    key={item.recipientEmail}
                                    text={item.recipientEmail}
                                    active={modifiers.active}
                                    onClick={handleClick}
                                  />
                                )}
                              >
                                <Button rightIcon="chevron-down" text="Select from history" className="py-2" />
                              </Select>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  />
                )}
              </CardBody>
              <CardBody className="space-y-4">
                <H3>2. Add a personal note to the email</H3>
                <TextAreaInput name="subject" label="Subject" />
                <Editor
                  id="customMessage"
                  onChange={({ target: { value: newValue } }) => setFieldValue('customMessage', newValue)}
                  inputProps={{ value: values.customMessage }}
                  formGroupProps={{
                    label: 'Custom Message',
                    labelInfo: '(optional)'
                  }}
                />
              </CardBody>
              <CardFooter>
                <Button large type="submit" text="Send" intent="primary" loading={isSubmitting} />
                {onCancel && <Button text="Cancel" disabled={isSubmitting} onClick={onCancel} />}
              </CardFooter>
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
};
