import { get } from 'lodash-es';

import { JSON } from '@/types/__generated__/GovlyApi';

type Agg = {
  [key: string]: {
    buckets: AggBuckets;
    [key: string]: AggBuckets | JSON;
  };
};

type AggBuckets = {
  key: string | string[];
  keyAsString?: string;
  docCount: number;
}[];

export type ExtractAggregationsData =
  | ({
      aggs?: Agg;
    } & {
      [key: string]: Agg | JSON;
    })
  | { [key: string]: string | number | string[] }[];

export type AggregateItem = { label: string; value: string; aggregation: number };

export const extractAggregations = <T extends ExtractAggregationsData>(data: T, agg: string) => {
  if (!data) return [];

  if (!('aggs' in data)) return Array.isArray(data) ? data : [];

  const buckets = get(data, `aggs.${agg}.buckets`, []) as AggBuckets;

  const items: AggregateItem[] = [];
  buckets.forEach(bucket => {
    let label = Array.isArray(bucket.key) ? bucket.key?.[1] : bucket.key;
    const value = Array.isArray(bucket.key) ? bucket.key?.[0] : bucket.key;
    if (agg === 'naicsCodesAgg') label = `${value} - ${label}`;

    if (label !== 'Unknown') {
      items.push({
        label,
        value,
        aggregation: bucket.docCount
      });
    }
  });
  return items;
};
