import React from 'react';
import { Button, Icon, MenuItem, MenuItemProps } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { ArrayHelpers } from 'formik';

import { useGetOrganizationUsersQuery } from '@/api/organizationUsersApi';
import { OrganizationUserFollowerSelectInput } from '@/types/__generated__/GovlyApi';
import {
  orgUserToOrgUserRecipient,
  type Recipient,
  type SimpleRecipient,
  useRecipientSelect
} from '@/app/organisms/RecipientSelect/utils';

export const RecipientSelectTeamSelect = ({ helpers }: { helpers: ArrayHelpers<Recipient[]> }) => {
  const organizationUsersQuery = useGetOrganizationUsersQuery({ view: 'follower_select_input' });
  const orgUsers = (organizationUsersQuery.data ?? []) as OrganizationUserFollowerSelectInput[];
  const { values } = useRecipientSelect();
  const teamEmails = new Set(
    values.recipients.filter(x => x.type === 'organizationUser').map(recipient => recipient.email)
  );
  const remainingOrgUsers = orgUsers.filter(user => !teamEmails.has(user.email)).map(orgUserToOrgUserRecipient);

  return (
    <Select
      popoverProps={{ usePortal: false }}
      items={remainingOrgUsers}
      itemRenderer={itemRenderer}
      onItemSelect={item => helpers.push(item)}
    >
      <Button
        loading={organizationUsersQuery.isLoading}
        small
        minimal
        icon={<Icon icon="person" className="text-purple-500" />}
        text="Team"
        rightIcon="chevron-down"
      />
    </Select>
  );
};

const itemRenderer: ItemRenderer<SimpleRecipient> = (item, { handleClick }) => {
  return <Item key={item.email} item={item} onClick={handleClick} />;
};

const Item = ({ item, ...props }: Partial<MenuItemProps> & { item: SimpleRecipient }) => {
  const { values } = useRecipientSelect();
  const selected = values.recipients.some(
    recipient => recipient.type === 'organizationUser' && recipient.email === item.email
  );

  return <MenuItem key={item.email} text={item.email} onClick={props.onClick} selected={selected} />;
};
