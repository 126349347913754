import React, { createContext, useContext } from 'react';

export type RecipientSelectContextType = {
  context: 'follow' | 'share';
};

const RecipientSelectContext = createContext<{ context: 'follow' | 'share' } | undefined>(undefined);

export const useRecipientSelectContext = () => {
  const value = useContext(RecipientSelectContext);

  if (!value) {
    throw new Error('useRecipientSelectContext must be used within a RecipientSelectContext provider');
  }

  return value;
};

export const RecipientSelectProvider = ({ children, context }: React.PropsWithChildren<RecipientSelectContextType>) => {
  return <RecipientSelectContext.Provider value={{ context }}>{children}</RecipientSelectContext.Provider>;
};
