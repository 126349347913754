import React, { useState } from 'react';
import { Button, Menu, MenuItem, Popover, Spinner, Tooltip } from '@blueprintjs/core';

import { WorkspaceAttachmentShow } from '@/types/__generated__/GovlyApi';
import { useOppWorkspaceStoreRef } from '@/app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';
import { useAppLayoutContext } from '@/app/organisms/AppLayout/AppLayoutContext';
import { AcceptedFileTypes } from '@/app/organisms/OppWorkspaceQuoteForm/quoteFileTypes';
import { useConvertWorkspaceAttachmentToQuote } from '@/app/organisms/WorkspaceAttachmentToolsButton/useConvertWorkspaceAttachmentToQuote';

export type WorkspaceAttachmentToolsButtonProps = {
  attachment: WorkspaceAttachmentShow;
};

const ACCEPTED_EXTENSIONS = Object.values(AcceptedFileTypes).flat();

export const WorkspaceAttachmentToolsButton = ({ attachment }: WorkspaceAttachmentToolsButtonProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const store = useOppWorkspaceStoreRef();
  const [convertToQuote, createQuoteMeta] = useConvertWorkspaceAttachmentToQuote();
  const { appBodyRef } = useAppLayoutContext();

  const isCompatible = ACCEPTED_EXTENSIONS.some(ext => attachment.attachment.name.endsWith(ext));

  const handleConvertToQuote = () => {
    convertToQuote(attachment.attachment.signedId).then(() => {
      setIsPopoverOpen(false);
      // Scroll to top of the page
      const scrollContainer = document.querySelector('.bp5-drawer') ?? appBodyRef.current;
      scrollContainer?.scrollTo({ top: 0, behavior: 'smooth' });
    });
  };

  return (
    <div>
      <Popover
        placement="right"
        isOpen={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        content={
          <Menu>
            <Tooltip
              content={isCompatible ? undefined : 'Unsupported file type'}
              className="w-full"
              disabled={isCompatible}
            >
              <MenuItem
                icon={createQuoteMeta.isLoading ? <Spinner size={12} /> : 'dollar'}
                disabled={createQuoteMeta.isLoading || !isCompatible}
                text="Convert to quote"
                onClick={handleConvertToQuote}
                shouldDismissPopover={false}
              />
            </Tooltip>
            <MenuItem
              icon="eye-open"
              text="View attachment"
              onClick={() =>
                store.setState({ fileToView: { name: attachment.attachment.name, link: attachment.attachment.link } })
              }
            />
          </Menu>
        }
      >
        <Button icon="generate" small onClick={() => setIsPopoverOpen(true)} aria-label="Workspace attachment tools" />
      </Popover>
    </div>
  );
};
