import React, { useMemo } from 'react';
import { Icon, Tag } from '@blueprintjs/core';
import { createColumnHelper } from '@tanstack/react-table';
import { EntityTitle } from '@blueprintjs/core';

import { GovlyTable } from '@/app/molecules/GovlyTable/GovlyTable';
import { GovlyTableTitle } from '@/app/molecules/GovlyTable/GovlyTableTitle';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import {
  useOppDetailsProviders,
  ProviderRow,
  OppDetailsProps
} from '@/app/pages/OppDetailsProvidersPage/useOppDetailsProvidersPage';
import { ImageWithFallback } from '@/app/atoms/ImageWithFallback/ImageWithFallback';
import { formatTime } from '@/app/lib/dates';

const columnHelper = createColumnHelper<ProviderRow>();

export const OppDetailsProvidersTable = ({ id }: OppDetailsProps) => {
  const { providerPartnerships, isLoading } = useOppDetailsProviders({ id });

  const columns = useMemo(
    () => [
      columnHelper.accessor('organization.name', {
        id: 'name',
        header: 'Partner',
        sortingFn: 'basic',
        size: 240,
        cell: e => (
          <EntityTitle
            data-test="opp-details-display-name"
            title={e.row.original.organization?.name || ''}
            titleURL={`/partnerships/${e.row.original.peerId}`}
            icon={
              <ImageWithFallback
                src={`https://logo.clearbit.com/${e.row.original?.organization?.profile?.website}`}
                className="h-8 w-8 object-contain shrink-0 rounded-sm"
                loading="lazy"
              >
                <Icon icon="office" />
              </ImageWithFallback>
            }
          />
        )
      }),
      columnHelper.accessor('organization.profile.website', {
        header: 'Website',
        sortingFn: 'basic',
        size: 240,
        cell: e => {
          const website = e.row.original?.organization?.profile?.website;
          const url = website ? `https://${website?.replace(/^https?:\/\//i, '')}` : '';

          return <EntityTitle titleURL={url} title={website || ''} />;
        }
      }),
      columnHelper.accessor('organization.profile.setAsides', {
        header: 'Set Asides',
        sortingFn: 'basic',
        size: 240,
        cell: e => (
          <div className="flex gap-x-2">
            {e.row.original.organization?.profile?.setAsides?.map(setAside => (
              <Tag minimal key={setAside}>
                {setAside}
              </Tag>
            ))}
          </div>
        )
      }),
      columnHelper.accessor('createdAt', {
        header: 'Partnered Since',
        sortingFn: 'basic',
        size: 240,
        cell: e => <EntityTitle title={formatTime(e.row.original.createdAt)} />
      })
    ],
    []
  );

  return (
    <GovlyTable
      id="providers_table"
      columns={columns}
      data={providerPartnerships}
      title={<GovlyTableTitle title={<CardHeadingSmall>Providers</CardHeadingSmall>} />}
      isFixedLayout
      subtitle="Providers are partners in Govly who are sharing this data with you"
      enableFilters={providerPartnerships.length > 3}
      emptyStateProps={{ icon: 'office', title: 'No providers' }}
      isLoading={isLoading}
      initialState={{
        pagination: { pageSize: 5 }
      }}
    />
  );
};
