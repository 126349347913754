import React, { useMemo } from 'react';
import { NonIdealState } from '@blueprintjs/core';

import { OppHistoryCard } from '../OppHistoryCard/OppHistoryCard';

import { getFormattedOppSources } from './OppHistoryTimelineUtils';

import { useGetOppQuery } from '@/api/oppsApi';
import { Card, CardBody } from '@/app/atoms/Card/Card';
import { Timeline } from '@/app/molecules/Timeline/Timeline';
import { Loading } from '@/app/atoms/Loading/Loading';
import { SearchNoResultsMessage } from '@/app/molecules/SearchNoResultsMessage/SearchNoResultsMessage';

export type OppHistoryTimelineProps = {
  id: string;
  desc: boolean;
  search: string;
};

export const OppHistoryTimeline = ({ id, desc, search }: OppHistoryTimelineProps) => {
  const { data: opp, isLoading, isUninitialized } = useGetOppQuery({ id });

  const timelineItems = useMemo(() => {
    if (!opp) return [];
    const formattedOppSources = getFormattedOppSources(opp, desc, search);
    if (formattedOppSources.length === 0) {
      return null;
    }
    return formattedOppSources.map(oppSource => <OppHistoryCard key={oppSource.id} oppSource={oppSource} />);
  }, [opp, desc, search]);

  if (isLoading || isUninitialized) {
    return <Loading type="card-list" />;
  }

  if (!opp?.oppSources?.length) {
    return (
      <div className="pt-12 my-12">
        <Card>
          <CardBody>
            <NonIdealState
              icon="history"
              title="No history"
              description={<div>{`No history found for this opportunity.`}</div>}
            />
          </CardBody>
        </Card>
      </div>
    );
  }

  if (timelineItems === null) return <SearchNoResultsMessage />;

  return <Timeline events={timelineItems} />;
};
