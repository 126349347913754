import { useEffect } from 'react';

import { useGetCustomQuotesForWorkspaceQuery } from '@/api/customQuotesApi';
import { useOppWorkspaceStoreRef } from '@/app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';

export const useWorkspaceCustomQuotesWithPolling = ({ workspaceId }: { workspaceId: string }) => {
  const storeRef = useOppWorkspaceStoreRef();
  const shouldPoll = storeRef.useStore(state => state.shouldPollCustomQuotes);

  const query = useGetCustomQuotesForWorkspaceQuery({ workspaceId }, { pollingInterval: 5000, skip: !shouldPoll });
  const { data: customQuotes } = query;

  // turn off polling once all quotes are completed
  useEffect(() => {
    if (query.isFetching || !customQuotes) return;

    const noQuotes = customQuotes.length === 0;
    const notProcessing = customQuotes.every(quote => quote.status !== 'pending');

    if (noQuotes || notProcessing) storeRef.setState({ shouldPollCustomQuotes: false });
  }, [customQuotes, query.isFetching, storeRef]);

  return query;
};
