import React from 'react';
import { Formik, Form, FormikConfig } from 'formik';
import { Button } from '@blueprintjs/core';
import { partition } from 'lodash-es';
import { match } from 'ts-pattern';
import * as yup from 'yup';

import { H3 } from '@/app/atoms/Typography/Typography';
import { Card, CardBody, CardFooter } from '@/app/atoms/Card/Card';
import { useGetOrganizationUsersQuery } from '@/api/organizationUsersApi';
import { FollowerSelectInput } from '@/app/organisms/FollowerSelectInput/FollowerSelectInput';
import { useCurrentUserAttribute } from '@/api/currentUserApi';
import { WorkspaceFormNotificationsInput } from '@/app/organisms/WorkspaceFormNotificationsInput/WorkspaceFormNotificationsInput';
import { OrganizationUserDisplayable, OrganizationUserCurrentOrg, WorkspaceBase } from '@/types/__generated__/GovlyApi';
import { LimitedFollow } from '@/api/workspacesApi';
import { useSubmitWorkspaceForm } from '@/app/organisms/WorkableForm/useSubmitWorkspaceForm';
import { getFollowsAttributes, getNotifyIds } from '@/app/organisms/WorkableForm/utils';
import { useInitialRecipients } from '@/app/organisms/RecipientSelect/useInitialRecipients';
import { flattenRecipientsToFollows, Recipient, recipientsSchema } from '@/app/organisms/RecipientSelect/utils';
import { useFeatureFlag } from '@/app/hooks/useFeatureFlag';
import { RecipientSelect } from '@/app/organisms/RecipientSelect/RecipientSelect';
import { WorkspaceFormCustomMessage } from '@/app/organisms/WorkspaceForm/WorkspaceFormCustomMessage';

export type InitialValues = {
  id?: string;
  workableId?: string;
  organizationDefault: boolean;
} & Pick<WorkspaceBase, 'workableType'>;

type FormValues = InitialValues & {
  recipients: Recipient[];
  subject: string;
  customMessage: string;
  // Remove below once outreach-form is 100%
  teamFollows: LimitedFollow[];
  partnerFollows: LimitedFollow[];
  customNotifyList?: LimitedFollow[];
  notifyGroup: 'notifiables' | 'nobody' | 'select';
  // End remove
};

type WorkableFormProps = {
  title?: string;
  onCancel: () => void;
  follows: LimitedFollow[];
  initialValues: InitialValues;
  workableType: 'USASpendingAward' | 'Contact' | 'Opp';
  notifyNewFollowersOnly?: boolean;
  shouldTrack?: boolean;
};

export const WorkableForm = ({
  title,
  onCancel,
  follows = [],
  initialValues,
  workableType,
  notifyNewFollowersOnly = true,
  shouldTrack = true,
  ...rest
}: WorkableFormProps) => {
  const newFormEnabled = useFeatureFlag('outreach-form');

  const currentOrgId = useCurrentUserAttribute('organizationId');

  const { data: teamData, isLoading: teamLoading } = useGetOrganizationUsersQuery({
    view: 'current_org'
  });
  const team = teamData as OrganizationUserCurrentOrg[];

  const { data: partnersData = [], isLoading: partnersLoading } = useGetOrganizationUsersQuery({
    filter: 'partners',
    view: 'displayable'
  });
  const partners = partnersData as OrganizationUserDisplayable[];

  const [teamFollows, partnerFollows] = partition(
    follows.filter(({ state }) => state === 'following'),
    ({ organizationUser }) => organizationUser?.organizationId === currentOrgId
  );

  const recipients = useInitialRecipients({ follows });

  const { onSubmit, isSubmitting } = useSubmitWorkspaceForm({
    follows,
    workableType,
    shouldTrack,
    onSuccess: () => onCancel()
  });

  const form: FormikConfig<FormValues> = {
    enableReinitialize: true,
    onSubmit: ({ teamFollows, partnerFollows, notifyGroup, customNotifyList, recipients, ...values }) => {
      const currentFollows = follows.map(({ organizationUser: _, ...rest }) => rest);

      const { notifyIds, followsAttributes } = match(newFormEnabled)
        .with(true, () => {
          const updatedFollows = flattenRecipientsToFollows(recipients);
          const followsAttributes = getFollowsAttributes({ updatedFollows, currentFollows });
          const notifyIds = updatedFollows.filter(r => r.notifyOnSave).map(r => r.organizationUserId);
          return { notifyIds, followsAttributes };
        })
        .otherwise(() => {
          const updatedFollows = teamFollows.concat(partnerFollows);
          const followsAttributes = getFollowsAttributes({ updatedFollows, currentFollows });
          const notifyIds = getNotifyIds({
            updatedFollows,
            currentFollows,
            notifyGroup,
            customNotifyList,
            notifyNewFollowersOnly
          });

          return { notifyIds, followsAttributes };
        });

      onSubmit({ ...values, followsAttributes, notifyIds });
    },
    validationSchema: newFormEnabled ? yup.object({ recipients: recipientsSchema }) : undefined,
    initialValues: {
      teamFollows,
      partnerFollows,
      recipients,
      ...initialValues,
      notifyGroup: 'notifiables',
      customNotifyList: [],
      subject: '',
      customMessage: ''
    }
  };

  return (
    <div {...rest}>
      <Formik {...form}>
        <Form>
          <Card title={title} rightElement={<Button minimal icon="cross" onClick={onCancel} />}>
            {newFormEnabled ? (
              <CardBody>
                <RecipientSelect context="follow" />

                <WorkspaceFormCustomMessage />
              </CardBody>
            ) : (
              <CardBody>
                <div className="space-y-4">
                  <H3>{`1. Which team members should be added as followers to this workspace?`}</H3>

                  <FollowerSelectInput
                    name="teamFollows"
                    defaultButtonText="Add a team member"
                    organizationUsers={team || []}
                    disabled={teamLoading}
                    buttonProps={{ loading: teamLoading }}
                    data-test="workspace-followers-input"
                  />
                </div>
                <div className="space-y-4">
                  <H3>{`2. Which partners should be added as followers to this workspace?`}</H3>
                  <FollowerSelectInput
                    name="partnerFollows"
                    defaultButtonText="Add a partner"
                    organizationUsers={partners || []}
                    disabled={partnersLoading}
                    buttonProps={{ loading: partnersLoading }}
                    data-test="workspace-partners-input"
                  />
                </div>
                <WorkspaceFormNotificationsInput notifyNewFollowersOnly={notifyNewFollowersOnly} />
              </CardBody>
            )}

            <CardFooter>
              <Button
                large
                type="submit"
                text="Save"
                intent="primary"
                loading={isSubmitting}
                disabled={teamLoading || isSubmitting}
                data-test="workspace-submit-button"
              />
              <Button text="Cancel" disabled={isSubmitting} onClick={onCancel} />
            </CardFooter>
          </Card>
        </Form>
      </Formik>
    </div>
  );
};
