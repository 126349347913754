import React from 'react';
import { MenuItem, MenuItemProps } from '@blueprintjs/core';
import { Link, LinkProps } from 'react-router';

import { cn } from '@/app/lib/cn';

export type MenuItemLinkProps = {
  to: string;
  text: string;
  linkProps?: Partial<LinkProps>;
} & Partial<MenuItemProps>;

export const MenuItemLink = ({ to, text, linkProps, ...rest }: MenuItemLinkProps) => {
  return (
    <Link
      to={`/${to}`.replace(/^\/\//, '/')}
      {...linkProps}
      className={cn('text-current hover:no-underline', linkProps?.className)}
    >
      <MenuItem text={text} tagName="span" {...rest} />
    </Link>
  );
};
