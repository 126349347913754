import React from 'react';
import { Tag, Tooltip } from '@blueprintjs/core';

import { useBrowserOS } from '@/app/hooks/useBrowserOS';

type NewTabTooltipProps = {
  children: React.ReactNode;
  title: string;
};

export const NewTabTooltip = ({ children, title }: NewTabTooltipProps) => {
  const { metaKey } = useBrowserOS();
  const tooltip = (
    <>
      <p>{title}</p>
      <span>
        <Tag>{metaKey} + click</Tag> to open in a new tab
      </span>
    </>
  );

  return <Tooltip content={tooltip}>{children}</Tooltip>;
};
