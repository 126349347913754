import { LoaderFunction } from 'react-router';
import snakecaseKeys from 'snakecase-keys';

import { safely } from './errorHelpers';

const shouldDevTrack = process.govlyEnv.RAILS_ENV === 'development' || process.govlyEnv.RAILS_ENV === 'test';

const devTrack = (event: unknown, properties: unknown) => {
  console.debug({ event, properties });
};

export interface TrackableEvent {
  object: Lowercase<string>;
  action: Lowercase<string>;
  properties?: Record<string, unknown>;
}

const translateObject = (object: string): string => object.replace(/usaspendingaward/gi, 'award');

export const trackEvent = (event: TrackableEvent) => {
  safely(() => {
    const object = translateObject(event.object.trim());
    const eventName = `${object}.${event.action.trim()}`;
    const properties = snakecaseKeys(event.properties ?? {});

    if (shouldDevTrack) {
      devTrack(eventName, properties);
    } else {
      window.posthog?.capture(eventName, properties);
    }
  });
};

export const pageViewLoader =
  (object: Lowercase<string>): LoaderFunction =>
  ({ params }) => {
    trackEvent({ object, action: 'viewed', properties: { ...params } });
    return null;
  };
