import { useEffect } from 'react';

import { useAssistantStoreRef, useCurrentThreadQuery } from '@/app/organisms/Assistant/assistantContext';
import { makeAssistantThreadActions, getThreadMessages } from '@/app/organisms/Assistant/useAssistantStore';

export const usePopulateThreadHistory = () => {
  const store = useAssistantStoreRef();
  const threadQuery = useCurrentThreadQuery();
  const thread = threadQuery.data;

  useEffect(() => {
    if (thread?.messages?.length) {
      const messages = getThreadMessages(store.getState(), thread.id);
      const hasMessages = messages.length > 0;
      if (hasMessages) return;

      const { setMessages } = makeAssistantThreadActions(store, thread.id);
      setMessages(thread.messages.slice().reverse());
    }
  }, [store, thread]);
};
