import React from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { GovlyTableCard, GovlyTableCardProps } from './GovlyTableCard';

import { GovlyTableProvider, GovlyTableProviderProps } from '@/app/molecules/GovlyTable/GovlyTableContext';
import { useGovlyTable, UseGovlyTableArgs } from '@/app/molecules/GovlyTable/useGovlyTable';
import { Loading } from '@/app/atoms/Loading/Loading';
import { ErrorBoundary } from '@/app/atoms/ErrorBoundary/ErrorBoundary';
import { CardError } from '@/app/atoms/ErrorFallback/CardError';

/** Hoist commonly used props for better DX */
type HoistedTableProps =
  | 'columns'
  | 'data'
  | 'enableColumnFilters'
  | 'enableFilters'
  | 'enableHiding'
  | 'enableSorting'
  | 'getRowId'
  | 'initialState'
  | 'manualFiltering'
  | 'manualPagination'
  | 'manualSorting'
  | 'onColumnFiltersChange'
  | 'onColumnOrderChange'
  | 'onColumnVisibilityChange'
  | 'onPaginationChange'
  | 'onRowSelectionChange'
  | 'onSortingChange'
  | 'rowCount'
  | 'state';

type TableProps<Data> = Pick<UseGovlyTableArgs<Data>, HoistedTableProps> & {
  tableProps?: Omit<UseGovlyTableArgs<Data>, HoistedTableProps>;
};

type CommonProps = {
  title?: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  loadingRowCount?: number;
};

type CardProps = GovlyTableCardProps & Pick<NonNullable<GovlyTableCardProps['cardProps']>, 'rightElement'>;

type Props<Data> = TableProps<Data> & CardProps & CommonProps & Omit<GovlyTableProviderProps<Data>, 'table'>;

type RootProps<Data> = React.PropsWithChildren<
  Omit<Props<Data>, 'title' | 'subtitle' | 'emptyStateProps' | 'bodyProps' | 'cardProps'>
>;

export type { Props as GovlyTableProps, RootProps as GovlyTableRootProps };

function GovlyTableRootEB<Data>(props: RootProps<Data>) {
  return (
    <ErrorBoundary action={`GovlyTable ${props.id}`} fallback={<CardError title={`Table: ${props.id}`} />}>
      <GovlyTableRoot {...props} />
    </ErrorBoundary>
  );
}

export { GovlyTableRootEB as GovlyTableRoot };

function GovlyTableRoot<Data>({
  isLoading = false,
  loadingRowCount = 10,
  children,
  tableProps,
  /* Hoisted table props */
  columns,
  data,
  enableColumnFilters,
  enableFilters,
  enableHiding = false,
  enableSorting,
  getRowId,
  initialState,
  manualFiltering,
  manualPagination,
  manualSorting,
  onColumnFiltersChange,
  onColumnOrderChange,
  onColumnVisibilityChange,
  onPaginationChange,
  onRowSelectionChange,
  onSortingChange,
  rowCount,
  state,
  /* End hoisted table props */
  ...context
}: RootProps<Data>) {
  const { table } = useGovlyTable({
    enableColumnFilters,
    enableFilters,
    enableHiding,
    enableSorting,
    getRowId,
    initialState,
    manualFiltering,
    manualPagination,
    manualSorting,
    onColumnFiltersChange,
    onColumnOrderChange,
    onColumnVisibilityChange,
    onPaginationChange,
    onRowSelectionChange,
    onSortingChange,
    rowCount,
    state,
    ...withLoading({ columns, data, isLoading, loadingRowCount }),
    ...tableProps
  });

  return (
    <GovlyTableProvider table={table} isLoading={isLoading} {...context}>
      {children}
    </GovlyTableProvider>
  );
}

export function GovlyTable<Data>({
  bodyProps,
  rightElement,
  cardProps,
  emptyStateProps,
  title,
  subtitle,
  ...rootProps
}: Props<Data>) {
  return (
    <GovlyTableRoot {...rootProps}>
      <GovlyTableCard
        bodyProps={bodyProps}
        cardProps={{ title, subtitle, rightElement, ...cardProps }}
        emptyStateProps={emptyStateProps}
      />
      {rootProps.children}
    </GovlyTableRoot>
  );
}

function withLoading<Data>({
  columns,
  data,
  isLoading,
  loadingRowCount
}: {
  columns: ColumnDef<Data>[];
  data: Data[];
  isLoading: boolean;
  loadingRowCount: number;
}) {
  return isLoading && !data.length
    ? {
        columns: columns.map((c): typeof c => ({ ...c, cell: () => <Loading type="text" /> })),
        data: Array.from({ length: loadingRowCount }, (_, i) => ({ id: i })) as Data[]
      }
    : { columns, data };
}
