import { Button, ButtonProps } from '@blueprintjs/core';
import { Header } from '@tanstack/react-table';
import React from 'react';
import { match } from 'ts-pattern';

import { useGovlyTableContext } from '@/app/molecules/GovlyTable/GovlyTableContext';
import { useEventTracking } from '@/app/hooks/useEventTracking';
import { getHeaderText } from '@/app/molecules/GovlyTable/utils';

const BUTTON_PROPS: ButtonProps = {
  className: '-ml-[7px] -mt-[5px] text-xs font-medium uppercase text-gray-500',
  small: true,
  minimal: true
};

export function GovlyTableThSortButton<Data>({ header }: { header: Header<Data, unknown> }) {
  const { isLoading, id } = useGovlyTableContext<Data>();
  const { trackEvent } = useEventTracking();
  const headerText = getHeaderText(header);

  return (
    <Button
      {...BUTTON_PROPS}
      disabled={isLoading}
      onClick={() => {
        header.column.toggleSorting();
        trackEvent({
          object: id,
          action: 'toggle_sort',
          properties: { column: header.column.columnDef.header }
        });
      }}
      rightIcon={match(header.column.getIsSorted())
        .with('asc', () => 'chevron-up' as const)
        .with('desc', () => 'chevron-down' as const)
        .otherwise(() => undefined)}
      aria-label={match(header.column.getIsSorted())
        .with('asc', () => `${headerText} ascending`)
        .with('desc', () => `${headerText} descending`)
        .otherwise(() => undefined)}
      text={headerText}
    />
  );
}
