import { DialogProps } from '@blueprintjs/core';
import { createContext, useContext } from 'react';
import { createStore } from 'zustand';

import { createStoreSelectors } from '@/app/lib/createSelectors';
import { QuoteShow } from '@/types/__generated__/GovlyApi';

type Store = {
  workspaceId: string;
  fileToView: { name: string; link: string } | undefined;
  quoteToView: QuoteShow | undefined;
  shouldPollQuotes: boolean;
  shouldPollCustomQuotes: boolean;
  toolboxDialogProps: DialogProps;
};

type OppWorkspaceStoreProps = {
  workspaceId: string;
};

export const createOppWorkspaceStore = ({ workspaceId }: OppWorkspaceStoreProps) => {
  const store = createStore<Store>()(() => ({
    workspaceId,
    fileToView: undefined,
    quoteToView: undefined,
    shouldPollQuotes: true,
    shouldPollCustomQuotes: true,
    toolboxDialogProps: {
      isOpen: false
    }
  }));
  return createStoreSelectors(store);
};

type StoreRef = ReturnType<typeof createOppWorkspaceStore>;

export const OppWorkspaceStoreContext = createContext<StoreRef | null>(null);

export function useOppWorkspaceStoreRef(): StoreRef {
  const store = useContext(OppWorkspaceStoreContext);
  if (!store) {
    throw new Error('useOppWorkspaceStore must be used within a OppWorkspaceStoreProvider');
  }
  return store;
}
