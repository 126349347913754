import { type MiddlewareAPI, type Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { match } from 'ts-pattern';

export const rtkQueryLogger: Middleware = (_api: MiddlewareAPI) => next => action => {
  if (isRejectedWithValue(action)) {
    match(action)
      .with({ meta: { arg: { endpointName: 'refreshWorklist' } }, payload: { status: 429 } }, () => {})
      .otherwise(() => console.error('[RTK Query] Rejected:', action));
  }

  return next(action);
};
