import React from 'react';
import { Link, LinkProps } from 'react-router';

import { Main } from '@/app/atoms/Main/Main';
import { MainColumn } from '@/app/atoms/MainColumn/MainColumn';
import Logo from '@/images/components/LogoIcon';

export type ApplicationErrorProps = {
  ctaText?: string;
  ctaProps?: LinkProps;
  errorCode?: string;
  title?: string;
  description?: string;
  children?: React.ReactNode;
};

export const ApplicationError = ({
  ctaText = 'Go back',
  ctaProps = { to: '/' },
  errorCode = 'Whoops...',
  title = 'There was an unexpected error',
  description = 'Our team has been notified. Please refresh and try again.',
  children
}: ApplicationErrorProps) => {
  return (
    <Main>
      <MainColumn>
        <div className="flex flex-shrink-0 justify-center">
          <a href="/" className="inline-flex">
            <span className="sr-only">Govly</span>
            <Logo className="h-24 w-auto rounded" />
          </a>
        </div>
        <div className="py-12">
          <div className="text-center">
            <p className="text-sm font-semibold uppercase tracking-wide text-blue-700">{errorCode}</p>
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">{title}</h1>
            {children || <p className="mt-2 text-base text-gray-500">{description}</p>}
            <div className="mt-6">
              <Link {...ctaProps} replace className="text-base font-medium text-blue-700 hover:text-blue-600">
                {ctaText}
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </MainColumn>
    </Main>
  );
};
