import { Button, Menu, MenuDivider, MenuItem, Popover, Tag } from '@blueprintjs/core';
import React from 'react';

import { useAuthenticatedNavItems } from './useAuthenticatedNavItems';
import { useAuthenticatedNavContext } from './useAuthenticatedNavContext';
import { useTopNavItems } from './useTopNavItems';
import { useGetNavMenuItemProps } from './useGetNavMenuItemProps';

import { MenuItemLink } from '@/app/atoms/MenuItemLink/MenuItemLink';
import Avatar from '@/app/molecules/Avatar/Avatar';
import { AvatarButton } from '@/app/atoms/AvatarButton/AvatarButton';
import { OrganizationSwitcherButton } from '@/app/molecules/OrganizationSwitcher/OrganizationSwitcher';

export const MobileNav = () => {
  const context = useAuthenticatedNavContext();
  const { currentUser } = context;

  const { profileNodes, searchableProducts, otherProducts, systemNodes, sysAdminNodes } = useAuthenticatedNavItems();
  const { children, parent } = useTopNavItems();

  const current = children?.findLast(x => x.current);

  const getNavMenuItemProps = useGetNavMenuItemProps();

  const navLabel = [parent?.label, current?.label].filter(Boolean).join(' > ') || 'Menu';

  return (
    <div className="flex justify-between items-center gap-2 px-4 py-2 border-b-2 border-b-gray-200">
      <Popover
        placement="bottom-start"
        minimal
        modifiers={{ offset: { enabled: true } }}
        content={
          <Menu>
            {searchableProducts?.map(item => (
              <MenuItemLink
                key={item.to}
                text={item.label}
                to={item.to}
                icon={item.icon}
                intent={item.intent}
                active={item.current}
              />
            ))}

            <MenuDivider />

            {otherProducts?.map(item => (
              <MenuItemLink
                key={item.to}
                text={item.label}
                to={item.to}
                icon={item.icon}
                intent={item.intent}
                active={item.current}
              />
            ))}

            <MenuDivider />

            {children.map(item => (
              <MenuItemLink
                key={item.to}
                text={item.label}
                to={item.to}
                icon={item.icon}
                intent={item.intent}
                active={item.current}
              />
            ))}

            {sysAdminNodes.length > 0 && (
              <>
                <MenuDivider />

                {sysAdminNodes.map(item => (
                  <MenuItemLink
                    key={item.to}
                    text={item.label}
                    to={item.to}
                    icon={item.icon}
                    intent={item.intent}
                    active={item.current}
                  />
                ))}
              </>
            )}

            <MenuDivider />

            {systemNodes.map(item => (
              <MenuItemLink
                key={item.to}
                text={item.label}
                to={item.to}
                icon={item.icon}
                intent={item.intent}
                active={item.current}
              />
            ))}
          </Menu>
        }
      >
        <Button rightIcon="chevron-down">{navLabel}</Button>
      </Popover>

      <div className="flex-1" />

      {currentUser.multipleOrganizations ? (
        <OrganizationSwitcherButton />
      ) : (
        <Tag large key="organizationName">
          {currentUser.organizationName}
        </Tag>
      )}

      <Popover
        placement="bottom-start"
        minimal
        modifiers={{ offset: { enabled: true } }}
        content={<Menu>{profileNodes?.map(n => <MenuItem key={n.to} {...getNavMenuItemProps(n)} />)}</Menu>}
      >
        <AvatarButton
          avatar={
            <Avatar
              className="bg-blue-700 cursor-pointer"
              initials={currentUser.initials}
              imgSrc={currentUser.avatar?.thumbUrl}
              notificationProps={currentUser.hasUnreadNotifications ? { size: 'md', intent: 'success' } : undefined}
            />
          }
          ariaLabel="Open menu"
        />
      </Popover>
    </div>
  );
};
