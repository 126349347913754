import React, { forwardRef, useMemo } from 'react';
import { Button, TextArea, Tooltip, Icon } from '@blueprintjs/core';
import { useField, useFormikContext } from 'formik';

import { ChatMessage } from './ChatMessage';
import { Message as MessageType } from './useChatStore';
import { useSendChatMessage } from './useSendChatMessage';

import { Card, CardProps } from '@/app/atoms/Card/Card';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { cn } from '@/app/lib/cn';

const ChatOptions = ({ messagesCount, resetMessages }: { messagesCount: number; resetMessages: () => void }) => {
  const [field, _, { setValue }] = useField<boolean>('useCustomPrompt');

  const handleUseCustomPromptChange = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setValue(!field.value);
  };

  const handleResetMessages = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    resetMessages();
  };

  return (
    <div className="flex items-center gap-2">
      {messagesCount > 0 && (
        <Tooltip content="Reset chat history">
          <LinkTag tag={'button'} onClick={handleResetMessages}>
            Reset
          </LinkTag>
        </Tooltip>
      )}
      <Tooltip
        content={
          <>
            <p className="max-w-sm">
              <Icon icon="globe-network" />: When enabled, the AI chat will expand its context beyond the budget
              document and use information from the broader internet to generate responses.
            </p>
          </>
        }
      >
        <Button
          intent={field.value ? 'primary' : undefined}
          icon={'globe-network'}
          minimal={true}
          onClick={handleUseCustomPromptChange}
        />
      </Tooltip>
    </div>
  );
};

export type ChatFormProps = {
  messages: MessageType[];
  createMessage: ReturnType<typeof useSendChatMessage>['createMessage'];
  resetMessages: () => void;
  isLoading?: boolean;
  placeholder?: React.ReactNode;
  questions?: string[];
  cardProps?: CardProps;
};

const ChatForm = forwardRef<HTMLDivElement, ChatFormProps>(
  (
    {
      messages,
      createMessage,
      resetMessages,
      isLoading = false,
      cardProps = {},
      placeholder = 'Ask a question to get started.',
      questions = []
    },
    ref
  ) => {
    const [queryField, _, { setValue }] = useField<string>('query');
    const [entityIds] = useField<string[]>('filters.entityIds');
    const { submitForm } = useFormikContext();

    const randomizedQuestions = useMemo(
      () => questions?.sort(() => 0.5 - Math.random())?.slice(0, 3) || [],
      [questions]
    );

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        submitForm();
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        const mostRecentMessage = messages.filter(m => m.user === 'user').pop();
        if (mostRecentMessage && typeof mostRecentMessage.message === 'string') setValue(mostRecentMessage.message);
      }
    };

    const handlePlaceholderClick = (message: string) => {
      createMessage({ entityIds: entityIds.value, message, clearQuery: () => setValue('') });
    };

    const { className, ...restCardProps } = cardProps;
    return (
      <Card
        className={cn('max-h-[80vh] flex flex-col', className)}
        {...restCardProps}
        rightElement={<ChatOptions messagesCount={messages.length} resetMessages={resetMessages} />}
      >
        {messages.length === 0 ? (
          <div className="flex flex-col h-60 mx-4">
            <div className="flex-grow text-gray-500 text-center content-center">{placeholder}</div>
            <div className="flex gap-2 mt-8">
              {randomizedQuestions.map((q, i) => (
                <button
                  key={i}
                  className="inline p-2 rounded border hover:bg-gray-50 mb-2"
                  onClick={() => handlePlaceholderClick(q)}
                >
                  {q}
                </button>
              ))}
            </div>
          </div>
        ) : (
          <div className={cn('flex flex-col overflow-y-scroll mx-4')}>
            {messages.map((c, i) => (
              <ChatMessage key={i} message={c.message} user={c.user} />
            ))}

            {isLoading && <ChatMessage message="Typing..." user="bot" />}
            <div ref={ref}></div>
          </div>
        )}

        <div className="flex">
          <TextArea
            {...queryField}
            autoResize
            onKeyDown={handleKeyDown}
            className="resize-none flex-grow p-2 m-4 rounded border"
            placeholder="Ask a question..."
          />
        </div>
      </Card>
    );
  }
);
ChatForm.displayName = 'ChatForm';

export { ChatForm };
