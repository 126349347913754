import { useMemo } from 'react';
import { snakeCase, keyBy } from 'lodash-es';

import { rootApi } from './rootApi';

import {
  Calendar,
  OrganizationUser,
  OrganizationUserCurrentOrg,
  OrganizationUserDisplayable,
  OrganizationUserFollowerSelectInput,
  OrganizationUserWithReports
} from '@/types/__generated__/GovlyApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['OrganizationUser', 'CurrentUser'] });

export type GetOrganizationUsers = {
  params: {
    filter?: 'partners';
    view?: 'current_org' | 'displayable' | 'follower_select_input';
    organizationId?: string;
  };
  result:
    | OrganizationUser[]
    | OrganizationUserCurrentOrg[]
    | OrganizationUserDisplayable[]
    | OrganizationUserFollowerSelectInput[];
};

export type GetOrganizationUsersForView<View extends GetOrganizationUsers['params']['view']> =
  View extends 'current_org'
    ? OrganizationUserCurrentOrg[]
    : View extends 'displayable'
      ? OrganizationUserDisplayable[]
      : View extends 'follower_select_input'
        ? OrganizationUserFollowerSelectInput[]
        : OrganizationUser[];

export type GetOrginazitonUsersApiView = GetOrganizationUsers['params']['view'];

// Remove OrganizationUser because will never pass undefined as the view
export type OrgUserWithView = GetOrganizationUsersForView<NonNullable<GetOrginazitonUsersApiView>>[number];

type GetOrganizationUser = {
  params: {
    id: string;
    view?: 'current_org' | 'with_reports';
  };
  result: OrganizationUser | OrganizationUserCurrentOrg | OrganizationUserWithReports;
};

type GetOrganizationUserCalendar = {
  params: {
    id: string;
    hash?: string;
  };
  result: Calendar;
};

type CreateOrganizationUser = {
  params: {
    name?: string;
    email: string;
    phoneNumber?: string;
    role?: string;
    subscriptionSeatPaid?: boolean;
    send_invite?: boolean;
  };
  result: OrganizationUser;
};

type InviteOrganizationUser = {
  params: {
    id: string;
  };
  result: void;
};

type UpdateOrganizationUser = {
  params: {
    id: string;
    name?: string;
    phoneNumber?: string;
    email?: string;
    role?: string;
    subscriptionSeatPaid?: boolean;
  };
  result: OrganizationUser;
};

type DeleteOrganizationUser = {
  params: {
    id: string;
  };
  result: void;
};

type UpdateNotificationSetting = {
  params: {
    organizationUserId: string;
    setting: string;
    value: string;
    checked?: boolean;
  };
  result: OrganizationUser;
};

const OrganizationUsersApi = api.injectEndpoints({
  endpoints: build => ({
    getOrganizationUsers: build.query<GetOrganizationUsers['result'], GetOrganizationUsers['params']>({
      query: params => ({ url: '/v2/organization_users', params }),
      providesTags: ['OrganizationUser']
    }),

    getOrganizationUser: build.query<GetOrganizationUser['result'], GetOrganizationUser['params']>({
      query: ({ id, ...params }) => ({ url: `/v2/organization_users/${id}`, params }),
      providesTags: (_result, _err, params) => {
        if (params.id) {
          return [{ type: 'OrganizationUser', id: params.id }];
        }

        return [];
      }
    }),

    getOrganizationUserCalendar: build.query<
      GetOrganizationUserCalendar['result'],
      GetOrganizationUserCalendar['params']
    >({
      query: ({ id, ...params }) => ({ url: `/v2/organization_users/${id}/calendar`, params })
    }),

    createOrganizationUser: build.mutation<CreateOrganizationUser['result'], CreateOrganizationUser['params']>({
      query: body => ({
        url: `/v2/organization_users`,
        method: 'POST',
        body
      }),

      invalidatesTags: () => ['OrganizationUser']
    }),

    inviteOrganizationUser: build.mutation<InviteOrganizationUser['result'], InviteOrganizationUser['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/organization_users/${id}/resend_invitation`,
        method: 'POST',
        body
      }),

      invalidatesTags: () => ['OrganizationUser']
    }),

    updateOrganizationUser: build.mutation<UpdateOrganizationUser['result'], UpdateOrganizationUser['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/organization_users/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: result => [
        ...(result ? [{ type: 'OrganizationUser' as const, id: result.id }] : []),
        'OrganizationUser',
        'CurrentUser'
      ]
    }),

    deleteOrganizationUser: build.mutation<DeleteOrganizationUser['result'], DeleteOrganizationUser['params']>({
      query: ({ id }) => ({
        url: `/v2/organization_users/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: () => ['OrganizationUser']
    }),

    updateNotificationSetting: build.mutation<UpdateNotificationSetting['result'], UpdateNotificationSetting['params']>(
      {
        query: ({ organizationUserId, setting, checked, value }) => ({
          url: `/v2/organization_users/${organizationUserId}/notification_settings`,
          method: 'PATCH',
          body: {
            setting: snakeCase(setting),
            value,
            checked
          }
        }),
        invalidatesTags: result => [
          ...(result ? [{ type: 'OrganizationUser' as const, id: result.id }] : []),
          'OrganizationUser',
          'CurrentUser'
        ]
      }
    )
  })
});

export const useOrganizationUserIndex = (params: GetOrganizationUsers['params']) => {
  const { data = [] } = useGetOrganizationUsersQuery(params);
  return useMemo(() => keyBy(data, 'id'), [data]);
};

export const {
  useGetOrganizationUserQuery,
  useGetOrganizationUserCalendarQuery,
  useGetOrganizationUsersQuery,
  useCreateOrganizationUserMutation,
  useUpdateOrganizationUserMutation,
  useDeleteOrganizationUserMutation,
  useUpdateNotificationSettingMutation,
  useInviteOrganizationUserMutation
} = OrganizationUsersApi;
