import React from 'react';

import { GovernmentEntityChat } from './GovernmentEntityChat';
import { GovernmentEntityBreadcrumbs } from './GovernmentEntityBreadcrumbs';
import { AdditionalDetails } from './GovernmentEntityDetails/AdditionalDetails';
import { BudgetUploads } from './GovernmentEntityDetails/BudgetUploads';
import { Description } from './GovernmentEntityDetails/Description';
import { Hierarchy } from './GovernmentEntityDetails/Hierarchy';
import { Title } from './GovernmentEntityDetails/Title';
import { BudgetSummary } from './GovernmentEntityDetails/BudgetSummary';
import { BudgetRequestsChart } from './GovernmentEntityDetails/BudgetRequestsChart';
import { CategoryRequestsChart } from './GovernmentEntityDetails/CategoryRequestsChart';

import { CardList } from '@/app/molecules/CardList/CardList';
import { useMetaTags } from '@/app/hooks/useMetaTags';
import { NotFound } from '@/app/molecules/NotFound/NotFound';
import { MainColumn } from '@/app/atoms/MainColumn/MainColumn';
import { Main } from '@/app/atoms/Main/Main';
import { useGetGovernmentEntityQuery } from '@/api/governmentEntitiesApi';
import { AppLoading } from '@/app/atoms/AppLoading/AppLoading';

export const GovernmentEntity = ({ id, className }: { id: string; className?: string }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  useMetaTags(
    {
      title: governmentEntity ? `Agencies | ${governmentEntity.name}` : 'Agency Details'
    },
    [governmentEntity]
  );

  if (!id) return <NotFound />;
  if (isLoading) return <AppLoading />;
  if (!governmentEntity) return <NotFound />;

  const showCardList = !!(
    governmentEntity.url ||
    governmentEntity.ancestors?.length ||
    governmentEntity.uploads?.length ||
    governmentEntity.children?.length
  );

  return (
    <Main className={className}>
      <MainColumn className="space-y-4">
        <GovernmentEntityBreadcrumbs id={id} />
        <Title id={id} />
      </MainColumn>

      <MainColumn className="lg:hidden">
        <CardList>
          <Description id={id} />
          <AdditionalDetails id={id} />
          <BudgetUploads id={id} />
          <Hierarchy id={id} />
          <BudgetRequestsChart id={id} />
          <CategoryRequestsChart id={id} />
          <BudgetSummary id={id} />
          <GovernmentEntityChat id={id} />
        </CardList>
      </MainColumn>

      <MainColumn columnSpan={8} className="hidden lg:block">
        <CardList>
          <Description id={id} />
          <BudgetRequestsChart id={id} />
          <CategoryRequestsChart id={id} />
          <BudgetSummary id={id} />
          <GovernmentEntityChat id={id} />
        </CardList>
      </MainColumn>

      <MainColumn columnSpan={4} className="hidden lg:block">
        {showCardList && (
          <CardList>
            <AdditionalDetails id={id} />
            <BudgetUploads id={id} />
            <Hierarchy id={id} />
          </CardList>
        )}
      </MainColumn>
    </Main>
  );
};
