import React, { useState } from 'react';
import { Alert, Button, Icon, Tag } from '@blueprintjs/core';

import { AttachmentEntityTitle } from '@/app/organisms/OppWorkspaceAttachmentsCard/AttachmentEntityTitle';
import { AttachmentEntityTitleUtils } from '@/app/organisms/OppWorkspaceAttachmentsCard/utils';
import { WorkspaceAttachmentToolsButton } from '@/app/organisms/WorkspaceAttachmentToolsButton/WorkspaceAttachmentToolsButton';
import { useGetCurrentUserQuery } from '@/api/currentUserApi';
import { useDeleteWorkspaceAttachmentMutation, useGetWorkspaceAttachmentsQuery } from '@/api/workspacesAttachmentsApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { CardListItem, CardListItemProps } from '@/app/molecules/CardList/CardList';
import { useUploadWorkspaceAttachments } from '@/app/organisms/OppWorkspaceAttachmentsCard/useUploadWorkspaceAttachments';
import { FileInputTarget } from '@/app/atoms/FileInputTarget/FileInputTarget';

export const OppWorkspaceAttachmentsCard = ({
  workspaceId,
  cardListItemProps
}: {
  workspaceId: string;
  cardListItemProps: CardListItemProps;
}) => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const [deleteWorkspaceAttachment, deleteWorkspaceAttachmentMeta] = useDeleteWorkspaceAttachmentMutation();
  const [confirmDeleteAttachmentId, setConfirmDeleteAttachmentId] = useState<string | null>(null);
  const { data: attachments = [], isLoading } = useGetWorkspaceAttachmentsQuery({ id: workspaceId });
  const workspaceAttachments = attachments.slice().sort((a, b) => b.createdAt?.localeCompare(a.createdAt ?? '') ?? 0);

  const { isUploading, isDragActive, ref, onDrop } = useUploadWorkspaceAttachments({ workspaceId });

  return (
    <CardListItem
      ref={ref}
      {...cardListItemProps}
      wrapperClassName="relative"
      title={
        <div className="flex items-center gap-1">
          <span>Attachments</span>
          <Tag minimal>{workspaceAttachments.length}</Tag>
        </div>
      }
    >
      {isDragActive ? (
        <span className="block absolute top-0 left-0 w-full h-full border-2 border-dashed border-blue-500" />
      ) : null}

      {isLoading ? (
        <Loading type="flex-row" />
      ) : (
        <dl className="flex flex-col gap-y-4 col-span-2">
          {workspaceAttachments.map(attachment => (
            <AttachmentEntityTitle
              key={attachment.id}
              tooltipProps={{ className: 'max-w-full' }}
              {...AttachmentEntityTitleUtils.fromWorkspaceAttachment(attachment)}
              actionsButtonGroup={
                <>
                  <Button
                    aria-label="Delete attachment"
                    icon={<Icon icon="trash" size={12} />}
                    intent="danger"
                    loading={
                      deleteWorkspaceAttachmentMeta.isLoading &&
                      deleteWorkspaceAttachmentMeta.originalArgs?.attachmentId === attachment.id
                    }
                    small
                    onClick={() => {
                      setConfirmDeleteAttachmentId(attachment.id);
                    }}
                  />
                  <WorkspaceAttachmentToolsButton attachment={attachment} />
                </>
              }
            />
          ))}

          {currentUser?.compliancePreventUploads ? undefined : (
            <FileInputTarget onFileChange={onDrop} disabled={isUploading}>
              <Button outlined icon="paperclip" loading={isUploading} fill>
                Add Attachments
              </Button>
            </FileInputTarget>
          )}

          <Alert
            isOpen={confirmDeleteAttachmentId !== null}
            intent="danger"
            onClose={() => setConfirmDeleteAttachmentId(null)}
            confirmButtonText="Delete Attachment"
            cancelButtonText="Cancel"
            canEscapeKeyCancel
            canOutsideClickCancel
            onConfirm={() => {
              deleteWorkspaceAttachment({ workspaceId, attachmentId: confirmDeleteAttachmentId ?? '' });
              setConfirmDeleteAttachmentId(null);
            }}
          >
            <p>Are you sure? The attachment will be deleted permanently.</p>
          </Alert>
        </dl>
      )}
    </CardListItem>
  );
};
