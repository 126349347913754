import React from 'react';
import { Switch, SwitchProps } from '@blueprintjs/core';

import { useFormikInput } from '@/app/hooks/useFormikInput';

export type SwitchInputProps = {
  name: string;
  helperText?: string;
  submitOnChange?: boolean;
} & SwitchProps;

export const SwitchInput = ({
  name,
  disabled,
  inline,
  label,
  helperText,
  submitOnChange,
  ...rest
}: SwitchInputProps) => {
  const { field, onChange } = useFormikInput({ name, submitOnChange });
  return (
    <>
      <Switch
        disabled={disabled}
        checked={!!field.value}
        inline={inline}
        name={name}
        label={label}
        large
        onChange={() => {
          onChange(!field.value);
        }}
        {...rest}
      />
      {helperText ? <small className="text-xs text-gray-500">{helperText}</small> : null}
    </>
  );
};
