import { InputGroup } from '@blueprintjs/core';
import { Header } from '@tanstack/react-table';
import React from 'react';

import { useGovlyTableContext } from '@/app/molecules/GovlyTable/GovlyTableContext';
import { useEventTracking } from '@/app/hooks/useEventTracking';

export function GovlyTableThFilterInput<Data>({ header }: { header: Header<Data, unknown> }) {
  const { isLoading, id } = useGovlyTableContext<Data>();
  const { trackEvent } = useEventTracking();

  return (
    <InputGroup
      className="max-w-sm"
      value={(header.column.getFilterValue() ?? '') as string}
      small
      disabled={isLoading}
      placeholder={`Filter by ${header.column.columnDef.header}`}
      onChange={e => {
        header.column.setFilterValue(e.target.value || undefined);
        trackEvent({
          object: id,
          action: 'filter',
          properties: { column: header.column.columnDef.header }
        });
      }}
    />
  );
}
