import React, { useState, useMemo, useRef } from 'react';
import { useField } from 'formik';
import { FormGroup, MenuItem, Button, ButtonProps, Intent, Menu } from '@blueprintjs/core';
import { ItemListRendererProps, Select, SelectProps } from '@blueprintjs/select';
import { keyBy } from 'lodash-es';
import { useVirtualizer } from '@tanstack/react-virtual';

import { cn } from '@/app/lib/cn';
import { OrganizationUserDisplayable } from '@/types/__generated__/GovlyApi';
import { useGetCurrentUserQuery } from '@/api/currentUserApi';
import { LabelSpan } from '@/app/atoms/inputs/LabelSpan/LabelSpan';
import { RadioGroupInput } from '@/app/atoms/inputs/RadioGroupInput/RadioGroupInput';
import { useFuzzySearch } from '@/app/hooks/useFuzzySearch';
import { Avatar } from '@/app/molecules/Avatar/Avatar';
import { AvatarWithName } from '@/app/molecules/AvatarWithName/AvatarWithName';
import { LoadingSpinner } from '@/app/atoms/LoadingSpinner/LoadingSpinner';

type FollowersSelectInputUser = Pick<
  OrganizationUserDisplayable,
  'id' | 'email' | 'name' | 'avatar' | 'avatarColor' | 'initials' | 'organizationName' | 'organizationTeams'
>;

type FollowersSelectInputItem = {
  organizationUserId: string;
  notifications: string;
  state: string;
  createdById?: string;
  organizationUser: FollowersSelectInputUser;
};

type FollowersSelectInputProps = {
  name: string;
  organizationUsers: FollowersSelectInputUser[];
  className?: string;
  helperText?: string;
  disabled?: boolean;
  intent?: Intent;
  label?: string;
  labelInfo?: string;
  defaultButtonText?: string;
  noSelectionText?: string;
  staticButtonText?: string;
  buttonProps?: ButtonProps;
  loading?: boolean;
  hideFollowSettings?: boolean;
  noResultsComponent?: React.ReactNode;
};

const VIRTUALIZED_THRESHOLD = 200;

export const FollowerSelectInput = ({
  name,
  organizationUsers,
  className,
  helperText,
  disabled,
  label,
  labelInfo,
  loading = false,
  defaultButtonText,
  buttonProps,
  hideFollowSettings,
  ...rest
}: FollowersSelectInputProps) => {
  const [query, setQuery] = useState<string>('');

  const [field, meta, { setValue }] = useField<FollowersSelectInputItem[]>({ name });
  const { data: currentUser, isLoading: currentUserLoading } = useGetCurrentUserQuery();

  const organizationUserIndex = keyBy(organizationUsers, 'id');

  const items: FollowersSelectInputItem[] = useMemo(
    () =>
      organizationUsers.map(organizationUser => ({
        organizationUserId: organizationUser.id,
        notifications: 'user_setting',
        state: 'following',
        createdById: currentUser?.id,
        organizationUser
      })),
    [organizationUsers, currentUser]
  );

  const selectedIds = useMemo(() => field.value.map(({ organizationUserId }) => organizationUserId), [field.value]);

  const results = useFuzzySearch({
    options: { keys: ['organizationUser.name', 'organizationUser.email'] },
    data: items
      .filter(({ organizationUserId }) => !selectedIds.includes(organizationUserId))
      .map(item => ({ ...item, organizationUser: { ...organizationUserIndex[item.organizationUserId] } })),
    query
  });

  const error = meta.touched && meta.error;
  const intent = error ? ('danger' as Intent) : undefined;

  const renderItem: SelectProps<FollowersSelectInputItem>['itemRenderer'] = (item, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const { organizationUserId } = item;
    const orgUser = organizationUserIndex[organizationUserId];
    const text = (
      <span className="flex items-center space-x-2 overflow-hidden">
        <Avatar
          className={cn(organizationUserIndex[organizationUserId].avatarColor)}
          initials={organizationUserIndex[organizationUserId].initials}
          imgSrc={organizationUserIndex[organizationUserId].avatar?.thumbUrl}
          size="xs"
        />
        <span className="truncate">{`${orgUser.email}${name === 'partnerFollows' ? ` (${orgUser.organizationName})` : ''}`}</span>
      </span>
    );

    return <MenuItem key={item.organizationUserId} onClick={handleClick} text={text} />;
  };

  const selectItem: SelectProps<FollowersSelectInputItem>['onItemSelect'] = item => {
    const newItems = [...field.value, item];

    return setValue(newItems);
  };

  const deselectItem = (index: number) => {
    const deselected = field.value[index];
    const newItems = field.value.filter(v => v.organizationUserId !== deselected.organizationUserId);

    return setValue(newItems);
  };

  if (currentUserLoading) {
    return null;
  }

  const noResults = loading ? (
    <MenuItem
      disabled
      text={
        <div className="text-center">
          <LoadingSpinner />
        </div>
      }
    />
  ) : (
    <MenuItem disabled text="No results." />
  );

  return (
    <FormGroup
      className={cn('m-0', className)}
      helperText={helperText}
      disabled={disabled}
      label={<LabelSpan label={label} />}
      labelInfo={labelInfo}
      labelFor={name}
      intent={intent}
      contentClassName="mt-2"
      {...rest}
    >
      <Select<FollowersSelectInputItem>
        items={results}
        noResults={noResults}
        onItemSelect={selectItem}
        itemRenderer={(item, options) => renderItem(item, { ...options })}
        popoverProps={{ canEscapeKeyClose: true }}
        {...(items.length > VIRTUALIZED_THRESHOLD ? { itemListRenderer: props => <VirtualList {...props} /> } : {})}
        resetOnSelect
        onQueryChange={setQuery}
        className="w-fit"
        {...rest}
      >
        <Button
          rightIcon="chevron-down"
          text={defaultButtonText || 'Select'}
          className="flex"
          data-test="follower-select-add-button"
          {...buttonProps}
        />
      </Select>

      <div className="mt-4 grid grid-cols-1 gap-y-2">
        {field.value.map(({ organizationUserId }, index) => {
          const organizationUser = organizationUserIndex[organizationUserId];

          if (!organizationUser) {
            return null;
          }

          return (
            <div
              key={organizationUser.id}
              className="col-span-1 flex flex-wrap items-center justify-between rounded border border-gray-100 p-2"
            >
              <AvatarWithName
                initials={organizationUser.initials}
                imgSrc={organizationUser.avatar?.thumbUrl}
                avatarColor={organizationUser.avatarColor}
                id={organizationUser.id}
                email={organizationUser.email}
                name={organizationUser.name}
                teams={organizationUser.organizationTeams}
              />
              <div className="flex items-center space-x-2">
                {!hideFollowSettings && (
                  <RadioGroupInput
                    label="Notification Settings"
                    name={`${name}[${index}].notifications`}
                    inline
                    options={[
                      { label: 'Default', value: 'user_setting', className: 'mb-0' },
                      { label: 'Email', value: 'email', className: 'mb-0' },
                      { label: 'Muted', value: 'muted', className: 'mb-0' }
                    ]}
                  />
                )}
                <div>
                  <Button
                    intent="danger"
                    outlined
                    icon="trash"
                    aria-label={`Remove ${organizationUser.email} from followers`}
                    onClick={() => deselectItem(index)}
                    data-test="follower-select-remove-button"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </FormGroup>
  );
};

const ROW_HEIGHT = 34;

const VirtualList = ({ items, ...props }: ItemListRendererProps<FollowersSelectInputItem>) => {
  const parentRef = useRef<HTMLUListElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => ROW_HEIGHT
  });

  return (
    <Menu ulRef={parentRef} className="min-h-[40px] overflow-y-auto w-[360px] p-0">
      <div className="relative" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
        {props.filteredItems.length === 0 && <MenuItem disabled={true} text="No results." />}
        {rowVirtualizer.getVirtualItems().map(virtualRow => (
          <div
            key={virtualRow.index}
            className="absolute top-0 left-0 w-full"
            style={{
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`
            }}
          >
            {props.renderItem(props.filteredItems[virtualRow.index], virtualRow.index)}
          </div>
        ))}
      </div>
    </Menu>
  );
};
