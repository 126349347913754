import React from 'react';
import { isEmpty, truncate } from 'lodash-es';
import { EntityTitle } from '@blueprintjs/core';

import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { formatTime, DATETIME_24_SHORT, DATE_SHORT } from '@/app/lib/dates';
import { OppShow } from '@/types/__generated__/GovlyApi';
import { CardSubheading } from '@/app/atoms/Typography/Typography';
import { CardSection } from '@/app/atoms/Card/Card';
import { useGetOppQuery } from '@/api/oppsApi';

export const OppDetailsDescriptionFieldsPrediction = ({ opp }: { opp: OppShow }) => {
  const {
    postedAt,
    awardedAt,
    cancelledAt,
    modifiedAt,
    modified,
    parentOppId,
    naics,
    latestData: sourceData = {}
  } = opp;

  const {
    isLoading,
    isSuccess: isAuthorized,
    data: sourceOpp
  } = useGetOppQuery({ id: parentOppId ?? '' }, { skip: !parentOppId });

  const publicLink = sourceData.externalUrl ?? sourceData.procurementPortalPublicUrl;

  const rows = [
    {
      label: 'Source Opportunity',
      value: !isLoading && isAuthorized && sourceOpp?.id && sourceOpp?.displayName && (
        <LinkTag target="_blank" to={`/opportunities/${sourceOpp.id}`}>
          {sourceOpp.displayName}
        </LinkTag>
      )
    },
    { label: 'Predicted Post Date', value: formatTime(postedAt ?? '', DATE_SHORT) },
    { label: 'Awarded At', value: formatTime(awardedAt ?? '', DATETIME_24_SHORT) },
    { label: 'Cancelled At', value: formatTime(cancelledAt ?? '', DATETIME_24_SHORT) },
    { label: 'Modified At', value: modified && modifiedAt && formatTime(modifiedAt, DATETIME_24_SHORT) },
    { label: 'Predicted Contract Vehicle', value: sourceData.contract?.name },
    { label: 'Clearance?', value: sourceData.clearance_designation },
    { label: 'Predicted Set Aside', value: sourceData.setAside },
    { label: 'Predicted Groups', value: sourceData.groups?.join(', ') },
    {
      label: 'NAICS',
      value: naics?.join(', ')
    },
    { label: 'Latest Modification Reason', value: sourceData.modificationReason },
    { label: 'Response Location', value: sourceData.responseLocation },
    { label: 'Delivery Instructions', value: sourceData.deliveryInstructions },
    {
      label: 'Public Link',
      value: publicLink && (
        <LinkTag target="_blank" rel="noreferrer" className="mt-1 text-sm" tag="a" href={publicLink}>
          {truncate(publicLink.replace(/^http?:\/\//, ''), { length: 45 })}
        </LinkTag>
      )
    }
  ];

  return (
    <>
      {rows.map(
        ({ label, value }) =>
          !isEmpty(value) &&
          !isEmpty(label) && (
            <CardSection key={label} className="py-2">
              <div className="flex flex-wrap justify-between gap-y-2">
                <EntityTitle heading={CardSubheading} title={label} />
                {value}
              </div>
            </CardSection>
          )
      )}
    </>
  );
};
