import React from 'react';
import { Button, ButtonProps } from '@blueprintjs/core';

import { govlyTableCSVExport } from './govlyTableCSVExport';
import { useGovlyTableContext } from './GovlyTableContext';

import { useDeviceWidth } from '@/app/hooks/useDeviceWidth';

export const GovlyTableCSVExportButton = ({ filename, ...buttonProps }: { filename?: string } & ButtonProps) => {
  const { isDesktop } = useDeviceWidth();
  const { table, isLoading } = useGovlyTableContext();

  if (!isDesktop) return null;

  return (
    <Button
      {...buttonProps}
      disabled={isLoading}
      outlined
      icon="import"
      onClick={() => govlyTableCSVExport(table, filename)}
    >
      Download CSV
    </Button>
  );
};
