import React, { useEffect, useState } from 'react';
import { Dialog, AnchorButton, Button, NonIdealState } from '@blueprintjs/core';

import { Card } from '@/app/atoms/Card/Card';

const OFFICE_FILE_TYPES = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
const IMG_FILE_TYPES = ['jpg', 'jpeg', 'png', 'gif'];

type FileViewerProps = {
  downloadLink: string;
  fileName: string;
  fileUrl: string;
  onClose: () => void;
  disableDownload?: boolean;
  isOpen: boolean;
};

export const FileViewer = ({
  downloadLink,
  fileName,
  fileUrl,
  onClose,
  disableDownload = false,
  isOpen
}: FileViewerProps) => {
  const [textContent, setTextContent] = useState<string>();
  const isTXT = fileName?.endsWith('.txt');

  useEffect(() => {
    if (isTXT) {
      fetch(fileUrl)
        .then(response => response.text())
        .then(text => setTextContent(text.trim()));
    }
  }, [isTXT, fileUrl]);

  if (!fileName) return null;

  const fileType = fileName.split('.').pop()?.toLowerCase() ?? '';
  const isPDF = fileName.endsWith('.pdf');
  const showDownloadLink = !disableDownload && !isPDF;

  const File = () => {
    if (IMG_FILE_TYPES.includes(fileType)) {
      return (
        <div className="flex overflow-scroll mx-4 my-8 justify-center">
          <img src={fileUrl} alt={fileName} />
        </div>
      );
    } else if (OFFICE_FILE_TYPES.includes(fileType)) {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`}
          height="100%"
        />
      );
    } else if (isPDF) {
      const url = disableDownload ? fileUrl + '#toolbar=0 ' : fileUrl;
      return <embed src={url} height="100%" />;
    } else if (isTXT) {
      return (
        <div className="prose prose-sm overflow-scroll whitespace-pre-line mx-8 my-8">
          <p>{textContent}</p>
        </div>
      );
    }
    return (
      <div className="pt-16">
        <NonIdealState icon="document" title="File type not supported" />
      </div>
    );
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className="h-screen min-w-[92%] p-0">
      <Card
        className="rounded-b-none"
        title={fileName}
        rightElement={
          <div className="flex gap-x-2">
            {showDownloadLink && (
              <AnchorButton
                text="Download"
                icon="download"
                minimal
                href={downloadLink}
                target="_blank"
                rel="noopener noreferrer nofollow"
              />
            )}
            <Button minimal icon="cross" onClick={onClose} />
          </div>
        }
      />
      <File />
    </Dialog>
  );
};
