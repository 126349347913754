import { Button, Collapse } from '@blueprintjs/core';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import { TextAreaInput } from '@/app/atoms/inputs/TextAreaInput/TextAreaInput';
import { useFeatureFlag } from '@/app/hooks/useFeatureFlag';
import { Editor } from '@/app/molecules/Editor/Editor';

export const WorkspaceFormCustomMessage = () => {
  const customMessageEnabled = useFeatureFlag('followers-custom-message');
  const [isCustomMessageOpen, setIsCustomMessageOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext<{ subject: string; customMessage: string }>();

  return (
    customMessageEnabled && (
      <>
        <div className="flex justify-center">
          <Button intent="primary" minimal onClick={() => setIsCustomMessageOpen(!isCustomMessageOpen)}>
            Custom Message
          </Button>
        </div>
        <Collapse isOpen={isCustomMessageOpen}>
          <div className="space-y-4">
            <TextAreaInput name="subject" label="Subject" />
            <Editor
              id="customMessage"
              onChange={({ target: { value: newValue } }) => setFieldValue('customMessage', newValue)}
              inputProps={{ value: values.customMessage }}
              formGroupProps={{
                className: '[&_label]:-mb-4',
                label: 'Custom Message',
                labelInfo: '(optional)'
              }}
            />
          </div>
        </Collapse>
      </>
    )
  );
};
