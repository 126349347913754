import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['SysAdminOppSourceAttachments', 'SysAdminAffectedOrgs'] });

type CreateSysAdmin = {
  params: { oppSourceId: string; attachmentSignedIds: string[] };
  result: void;
};

type ListAffectedOrgs = {
  params: { oppSourceId: string };
  result: { affectedOrgs: string[] };
};

export const sysAdminOppSourceAttachments = api.injectEndpoints({
  endpoints: build => ({
    createSysAdminOppSourceAttachments: build.mutation<CreateSysAdmin['result'], CreateSysAdmin['params']>({
      query: body => ({
        url: '/v2/sys_admin_opp_source_attachments',
        method: 'POST',
        body
      })
    }),
    listSysAffectedOrgs: build.query<ListAffectedOrgs['result'], ListAffectedOrgs['params']>({
      query: params => ({
        url: '/v2/sys_admin_opp_source_attachments',
        params
      }),
      providesTags: () => ['SysAdminAffectedOrgs']
    })
  })
});

export const { useCreateSysAdminOppSourceAttachmentsMutation, useListSysAffectedOrgsQuery } =
  sysAdminOppSourceAttachments;
