import React from 'react';

import { Card, CardBody } from '@/app/atoms/Card/Card';
import { useGetGovernmentEntityQuery } from '@/api/governmentEntitiesApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { GovernmentEntityLink } from '@/app/organisms/GovernmentEntity/utils';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';

export const AdditionalDetails = ({ id }: { id: string }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  if (isLoading) return <Loading type="card" />;

  const url = governmentEntity?.url;
  const parentEntity = governmentEntity?.ancestors?.slice(-1)?.[0];

  if (!url && !parentEntity) return null;

  return (
    <Card title={'Additional Details'} titleRenderer={CardHeadingSmall} collapsible={true}>
      <CardBody>
        {url && (
          <>
            <h4 className="text-gray-500">Website</h4>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
          </>
        )}
        {parentEntity && (
          <>
            <h4 className="text-gray-500">Parent Agency</h4>
            <GovernmentEntityLink entity={parentEntity} />
          </>
        )}
      </CardBody>
    </Card>
  );
};
