import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { createSelectors } from '@/app/lib/createSelectors';

type Store = {
  isExpanded: boolean;
};

export const useNavStore = createSelectors(
  create<Store>()(persist(_set => ({ isExpanded: true }), { name: 'nav-store' }))
);

export const toggleExpanded = () => useNavStore.setState(state => ({ isExpanded: !state.isExpanded }));
