import React from 'react';
import { Button, Card as BpCard } from '@blueprintjs/core';
import { ArrayHelpers, useField } from 'formik';

import { AvatarWithName } from '@/app/molecules/AvatarWithName/AvatarWithName';
import { RadioGroupInput } from '@/app/atoms/inputs/RadioGroupInput/RadioGroupInput';
import { OrgUserRecipient } from '@/app/organisms/RecipientSelect/utils';
import { useRecipientSelectContext } from '@/app/organisms/RecipientSelect/RecipientSelectProvider';

type Props = {
  index: number;
  helpers: ArrayHelpers;
};

export const RecipientSelectTeamItem = ({ helpers, index }: Props) => {
  const [field] = useField<OrgUserRecipient>(`recipients.${index}`);
  const { context } = useRecipientSelectContext();

  return (
    <BpCard
      compact
      aria-label="team recipient"
      key={index}
      className="flex flex-col gap-y-2 sm:flex-row sm:items-center justify-between border-t-purple-500 border-t-4"
    >
      <AvatarWithName
        email={field.value.email}
        name={field.value.name}
        initials={field.value.initials}
        imgSrc={field.value.avatarUrl}
      />

      <div className="flex items-center gap-x-2 sm:[&_*]:my-0">
        {context === 'follow' && (
          <RadioGroupInput
            label="Notification Settings"
            name={`recipients.${index}.notifications`}
            inline
            options={[
              { label: 'Default', value: 'user_setting', className: 'mb-0' },
              { label: 'Email', value: 'email', className: 'mb-0' },
              { label: 'Muted', value: 'muted', className: 'mb-0' }
            ]}
          />
        )}
        <div>
          <Button
            intent="danger"
            minimal
            small
            icon="cross"
            aria-label="Remove teammate"
            onClick={() => {
              helpers.remove(index);
            }}
            data-test="follower-select-remove-button"
          />
        </div>
      </div>
    </BpCard>
  );
};
