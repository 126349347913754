import { IconName, Intent, TagProps } from '@blueprintjs/core';
import { matchPath } from 'react-router';

import { DrawerType } from '../AppDrawer/constants';

type Taggable<Node> = Node & {
  TagContent?: React.FC<{ node: Node; index: number }>;
  getTagProps?: (props: { node: Node; index: number }) => TagProps;
};

export type NavItem = Taggable<{
  to: string;
  label: string;
  absolute?: boolean;
  external?: boolean;
  icon?: IconName;
  current?: boolean;
  equivalentPaths?: string[];
  children?: NavItem[];
  enabled?: boolean;
  maxTabs?: number;
  state?: Record<string, unknown>;
  intent?: Intent;
  Component?: React.FC<{ node: NavItem; index: number }>;
  TabTitle?: React.FC<{ node: NavItem; index: number }>;
}>;

export type DrawerNavItem = {
  type: 'drawer';
  drawerType: DrawerType;
  children?: DrawerNavTab[];
};

export type DrawerNavTab = Taggable<{ type: 'tab'; tab: string; icon?: IconName; enabled?: boolean }>;

function makeSetCurrent(pathname: string) {
  const isCurrent = makeIsCurrent(pathname);

  return (nodes: NavItem[]): NavItem[] => {
    return nodes.map(node => ({
      ...node,
      current: isCurrent(node),
      ...(node.children ? { children: makeSetCurrent(pathname)(node.children) } : {})
    }));
  };
}

function getTos(node: NavItem): string[] {
  return [node.to, ...(node.equivalentPaths ?? []), ...(node.children?.flatMap(getTos) ?? [])];
}

const ID_PATTERN = /^[0-9]+$/;
const UUID_PATTERN = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
const AWARD_UNIQUE_KEY_PATTERN = /^CONT_(IDV|AWD)_.*$/;

const withSlashPrefix = (str: string) => (str.startsWith('/') ? str : `/${str}`);

const makePathDoesMatch = (pathname?: string) => {
  return (to?: string): boolean => {
    const match = matchPath({ path: withSlashPrefix(to ?? ''), caseSensitive: true }, withSlashPrefix(pathname ?? ''));

    const allIdsValid = Object.entries(match?.params ?? {})
      .filter(([key]) => key === 'id' || key.endsWith('Id'))
      .every(
        ([_, value = '']) => ID_PATTERN.test(value) || UUID_PATTERN.test(value) || AWARD_UNIQUE_KEY_PATTERN.test(value)
      );

    return Boolean(match && allIdsValid);
  };
};

function makeIsCurrent(pathname?: string) {
  if (!pathname) return () => false;

  const pathDoesMatch = makePathDoesMatch(pathname);
  return (node: NavItem) => getTos(node).some(pathDoesMatch);
}

const getEnabledNodes = (nodes: NavItem[]): NavItem[] =>
  nodes
    .filter(x => ('enabled' in x ? x.enabled === true : true))
    .map(x => ({ ...x, ...(x.children ? { children: getEnabledNodes(x.children) } : {}) }));

const setParentChildrenToDecscendant = (
  acc: Record<string, NavItem['children']>,
  node: NavItem,
  parentChildren: NavItem['children']
): Record<string, NavItem['children']> => {
  acc[node.to] = parentChildren;
  if (node.children) {
    return node.children.reduce((acc2, child) => setParentChildrenToDecscendant(acc2, child, parentChildren), acc);
  }
  return acc;
};

export const AuthenticatedNavItemsUtils = {
  makeSetCurrent,
  makePathDoesMatch,
  makeIsCurrent,
  getTos,
  getEnabledNodes,
  setParentChildrenToDecscendant
};
