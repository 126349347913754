import React, { useRef, useState } from 'react';
import { Icon } from '@blueprintjs/core';

import { getChatSessionId, makeChatActions, useChatStore } from './useChatStore';

import { useCreateChatMutation } from '@/api/chatsApi';
import { errorToast } from '@/app/lib/toaster';

const unhelpfulMessageRegex = /I am unable to assist you with this request|the search results do not|I could not find/;

export const useSendChatMessage = ({ storeId, numberOfResults }: { storeId: string; numberOfResults?: number }) => {
  const sessionId = useChatStore(getChatSessionId(storeId));

  const [seenFocusedMessage, setSeenFocusedMessage] = useState(false);
  const [createChatMutation, { isLoading }] = useCreateChatMutation({ fixedCacheKey: storeId });
  const ref = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 150);
  };

  const createMessage = async ({
    entityIds,
    message,
    clearQuery,
    useCustomPrompt
  }: {
    entityIds?: string[];
    message: string;
    clearQuery: () => void;
    useCustomPrompt?: boolean;
  }) => {
    if (!message) return;

    const { addMessage, addSessionId } = makeChatActions(storeId);

    addMessage({ message: message, user: 'user' });
    scrollToBottom();
    clearQuery();

    const res = await createChatMutation({
      entityIds,
      query: message,
      numberOfResults,
      sessionId,
      useCustomPrompt
    });

    if ('error' in res) {
      errorToast(res.error);
    }

    if ('data' in res && res.data) {
      addMessage({ message: res.data.output.text, user: 'bot' });
      addSessionId(res.data.sessionId);
      if (!useCustomPrompt && !seenFocusedMessage && res.data.output.text.match(unhelpfulMessageRegex)) {
        addMessage({
          message: (
            <span>
              Try toggling expanded search <Icon icon="globe-network" /> to have the chat assistant use information from
              the internet to help generate responses.
            </span>
          ),
          user: 'bot'
        });
        setSeenFocusedMessage(true);
      }
      scrollToBottom();
    }
  };

  return { createMessage, isLoading, ref };
};
