import { rootApi } from './rootApi';

import { FollowBase, OrganizationUserFollowerSelectInput, WorkspaceBase } from '@/types/__generated__/GovlyApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Workspace', 'Opp', 'Worklist', 'Contact', 'USASpendingAward'] });

export type LimitedFollow = Pick<FollowBase, 'organizationUserId' | 'state' | 'notifications'> & {
  organizationUser?: { organizationId: string } & Partial<
    Pick<OrganizationUserFollowerSelectInput, 'avatar' | 'email' | 'initials' | 'name'>
  >;
  id?: string;
};
export type FollowsAttributes = Omit<LimitedFollow, 'organizationUser'> & { email?: string };

export type CreateWorkspace = {
  params: {
    workableType?: string;
    workableId?: string;
    oppId?: string;
    notifyIds?: string[];
    followsAttributes?: FollowsAttributes[];
    subject?: string;
    customMessage?: string;
  } & Partial<Pick<WorkspaceBase, 'name' | 'privateAccess' | 'workflowStage' | 'workableType' | 'organizationDefault'>>;
  result: WorkspaceBase;
};

export type UpdateWorkspace = {
  params: {
    id: string;
    notifyIds: string[];
    followsAttributes?: FollowsAttributes[];
    subject?: string;
    customMessage?: string;
  } & Partial<Pick<WorkspaceBase, 'name' | 'privateAccess' | 'workflowStage' | 'archivedAt'>>;
  result: WorkspaceBase;
};

export const workspacesApi = api.injectEndpoints({
  endpoints: build => ({
    createWorkspace: build.mutation<CreateWorkspace['result'], CreateWorkspace['params']>({
      query: body => ({
        url: `/v2/workspaces`,
        method: 'POST',
        body
      }),
      invalidatesTags: result =>
        result?.workableType === 'Opp'
          ? [
              { type: 'Workspace', id: result.id },
              { type: 'Opp', id: result.workableId }
            ]
          : result?.workableType
            ? [result?.workableType]
            : ['Workspace']
    }),

    updateWorkspace: build.mutation<UpdateWorkspace['result'], UpdateWorkspace['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/workspaces/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: result =>
        result?.workableType === 'Opp'
          ? [{ type: 'Workspace', id: result?.id }, { type: 'Opp', id: result?.workableId }, { type: 'Worklist' }]
          : result?.workableType
            ? [result?.workableType]
            : []
    })
  })
});

export const { useCreateWorkspaceMutation, useUpdateWorkspaceMutation } = workspacesApi;
