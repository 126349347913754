import { useNavigate } from 'react-router';
import { FormikConfig } from 'formik';
import * as yup from 'yup';
import { partition } from 'lodash-es';
import { match } from 'ts-pattern';

import { errorToast, successToast } from '@/app/lib/toaster';
import { useCurrentUserAttribute } from '@/api/currentUserApi';
import { WorkflowStageValue } from '@/app/lib/workflowStages';
import { LimitedFollow } from '@/api/workspacesApi';
import { getFollowsAttributes, getNotifyIds } from '@/app/organisms/WorkableForm/utils';
import { useSubmitWorkspaceForm } from '@/app/organisms/WorkableForm/useSubmitWorkspaceForm';
import { useInitialRecipients } from '@/app/organisms/RecipientSelect/useInitialRecipients';
import { flattenRecipientsToFollows, Recipient, recipientsSchema } from '@/app/organisms/RecipientSelect/utils';
import { useFeatureFlag } from '@/app/hooks/useFeatureFlag';

type InitialValues = {
  id?: string;
  name: string;
  workflowStage: WorkflowStageValue;
  oppId?: string;
  organizationDefault?: boolean;
  privateAccess?: boolean;
};

type FormValues = InitialValues & {
  recipients: Recipient[];
  subject: string;
  customMessage: string;
  // Remove below once outreach-form is 100%
  teamFollows: LimitedFollow[];
  partnerFollows: LimitedFollow[];
  customNotifyList?: LimitedFollow[];
  notifyGroup: 'notifiables' | 'nobody' | 'select';
  // End remove
};

export type UseWorkspaceFormArgs = {
  enableReinitialize?: boolean;
  followersInputs: boolean;
  follows: LimitedFollow[];
  initialValues: InitialValues;
  notifyNewFollowersOnly?: boolean;
  onSuccess?: (args: { workflowStage?: WorkflowStageValue }) => void;
  onCancel: () => void;
  redirectOnSuccess?: boolean;
  shouldTrack?: boolean;
  successMessage?: string;
  trackingData?: Record<string, string | null>;
  workableTypeForLink: string;
};

export const useWorkspaceForm = ({
  onCancel,
  onSuccess = onCancel,
  initialValues,
  workableTypeForLink,
  followersInputs = true,
  follows,
  redirectOnSuccess = false,
  notifyNewFollowersOnly = true,
  successMessage,
  trackingData = {},
  shouldTrack = true
}: UseWorkspaceFormArgs) => {
  const newFormEnabled = useFeatureFlag('outreach-form');

  const navigate = useNavigate();
  const currentOrgId = useCurrentUserAttribute('organizationId');

  const { onSubmit } = useSubmitWorkspaceForm({
    follows,
    onSuccess: result => {
      if (initialValues.id) {
        successToast(successMessage || 'Workspace updated');
        onSuccess(result);
      } else {
        successToast(successMessage || 'Workspace created');
        if (redirectOnSuccess) {
          const { workableId, id } = result;
          navigate(`/${workableTypeForLink}/${workableId}/workspaces/${id}`);
        } else {
          onSuccess(result);
        }
      }
    },
    workableType: 'Opp',
    shouldTrack,
    trackingData
  });

  const [teamFollows, partnerFollows] = partition(
    follows.filter(({ state }) => state === 'following'),
    ({ organizationUser }) => organizationUser?.organizationId === currentOrgId
  );

  const recipients = useInitialRecipients({ follows });

  const form: FormikConfig<FormValues> = {
    enableReinitialize: true,
    onSubmit: async values => {
      try {
        const { teamFollows, partnerFollows, customNotifyList, notifyGroup, recipients, ...payload } = values;
        const currentFollows = follows.map(({ organizationUser: _, ...rest }) => rest);

        const { notifyIds, followsAttributes } = match(newFormEnabled)
          .with(true, () => {
            const updatedFollows = flattenRecipientsToFollows(recipients);
            const followsAttributes = getFollowsAttributes({ updatedFollows, currentFollows });
            const notifyIds = updatedFollows.filter(r => r.notifyOnSave).map(r => r.organizationUserId);
            return { notifyIds, followsAttributes };
          })
          .otherwise(() => {
            const updatedFollows = teamFollows.concat(partnerFollows);
            const followsAttributes = getFollowsAttributes({ updatedFollows, currentFollows });
            const notifyIds = getNotifyIds({
              updatedFollows,
              currentFollows,
              notifyGroup,
              customNotifyList,
              notifyNewFollowersOnly
            });
            return { notifyIds, followsAttributes };
          });

        const enrichedPayload = { ...payload, notifyIds, ...(followersInputs ? { followsAttributes } : {}) };

        await onSubmit(enrichedPayload);
      } catch (e) {
        errorToast(e);
      }
    },
    initialValues: {
      teamFollows,
      partnerFollows,
      recipients,
      subject: '',
      customMessage: '',
      ...initialValues,
      notifyGroup: 'notifiables',
      customNotifyList: []
    },
    validationSchema: yup.object({
      name: yup.string().required('Name is required.'),
      ...(newFormEnabled ? { recipients: recipientsSchema } : {})
    })
  };

  return form;
};
