import React from 'react';
import { Tag } from '@blueprintjs/core';
import { truncate } from 'lodash-es';
import { useNavigate, useSearchParams } from 'react-router';

import { useGetSimilarOppsQuery } from '@/api/oppsApi';
import { openDrawerOrNewTab } from '@/app/lib/navigation';
import { OppIndex } from '@/types/__generated__/GovlyApi';
import { Card, CardSection } from '@/app/atoms/Card/Card';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import { NewTabTooltip } from '@/app/molecules/NewTabTooltip/NewTabTooltip';
import { ScheduledDateTag } from '@/app/molecules/ScheduledDateTag/ScheduledDateTag';

export type SimilarOppsCardProps = {
  id: string;
  inDrawer?: boolean;
};

const CardRow = ({ opp, inDrawer }: { opp: OppIndex; inDrawer: boolean }) => {
  const [_searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const openOpp = (event: React.MouseEvent<HTMLElement>) => {
    openDrawerOrNewTab(event, `/opportunities/${opp.id}`, () => {
      if (inDrawer) {
        setSearchParams({ drawerType: 'opportunities', drawerId: opp.id });
      } else {
        navigate(`/opportunities/${opp.id}`);
      }
    });
  };

  return (
    <CardSection className="py-4 px-5" padded={false}>
      <div className="flex flex-wrap justify-start">
        <LinkTag tag="a" onClick={openOpp} className="pb-1 mr-2 inline-block">
          <NewTabTooltip title="View Opportunity">
            <Tag interactive>{opp.displayName}</Tag>
          </NewTabTooltip>
        </LinkTag>
        <ScheduledDateTag hideIntents={['success']} respondBy={opp.respondBy} className="pb-2 inline-block" />
      </div>
      <h4 className="inline-block">{truncate(opp.title, { length: 200 })}</h4>
    </CardSection>
  );
};

export const SimilarOppsCard = ({ id, inDrawer }: SimilarOppsCardProps) => {
  const { data } = useGetSimilarOppsQuery({ id });

  if (!data?.length) {
    return null;
  }

  return (
    <Card
      title="Similar Opportunities"
      titleRenderer={CardHeadingSmall}
      collapsible
      collapseProps={{ defaultIsOpen: !!data?.length }}
    >
      {data?.map(opp => <CardRow key={opp.id} opp={opp} inDrawer={!!inDrawer} />)}
    </Card>
  );
};
