import React from 'react';
import { Icon, Button, MenuItem } from '@blueprintjs/core';
import { ArrayHelpers, useField } from 'formik';

import { AvatarWithName } from '@/app/molecules/AvatarWithName/AvatarWithName';
import { GuestRecipient, SimpleRecipient } from '@/app/organisms/RecipientSelect/utils';
import { TextInput } from '@/app/atoms/inputs/TextInput/TextInput';
import {
  RecipientSelectGroupItem,
  RecipientSelectGroupItemProps
} from '@/app/organisms/RecipientSelect/RecipientSelectGroupItem';
import { useGetOutreachRecipientsQuery } from '@/api/outreachRecipientsApi';

type Props = {
  index: number;
  helpers: ArrayHelpers;
};

export const RecipientSelectGuestItem = ({ helpers, index }: Props) => {
  const [field] = useField<GuestRecipient['recipients']>(`recipients.${index}.recipients`);
  const guestEmails = new Set(field.value.map(recipient => recipient.email));

  const recipientsQuery = useGetOutreachRecipientsQuery();
  const history: SimpleRecipient[] = (recipientsQuery.data ?? []).map(r => ({
    email: r.recipientEmail,
    name: r.recipientName,
    company: r.recipientCompany
  }));

  const remainingContacts = history.filter(contact => !guestEmails.has(contact.email));

  return (
    <RecipientSelectGroupItem
      type="guest"
      helpers={helpers}
      index={index}
      name="Add guest"
      recipients={[...remainingContacts, { email: '', company: '', name: '', icon: 'plus', new: true }]}
      selectProps={{
        filterable: remainingContacts.length >= 6,
        resetOnSelect: true,
        createNewItemFromQuery: (query: string) => ({ email: query, new: true }),
        createNewItemRenderer: (query, active, handleClick) => (
          <MenuItem key={query} text={`Add ${query}`} onClick={handleClick} active={active} />
        )
      }}
      buttonProps={{
        icon: <Icon icon="office" className="text-orange-500" />,
        loading: recipientsQuery.isLoading
      }}
      SelectedItem={SelectedItem}
    />
  );
};

const SelectedItem: RecipientSelectGroupItemProps['SelectedItem'] = ({
  contact: guest,
  index: i,
  helpers: h,
  parentIndex
}) => {
  return (
    <div key={i} className="flex items-center justify-between w-full">
      {guest.new ? (
        <div className="flex items-center gap-x-2">
          <TextInput
            className="[&_label]:hidden"
            errorOnTouch={false}
            inputProps={{ small: true, placeholder: 'Email' }}
            name={`recipients.${parentIndex}.recipients.${i}.email`}
          />

          <TextInput
            className="[&_label]:hidden"
            errorOnTouch={false}
            inputProps={{ small: true, placeholder: 'Name (optional)' }}
            name={`recipients.${parentIndex}.recipients.${i}.name`}
          />

          <TextInput
            className="[&_label]:hidden"
            errorOnTouch={false}
            inputProps={{ small: true, placeholder: 'Company (optional)' }}
            name={`recipients.${parentIndex}.recipients.${i}.company`}
          />
        </div>
      ) : guest.email ? (
        <AvatarWithName key={i} size="sm" email={guest.email} name={guest.name} />
      ) : null}

      <Button
        icon="cross"
        small
        minimal
        intent="danger"
        aria-label={`Remove ${guest.email}`}
        onClick={() => h.remove(i)}
      />
    </div>
  );
};
