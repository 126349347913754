import React from 'react';
import { startCase, camelCase, isEmpty, truncate } from 'lodash-es';
import { EntityTitle } from '@blueprintjs/core';

import { useGetOppQuery } from '@/api/oppsApi';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { CardSection } from '@/app/atoms/Card/Card';
import { CardSubheading } from '@/app/atoms/Typography/Typography';

type OppDetailsOrganizationOppContextProps = {
  id: string;
};

export const OppDetailsOrganizationOppContext = ({ id }: OppDetailsOrganizationOppContextProps) => {
  const { data: opp } = useGetOppQuery({ id });

  const context = opp?.organizationOpp?.context;

  if (isEmpty(context)) {
    return null;
  }

  const items = Object.keys(context).map(key => {
    let value = context[key];
    if (isEmpty(value)) {
      return null;
    }

    let label = key;

    if (camelCase(key) === key) {
      label = startCase(key);
    }

    if (key === 'noBidMatchedLogic' && Array.isArray(value)) {
      label = 'No Bid Matched Logic';
      value = value.join(', ');
    }

    if (label === 'Acknowledged Iteration') {
      label = 'Acknowledged?';
      value = value ? 'Yes' : 'No';
    }

    if (label === 'No Bid Iteration') {
      label = 'No Bid?';
      value = value ? 'Yes' : 'No';
    }
    if (typeof value !== 'string') {
      return null;
    }

    if (value.startsWith('http')) {
      return (
        <CardSection key={label} className="py-2">
          <div className="flex flex-wrap justify-between gap-y-2">
            <EntityTitle heading={CardSubheading} title={label} />
            <LinkTag target="_blank" rel="noreferrer" className="mt-1 text-sm" tag="a" href={value}>
              {truncate(value.replace(/^https?:\/\//, ''), { length: 45 })}
            </LinkTag>
          </div>
        </CardSection>
      );
    }

    return (
      <CardSection key={label} className="py-2">
        <div className="flex flex-wrap justify-between gap-y-2">
          <EntityTitle heading={CardSubheading} title={label} />
          {value}
        </div>
      </CardSection>
    );
  });

  return items;
};
