import React from 'react';
import { Button, Callout } from '@blueprintjs/core';
import { Formik, Form } from 'formik';

import { formErrorToast, successToast } from '@/app/lib/toaster';
import { FileUploader } from '@/app/molecules/FileUploaders/FileUploader/FileUploader';
import { AttachmentEntityTitle } from '@/app/organisms/OppWorkspaceAttachmentsCard/AttachmentEntityTitle';
import { cn } from '@/app/lib/cn';
import { Card, CardFooter } from '@/app/atoms/Card/Card';
import {
  useCreateSysAdminOppSourceAttachmentsMutation,
  useListSysAffectedOrgsQuery
} from '@/api/sysAdminOppSourceAttachmentsApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { CheckboxInput } from '@/app/atoms/inputs/CheckboxInput/CheckboxInput';

type FormValues = {
  oppSourceId: string;
  rematch: boolean;
  attachments: { name: string; signedId: string }[];
};

type SysAdminAddAttachmentsFormProps = {
  oppSourceId: string;
  onClose: () => void;
};

export const SysAdminAddAttachmentsForm = ({ oppSourceId, onClose }: SysAdminAddAttachmentsFormProps) => {
  const [createSysAdminOppSourceAttachments, { isLoading }] = useCreateSysAdminOppSourceAttachmentsMutation();
  const { data: { affectedOrgs = [] } = {}, isLoading: isLoadingAffectedOrgs } = useListSysAffectedOrgsQuery({
    oppSourceId
  });

  if (isLoadingAffectedOrgs || affectedOrgs.length === 0) <Loading />;

  const initialValues: FormValues = {
    oppSourceId,
    rematch: true,
    attachments: []
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async values => {
        try {
          await createSysAdminOppSourceAttachments({
            oppSourceId,
            attachmentSignedIds: values.attachments.map(attachment => attachment.signedId)
          });

          successToast('Attachments queued for processing. Check back in a few minutes.');
          onClose();
        } catch (error) {
          formErrorToast(error as number);
        }
      }}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <Card title="Add Attachments">
            <div className="min-h-14 min-w-14 p-4 space-y-4">
              <Callout intent="primary">
                <p>
                  This change will affect the following organizations since they own an opp source with the same digest:
                </p>
                <ul>
                  {affectedOrgs.map((org: string) => (
                    <li key={org}>{org}</li>
                  ))}
                </ul>
              </Callout>
              <CheckboxInput
                label="Rematch Opp Source Digest"
                name="rematch"
                helperText="If checked, the opp will rematch after the attachments are processed and summarized. This will also queue match notifications."
              />
              <FileUploader
                id={oppSourceId}
                multiple
                onBulkAttach={async attachments => {
                  setFieldValue('attachments', [...values.attachments, ...attachments]);
                }}
              >
                {({ isDragActive }) => (
                  <Button
                    outlined
                    icon="paperclip"
                    className={cn(isDragActive ? 'outline-dashed outline-blue-500' : '')}
                  >
                    Add Attachments
                  </Button>
                )}
              </FileUploader>
              {values.attachments.length > 0 ? (
                <div className="flex flex-wrap gap-2">
                  {values.attachments.map((attachment, index) => (
                    <div key={index} className="flex items-center gap-x-2">
                      <AttachmentEntityTitle
                        {...{
                          extension: attachment.name.split('.').pop() ?? '',
                          url: '',
                          filename: attachment.name
                        }}
                        actionsButtonGroup={
                          <Button
                            aria-label="Remove attachment"
                            icon="small-cross"
                            intent="danger"
                            small
                            onClick={() => {
                              setFieldValue(
                                'attachments',
                                values.attachments.filter((_, i) => i !== index)
                              );
                            }}
                          />
                        }
                      />
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <CardFooter>
              <Button
                intent="primary"
                type="submit"
                loading={isSubmitting || isLoading}
                disabled={isLoading || values.attachments.length === 0}
              >
                Submit
              </Button>
              <Button onClick={() => onClose()}>Close</Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
