import React, { useState, useMemo } from 'react';
import { FormGroup, Button, MenuItem, ButtonProps, FormGroupProps, IconName } from '@blueprintjs/core';
import { Select, SelectProps } from '@blueprintjs/select';
import { get as lodashGet, keyBy } from 'lodash-es';
import { FieldMetaProps } from 'formik';

import { cn } from '@/app/lib/cn';
import { LabelSpan } from '@/app/atoms/inputs/LabelSpan/LabelSpan';
import { useFuzzySearch } from '@/app/hooks/useFuzzySearch';
import { LoadingSpinner } from '@/app/atoms/LoadingSpinner/LoadingSpinner';
import { useFormikInput } from '@/app/hooks/useFormikInput';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BaseItem = Record<string, any> & { disabled?: boolean; icon?: IconName };

const renderItem = <T extends BaseItem>(
  item: T,
  {
    modifiers,
    handleClick,
    valueAttr,
    labelAttr,
    shouldDismissPopover
  }: {
    modifiers: { matchesPredicate?: boolean; active?: boolean };
    handleClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    index: number;
    valueAttr: string;
    labelAttr: string;
    shouldDismissPopover: boolean;
  }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      active={modifiers.active}
      key={lodashGet(item, valueAttr) as string}
      onClick={handleClick}
      text={lodashGet(item, labelAttr) as string}
      shouldDismissPopover={shouldDismissPopover}
      disabled={item.disabled}
      icon={item.icon}
    />
  );
};

export type SelectInputProps<T extends BaseItem> = {
  name: string;
  items: T[];
  searchKeys?: string[];
  defaultButtonText?: string;
  noSelectionText?: string;
  staticButtonText?: string;
  buttonProps?: ButtonProps;
  loading?: boolean;
  noResultsComponent?: React.ReactNode;
  filterActive?: boolean;
  submitOnChange?: boolean;
  valueAttr?: string;
  labelAttr?: string;
  beforeChange?: (value: string) => void;
  shouldDismissPopover?: boolean;
} & FormGroupProps &
  Partial<SelectProps<T>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QueryHook<T = any> = (...args: T[]) => {
  data?: T;
  isLoading: boolean;
};

export type QuerySelectInputProps<T extends BaseItem> = {
  useQueryFn: QueryHook;
} & Omit<SelectInputProps<T>, 'items'>;

type SelectFormProps<T extends BaseItem> = {
  itemValueMap: Record<string, T>;
  field: { value: string };
  results: T[];
  noResults: React.ReactNode;
  setQuery: (query: string) => void;
  onChange: (value: string) => void;
  error: string | false | undefined;
  meta: FieldMetaProps<string>;
} & Omit<SelectInputProps<T>, 'items'>;

const SelectForm = <T extends BaseItem>({
  className,
  helperText,
  disabled,
  label,
  labelInfo,
  name,
  intent,
  contentClassName,
  itemValueMap,
  field,
  results,
  noResultsComponent,
  noResults,
  valueAttr = 'value',
  labelAttr = 'label',
  beforeChange,
  onChange,
  shouldDismissPopover = false,
  setQuery,
  staticButtonText,
  defaultButtonText,
  noSelectionText,
  buttonProps,
  error,
  meta,
  ...rest
}: SelectFormProps<T>) => {
  return (
    <FormGroup
      className={cn('m-0', className)}
      helperText={helperText}
      disabled={disabled}
      label={label ? <LabelSpan label={label} /> : null}
      labelInfo={labelInfo}
      labelFor={name}
      intent={intent}
      contentClassName={cn('mt-2', contentClassName)}
      {...rest}
    >
      <Select
        activeItem={itemValueMap[field.value]}
        items={results.slice(0, 40)}
        noResults={noResultsComponent || noResults}
        onItemSelect={item => {
          const value = item[valueAttr] as string;
          beforeChange?.(value);
          onChange(value);
        }}
        itemRenderer={(item, options) => renderItem(item, { ...options, valueAttr, labelAttr, shouldDismissPopover })}
        onQueryChange={q => setQuery(q)}
        disabled={disabled}
        className="w-fit"
      >
        <Button
          rightIcon="chevron-down"
          text={
            staticButtonText ||
            (itemValueMap[field.value]?.[labelAttr] as string) ||
            defaultButtonText ||
            noSelectionText ||
            'Select'
          }
          className="flex justify-between"
          large={!buttonProps?.small && buttonProps?.large !== false}
          disabled={disabled}
          {...buttonProps}
        />
      </Select>
      {error ? <small className="text-xs text-red-500">{meta.error}</small> : null}
    </FormGroup>
  );
};

export const QuerySelectInput = <T extends BaseItem>({
  name,
  className,
  helperText,
  submitOnChange = true,
  valueAttr = 'value',
  labelAttr = 'label',
  useQueryFn,
  disabled = false,
  label,
  labelInfo,
  contentClassName,
  noResultsComponent,
  beforeChange,
  shouldDismissPopover = false,
  staticButtonText,
  defaultButtonText,
  noSelectionText,
  buttonProps,
  ...rest
}: QuerySelectInputProps<T>) => {
  const { field, meta, onChange } = useFormikInput<string>({ name, submitOnChange });
  const [query, setQuery] = useState<string>('');
  const { data: { results = [], loading } = {} } = useQueryFn({ [labelAttr]: query });
  const itemValueMap = useMemo(() => keyBy(results, valueAttr), [results, valueAttr]);
  const error = meta.touched && meta.error;
  const intent = error ? 'danger' : undefined;

  const noResults = loading ? (
    <MenuItem
      disabled
      text={
        <div className="text-center">
          <LoadingSpinner />
        </div>
      }
    />
  ) : (
    <MenuItem disabled text="No results." />
  );

  return (
    <SelectForm
      className={className}
      helperText={helperText}
      disabled={disabled}
      label={label}
      labelInfo={labelInfo}
      name={name}
      intent={intent}
      contentClassName={contentClassName}
      itemValueMap={itemValueMap}
      labelAttr={labelAttr}
      valueAttr={valueAttr}
      field={field}
      results={results}
      noResults={noResults}
      setQuery={setQuery}
      onChange={onChange}
      error={error}
      meta={meta}
      noResultsComponent={noResultsComponent}
      noSelectionText={noSelectionText}
      staticButtonText={staticButtonText}
      defaultButtonText={defaultButtonText}
      buttonProps={buttonProps}
      beforeChange={beforeChange}
      shouldDismissPopover={shouldDismissPopover}
      {...rest}
    />
  );
};

export const SelectInput = <T extends BaseItem>({
  name,
  className,
  contentClassName,
  helperText,
  disabled,
  label,
  labelInfo,
  items,
  searchKeys,
  buttonProps,
  loading = false,
  defaultButtonText,
  noSelectionText,
  staticButtonText,
  noResultsComponent,
  filterActive = true,
  submitOnChange,
  valueAttr = 'value',
  labelAttr = 'label',
  beforeChange,
  shouldDismissPopover = false,
  ...rest
}: SelectInputProps<T>) => {
  const { field, meta, onChange } = useFormikInput<string>({ name, submitOnChange });
  const [query, setQuery] = useState<string>('');
  const itemValueMap = useMemo(() => keyBy(items, valueAttr), [items, valueAttr]);
  const error = meta.touched && meta.error;
  const intent = error ? 'danger' : undefined;

  const noResults = loading ? (
    <MenuItem
      disabled
      text={
        <div className="text-center">
          <LoadingSpinner />
        </div>
      }
    />
  ) : (
    <MenuItem disabled text="No results." />
  );

  const results = useFuzzySearch({
    options: { keys: searchKeys || [] },
    data: filterActive ? items.filter(({ [valueAttr]: value }) => field.value !== value) : items,
    query
  });

  return (
    <SelectForm
      className={className}
      helperText={helperText}
      disabled={disabled}
      label={label}
      labelInfo={labelInfo}
      name={name}
      intent={intent}
      contentClassName={contentClassName}
      itemValueMap={itemValueMap}
      labelAttr={labelAttr}
      valueAttr={valueAttr}
      field={field}
      results={results}
      noResults={noResults}
      setQuery={setQuery}
      onChange={onChange}
      error={error}
      meta={meta}
      noResultsComponent={noResultsComponent}
      noSelectionText={noSelectionText}
      staticButtonText={staticButtonText}
      defaultButtonText={defaultButtonText}
      buttonProps={buttonProps}
      beforeChange={beforeChange}
      shouldDismissPopover={shouldDismissPopover}
      {...rest}
    />
  );
};
