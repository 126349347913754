import React from 'react';
import { useParams, useSearchParams } from 'react-router';

import type { DrawerNavTab, NavItem } from '@/app/organisms/AuthenticatedNav/AuthenticatedNavItemsUtils';
import { useGetOppQuery } from '@/api/oppsApi';
import { DRAWER_PARAMS } from '@/app/organisms/AppDrawer/constants';

export type OppWorkspacesTabProps = {
  node: NavItem | DrawerNavTab;
  index: number;
};

export const OppWorkspacesTagContent = (_: OppWorkspacesTabProps) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const id = params.id ?? searchParams.get(DRAWER_PARAMS.id) ?? '';
  const query = useGetOppQuery({ id }, { skip: !id });
  const count = query.data?.workspaces.length ?? 0;
  return count > 0 ? <span>{count}</span> : null;
};
