import { create } from 'zustand';

import { createSelectors } from '@/app/lib/createSelectors';

type Store = {
  isOpen: boolean;
  isSwitching: boolean;
};

export type { Store as OrganizationSwitcherStore };

export const useOrganizationSwitcherStore = createSelectors(
  create<Store>(() => ({
    isOpen: false,
    isSwitching: false
  }))
);
