import React, { useMemo } from 'react';
import { Drawer, Button } from '@blueprintjs/core';
import { camelCase } from 'lodash-es';
import { createColumnHelper, AccessorKeyColumnDef } from '@tanstack/react-table';
import { useShallow } from 'zustand/shallow';

import { OppWorkspaceQuoteDrawerExportButton } from './OppWorkspaceQuoteDrawerExportButton';

import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { useDeviceWidth } from '@/app/hooks/useDeviceWidth';
import { successToast } from '@/app/lib/toaster';
import { GovlyTableToolbar } from '@/app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTable } from '@/app/molecules/GovlyTable/GovlyTable';
import { useGetOppWorkspaceQuery } from '@/api/oppWorkspacesApi';
import { useOppWorkspaceStoreRef } from '@/app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';
import { GovlyTableTitle } from '@/app/molecules/GovlyTable/GovlyTableTitle';
import { useCurrentUserAttribute } from '@/api/currentUserApi';

type TableData = {
  columns: AccessorKeyColumnDef<Record<string, string>, string>[];
  rows: { [key: string]: string | number }[];
};

function toCSV({ columns, rows }: TableData) {
  const headerValues = columns.map(column => column.header);
  const data = [headerValues, ...rows.map(row => columns.map(column => row[column.accessorKey]))];
  return data;
}

function copyToClipboard(data: TableData) {
  navigator.clipboard.writeText(
    toCSV(data)
      .map(lines => lines.join('\t'))
      .join('\n')
  );

  successToast('Copied to clipboard');
}

const columnHelper = createColumnHelper<Record<string, string>>();

export function OppWorkspaceQuoteDrawer() {
  const { isMobile } = useDeviceWidth();

  const storeRef = useOppWorkspaceStoreRef();
  const workspaceId = storeRef.use.workspaceId();
  const organizationId = useCurrentUserAttribute('organizationId');

  const quote = storeRef.useStore(useShallow(state => state.quoteToView));
  const { data: workspace } = useGetOppWorkspaceQuery({ id: workspaceId });

  const workableDisplayName = workspace?.workableDisplayName;
  const workspaceName = workspace?.name;
  const workableId = workspace?.workableId;

  const tableTitle = quote?.file ? quote.file.name.replace(/\.[^/.]+$/, '') : '';
  const moneyFormat = useMemo(() => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }), []);

  const columns = useMemo(() => {
    if (quote == null) return [];

    if (quote.llmExtractStatus === 'completed') {
      return [
        columnHelper.accessor('lineNumber', { header: 'Line', enableColumnFilter: false }),
        columnHelper.accessor('partNumber', { header: 'Part No.', enableColumnFilter: false }),
        columnHelper.accessor('description', {
          header: 'Description',
          enableColumnFilter: false,
          cell: props => <span className="whitespace-pre-line">{props.getValue()}</span>
        }),
        columnHelper.accessor('quantity', { header: 'Qty.', enableColumnFilter: false }),
        columnHelper.accessor('unitPrice', {
          header: 'Unit Price',
          enableColumnFilter: false,
          cell: props => moneyFormat.format(props.getValue() as unknown as number),
          footer: 'Total'
        }),
        columnHelper.accessor('extendedPrice', {
          header: 'Extended Price',
          enableColumnFilter: false,
          cell: props => moneyFormat.format(props.getValue() as unknown as number),
          footer: _ => moneyFormat.format(quote.lineItemsTotal || 0)
        })
      ];
    } else {
      if (!quote.lineItemsRaw) return [];
      const headers = quote.lineItemsRaw[0];

      return headers.map(header => {
        return columnHelper.accessor(camelCase(header.header), {
          header: header.header,
          enableColumnFilter: false
        });
      });
    }
  }, [quote, moneyFormat]);

  const data = useMemo(() => {
    if (quote == null) return [];

    let data: Record<string, string>[];
    if (quote.llmExtractStatus === 'completed') {
      data = Array.from(quote.lineItems) as unknown as Record<string, string>[];
    } else {
      if (!quote.lineItemsRaw) return [];

      data = quote.lineItemsRaw.map(lineItem => {
        const row: Record<string, string> = {};
        lineItem.forEach(item => {
          row[camelCase(item.header)] = item.value;
        });
        return row;
      });

      if (quote.lineItemsTotal) {
        const totalHeaders = quote.lineItemsRaw[0].slice(-2);
        const totalRow = {
          [camelCase(totalHeaders[0].header)]: 'Total',
          [camelCase(totalHeaders[1].header)]: moneyFormat.format(quote.lineItemsTotal)
        };

        data.push(totalRow);
      }
    }

    return data;
  }, [quote, moneyFormat]);

  const drawerTitle = (
    <div>
      <LinkTag to={`/opportunities/${workableId}`} target="_blank">
        {workableDisplayName || `Opportunity #${workableId}`}
      </LinkTag>
      <dd>Workspace: {workspaceName}</dd>
    </div>
  );

  return (
    <Drawer
      isOpen={!!quote}
      onClose={() => storeRef.setState({ quoteToView: undefined })}
      size={isMobile ? '100%' : '85%'}
      position="right"
      title={drawerTitle}
    >
      <div className="overflow-auto">
        {quote ? (
          <GovlyTable
            id="quote_drawer"
            columns={columns}
            data={data}
            title={<GovlyTableTitle title={tableTitle} />}
            striped={true}
            rightElement={
              <GovlyTableToolbar>
                <Button icon="clipboard" outlined onClick={() => copyToClipboard({ columns, rows: data })}>
                  Copy
                </Button>
                <OppWorkspaceQuoteDrawerExportButton
                  quote={quote}
                  organizationId={organizationId}
                  workspaceId={workspaceId}
                  csvFilename={tableTitle}
                />
              </GovlyTableToolbar>
            }
          />
        ) : null}
      </div>
    </Drawer>
  );
}
