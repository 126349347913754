import { PaginationState, createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import { useMemo } from 'react';

import { useGetAwardSubawardsQuery } from '@/api/awardsApi';
import { asCurrency } from '@/app/lib/numbers';
import { GovlyTable } from '@/app/molecules/GovlyTable/GovlyTable';
import { USASpendingAwardShow, USASpendingSubawardIndex } from '@/types/__generated__/GovlyApi';

type Props = { award: USASpendingAwardShow };

const columnHelper = createColumnHelper<USASpendingSubawardIndex>();

const PAGE_SIZE = 5;

export const AwardDetailsSubawardsTable = ({ award }: Props) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: PAGE_SIZE
  });

  const subawardsQuery = useGetAwardSubawardsQuery({
    contractAwardUniqueKey: award.contractAwardUniqueKey,
    page: pagination.pageIndex + 1
  });
  const pageMeta = subawardsQuery.data?.meta;

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('subAwardeeOrRecipientLegalRaw', { header: 'Recipient Name' }),
      columnHelper.accessor('subActionDate', { header: 'Action Date', size: 125 }),
      columnHelper.accessor('subawardAmount', {
        header: 'Amount',
        cell: e => asCurrency(e.getValue(), {})
      }),
      columnHelper.accessor('subawardDescription', {
        header: 'Description',
        cell: e => <span className="text-wrap">{e.getValue() ?? '(N/A)'}</span>
      })
    ];
  }, []);

  const data = useMemo(() => {
    return subawardsQuery.data?.results ?? [];
  }, [subawardsQuery.data]);

  return (
    <GovlyTable
      id="award_details_subawards_table"
      columns={columns}
      isLoading={subawardsQuery.isLoading}
      loadingRowCount={PAGE_SIZE}
      data={data}
      title="Sub-Awards"
      manualPagination
      enableSorting={false}
      enableFilters={false}
      rowCount={pageMeta?.total ?? 0}
      onPaginationChange={setPagination}
      state={{ pagination }}
    />
  );
};
