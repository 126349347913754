import { keyBy } from 'lodash-es';
import { match, P } from 'ts-pattern';

import { FollowsAttributes, LimitedFollow } from '@/api/workspacesApi';

export const getFollowsAttributes = ({
  updatedFollows,
  currentFollows
}: {
  updatedFollows: LimitedFollow[];
  currentFollows: LimitedFollow[];
}): FollowsAttributes[] => {
  const updatedFollowsMap = keyBy(updatedFollows, 'organizationUserId');
  const currentFollowsMap = keyBy(currentFollows, 'organizationUserId');

  const allFollowers = [...new Set(Object.keys(currentFollowsMap).concat(Object.keys(updatedFollowsMap)))];

  const followsAttributes = allFollowers.map(ouid => {
    const updatedFollow = updatedFollowsMap[ouid];
    const originalFollow = currentFollowsMap[ouid];

    if (originalFollow && !updatedFollow) {
      return {
        id: originalFollow.id,
        state: 'unfollowed' as const,
        notifications: 'muted' as const,
        organizationUserId: originalFollow.organizationUserId,
        email: undefined
      };
    }

    return {
      id: originalFollow?.id || updatedFollow.id,
      state: updatedFollow.state,
      notifications: updatedFollow.notifications,
      organizationUserId: updatedFollow.organizationUserId,
      email: updatedFollow.organizationUser?.email
    };
  });

  return followsAttributes;
};

export const getNotifyIds = ({
  updatedFollows,
  currentFollows,
  notifyGroup,
  customNotifyList,
  notifyNewFollowersOnly
}: {
  updatedFollows: LimitedFollow[];
  currentFollows: LimitedFollow[];
  notifyGroup: 'notifiables' | 'nobody' | 'select';
  customNotifyList?: LimitedFollow[];
  notifyNewFollowersOnly: boolean;
}) => {
  const notifyIds = match({ notifyGroup, customNotifyList, notifyNewFollowersOnly })
    .with({ notifyGroup: 'select', customNotifyList: P.nonNullable }, ({ customNotifyList }) =>
      customNotifyList.map(({ organizationUserId }) => organizationUserId)
    )
    .with({ notifyGroup: 'notifiables', notifyNewFollowersOnly: true }, () => {
      const ids = [...updatedFollows, ...currentFollows]
        .filter(({ id }) => !id)
        .map(({ organizationUserId }) => organizationUserId);
      return [...new Set(ids)];
    })
    .with({ notifyGroup: 'notifiables', notifyNewFollowersOnly: false }, () => {
      const ids = [...updatedFollows, ...currentFollows].map(({ organizationUserId }) => organizationUserId);
      return [...new Set(ids)];
    })
    .otherwise(() => []);

  return notifyIds;
};
