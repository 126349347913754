import React from 'react';
import { Header } from '@tanstack/react-table';

import { useGetColumnWidth } from './useGetColumnWidth';

import { cn } from '@/app/lib/cn';

export function GovlyTableTh<Data>({
  header,
  className,
  ...rest
}: { header: Header<Data, unknown> } & React.ComponentProps<'th'>) {
  const getColumnWidth = useGetColumnWidth();

  return (
    <th
      style={{
        width: getColumnWidth(header.column.getSize()),
        minWidth: getColumnWidth(header.column.columnDef.minSize ?? 0)
      }}
      className={cn(
        'p-3 align-middle text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-50 first:pl-5 last:pr-5',
        className
      )}
      {...rest}
    />
  );
}
