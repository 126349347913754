import { Activity, ActivityExtended } from '@/types/__generated__/GovlyApi';

type CustomData = {
  customData: {
    mentions?: string[];
    displayId?: string;
  };
};

export type MentionActivity = (Activity | ActivityExtended) & CustomData;

export type LinkActivity = ActivityExtended & CustomData;

export const hasMentions = (activities: MentionActivity[] = [], email: string) =>
  activities.some(activity => activity.customData?.mentions?.includes(email));

export const hasComments = (activities: (Activity | ActivityExtended)[] = []) =>
  activities.some(activity => activity.message?.includes('posted a new comment'));

export const hasReactions = (activities: (Activity | ActivityExtended)[] = []) =>
  activities.some(activity => activity.message?.includes('reacted with'));

export const hasOppActionTargetType = (activities: (Activity | ActivityExtended)[] = []) =>
  activities.some(u => u.actionTargetType === 'Opp');

export const mentionDecorator = (activity: MentionActivity, currentUserEmail: string) => {
  let message = activity.message;
  if ((activity.customData?.mentions?.length ?? 0) > 0 && activity.customData.mentions?.includes(currentUserEmail)) {
    message = activity.message?.replace('posted', 'mentioned you in');
  }
  return message;
};

export const linkDecorator = (activity: LinkActivity) => {
  let link = `/opportunities/${activity.actionTargetId}`;
  if (activity.actionTargetType === 'Workspace')
    link = `/opportunities/${activity.customData.displayId}/workspaces/${activity.actionTargetId}`;

  return link;
};

export const linkNameDecorator = (activity: LinkActivity) => {
  let linkName = 'View Opportunity';
  if (activity.actionTargetType === 'Workspace') linkName = 'View Workspace';

  return linkName;
};
