import React from 'react';
import { Tag, TagProps, Intent } from '@blueprintjs/core';
import { keyBy } from 'lodash-es';

import { workflowStages } from '@/app/lib/workflowStages';
import { WorkspaceBase } from '@/types/__generated__/GovlyApi';

type WorkflowStageTagProps = { workflowStage: WorkspaceBase['workflowStage'] } & TagProps;

export const WorkflowStageTag = ({ workflowStage, ...rest }: WorkflowStageTagProps) => {
  const decoratedStages = keyBy(workflowStages, 'value');
  if (!workflowStage) return null;

  let intent: Intent | undefined = 'success';
  let minimal = false;

  if (['submitted', 'published'].includes(workflowStage)) {
    intent = 'primary';
  }
  if (['not_awarded', 'no_bid', 'rejected'].includes(workflowStage)) {
    intent = 'danger';
  }
  if (workflowStage === 'qualifying') {
    intent = undefined;
    minimal = true;
  }

  return (
    <Tag className="mr-1" intent={intent} minimal={minimal} {...rest}>
      {decoratedStages[workflowStage].label}
    </Tag>
  );
};
