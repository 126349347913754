import React, { useState } from 'react';

import { LabelList } from '@/app/organisms/LabelList/LabelList';
import { LabelFormDialog } from '@/app/organisms/LabelFormDialog/LabelFormDialog';
import { OrganizationLabel } from '@/types/__generated__/GovlyApi';

type Props = Partial<React.ComponentProps<typeof LabelFormDialog>> & {
  taggableId: string;
  taggableType: 'Opp' | 'Workspace' | 'Contact' | 'USASpendingAward';
  labels?: OrganizationLabel[];
};

export const LabelsDescriptionListField = ({ taggableId, taggableType, labels = [], ...rest }: Props) => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-y-1">
        <LabelList labels={labels} onAdd={() => setIsFormOpen(true)} />
      </div>

      <LabelFormDialog
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        initialValues={{
          organizationLabelIds: labels.map(({ id }) => id),
          taggableId,
          taggableType
        }}
        {...rest}
      />
    </>
  );
};
