import React from 'react';
import { ErrorBoundary as AppsignalErrorBoundary } from '@appsignal/react';

import { appsignal } from '@/app/lib/appsignal';
import { defaultTags } from '@/app/lib/errorHelpers';

export type ErrorBoundaryProps = Omit<React.ComponentProps<typeof AppsignalErrorBoundary>, 'instance' | 'fallback'> & {
  fallback: React.ReactNode;
  action: string;
};

export const ErrorBoundary = (props: ErrorBoundaryProps) => {
  return (
    <AppsignalErrorBoundary {...props} fallback={_error => props.fallback} tags={defaultTags} instance={appsignal} />
  );
};
