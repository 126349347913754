import { RowSelectionState, VisibilityState } from '@tanstack/react-table';
import { create } from 'zustand';
import { Updater } from '@tanstack/react-table';

import { createSelectors } from '@/app/lib/createSelectors';
import type { GetWorklistViews } from '@/api/worklistViewsApi';
import { WorkspaceFormAttrs } from '@/app/organisms/WorklistWorkspaces/types';
import { DEFAULT_MY_WORKLIST_VIEW, WORKLIST_VIEW_CREATE_NEW_ITEM } from '@/app/organisms/WorklistWorkspaces/constants';

export type WorklistView = GetWorklistViews['result'][number];

interface WorklistStore {
  recentlyFollowedOppIds: Set<string>;
  pollingInterval: number;
  viewId: string;
  isViewDrawerOpen: boolean;
  workspaceFormAttrs: WorkspaceFormAttrs | null;
  selectedRows: RowSelectionState;
  columnVisibility: VisibilityState;
  columnOrder: string[] | undefined;
  defaultColumnOrder: string[];
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
}

export const useWorklistStore = createSelectors(
  create<WorklistStore>(() => ({
    recentlyFollowedOppIds: new Set(),
    pollingInterval: 0,
    viewId: DEFAULT_MY_WORKLIST_VIEW.id,
    isViewDrawerOpen: false,
    columnOrder: undefined,
    defaultColumnOrder: [],
    workspaceFormAttrs: null,
    selectedRows: {},
    columnVisibility: {},
    pagination: {
      pageIndex: 0,
      pageSize: 50
    }
  }))
);

export const getTableState = (): Omit<WorklistView['tableState'], 'filters'> => {
  const state = useWorklistStore.getState();
  return {
    columnVisibility: state.columnVisibility,
    ...(state.columnOrder ? { columnOrder: state.columnOrder } : {}),
    pagination: { pageSize: state.pagination.pageSize, pageIndex: 0 }
  };
};

export const setIsViewDrawerOpen = (open: boolean) => useWorklistStore.setState({ isViewDrawerOpen: open });

export const setView = (view?: GetWorklistViews['result'][number]) =>
  useWorklistStore.setState({
    columnVisibility: view?.tableState?.columnVisibility ?? {},
    columnOrder: view?.tableState?.columnOrder,
    pagination: {
      pageSize: view?.tableState?.pagination?.pageSize ?? 50,
      pageIndex: view?.tableState?.pagination?.pageIndex ?? 0
    }
  });

export const setViewId = (id?: string) =>
  useWorklistStore.setState({
    viewId: id,
    ...(id === WORKLIST_VIEW_CREATE_NEW_ITEM.value ? { isViewDrawerOpen: true } : {})
  });

export const setDefaultColumnOrder = (order: string[]) => useWorklistStore.setState({ defaultColumnOrder: order });

export const setWorkspaceFormAttrs = (attrs: WorklistStore['workspaceFormAttrs']) =>
  useWorklistStore.setState({ workspaceFormAttrs: attrs });

export const setSelectedRows = (updater: Updater<WorklistStore['selectedRows']>) => {
  const state = typeof updater === 'function' ? updater(useWorklistStore.getState().selectedRows) : updater;
  useWorklistStore.setState({ selectedRows: state });
};

export const setColumnVisibility = (updater: Updater<WorklistStore['columnVisibility']>) => {
  const state = typeof updater === 'function' ? updater(useWorklistStore.getState().columnVisibility) : updater;
  useWorklistStore.setState({ columnVisibility: state });
};

export const setColumnOrder = (updater: Updater<NonNullable<WorklistStore['columnOrder']>>) => {
  const state = typeof updater === 'function' ? updater(useWorklistStore.getState().columnOrder ?? []) : updater;
  useWorklistStore.setState({ columnOrder: state });
};

export const setPagination = (updater: Updater<WorklistStore['pagination']>) => {
  const state = typeof updater === 'function' ? updater(useWorklistStore.getState().pagination) : updater;
  useWorklistStore.setState({ pagination: state });
};

export const useWorklistColumnOrder = () => {
  const columnOrder = useWorklistStore.use.columnOrder();
  const defaultColumnOrder = useWorklistStore.use.defaultColumnOrder();
  return columnOrder ?? defaultColumnOrder;
};

export const addRecentlyFollowedOppId = (id: string) => {
  useWorklistStore.setState(state => {
    state.recentlyFollowedOppIds.add(id);
    return { recentlyFollowedOppIds: state.recentlyFollowedOppIds };
  });
};

export const removeRecentlyFollowedOppId = (id: string) => {
  useWorklistStore.setState(state => {
    state.recentlyFollowedOppIds.delete(id);
    return { recentlyFollowedOppIds: state.recentlyFollowedOppIds };
  });
};

export const stopPolling = () => {
  useWorklistStore.setState({
    recentlyFollowedOppIds: new Set(),
    pollingInterval: POLLING_INTERVAL_OFF
  });
};

export const startPolling = () => {
  useWorklistStore.setState({ pollingInterval: POLLING_INTERVAL_ON });
};

const POLLING_INTERVAL_OFF = 0;
const POLLING_INTERVAL_ON = 3_000;
