import { useEffect } from 'react';

import { useGetQuotesForWorkspaceQuery } from '@/api/quotesApi';
import { useOppWorkspaceStoreRef } from '@/app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';

export const useWorkspaceQuotesWithPolling = ({ workspaceId }: { workspaceId: string }) => {
  const storeRef = useOppWorkspaceStoreRef();
  const shouldPoll = storeRef.useStore(state => state.shouldPollQuotes);

  const query = useGetQuotesForWorkspaceQuery({ workspaceId }, { pollingInterval: 5000, skip: !shouldPoll });
  const { data: quotes } = query;

  // turn off polling once all quotes are completed
  useEffect(() => {
    if (query.isFetching || !quotes) return;

    // stop if there are none in processing state
    const processingCount = quotes.reduce((c, q) => c + (q.analysisStatus === 'processing' ? 1 : 0), 0);
    if (processingCount === 0) storeRef.setState({ shouldPollQuotes: false });
  }, [quotes, query.isFetching, storeRef]);

  return query;
};
